import { Pagination as PaginationData } from "pages/Main/Components/AdminPanel/Options";
import styles from "./index.module.css";
import { ArrowLeftIcon, ArrowRightIcon } from "Common/Icons/Arrow";
import { ENTITY_PER_PAGE, PROP_NAME_SKIP_ADS } from "helpers/constants";
import { Pagination, Table } from "antd";
import { useSearchParams } from "react-router-dom";

export type PropsTable = {
  propsTable: {
    columns: Array<{
      key: string;
      title: string | number | JSX.Element;
      dataIndex: string;
    }>;
    dataSource: Array<any>;
    headerShow?: boolean;
    isLoading?: boolean;
    classList?: string;
  };
  paginationTable: {
    skipAds: number;
    pagination: PaginationData;
  };
};

export const TableWithPagination = ({ paginationTable, propsTable }: PropsTable) => {
  const { columns, dataSource, headerShow, isLoading } = propsTable;
  const { pagination, skipAds } = paginationTable;
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className={styles.container}>
      <Table
        showHeader={headerShow}
        columns={columns}
        loading={isLoading}
        dataSource={dataSource}
        className={propsTable.classList ? propsTable.classList + " " + styles.table : styles.table}
        pagination={false}
      />
      <Pagination
        className={styles.paginationBottom}
        hideOnSinglePage
        showSizeChanger={false}
        total={pagination.total}
        current={Math.floor(skipAds / ENTITY_PER_PAGE + 1)}
        onChange={(page) => {
          const pageMinus = page - 1;
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set(PROP_NAME_SKIP_ADS, (pageMinus * ENTITY_PER_PAGE).toString());
          setSearchParams(newSearchParams, { replace: true });
        }}
        defaultPageSize={ENTITY_PER_PAGE}
        nextIcon={<ArrowRightIcon />}
        prevIcon={<ArrowLeftIcon />}
      />
    </div>
  );
};
