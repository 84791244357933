import { Button } from "antd";
import { TableWithPagination } from "Components/TableWithPagination";
import sortIcon from "assets/svg/SortAscending.svg";
import c2c_black from "assets/svg/c2c_token_black.svg";
import styles from "./index.module.css";
import { useContext, useEffect, useMemo, useState } from "react";
import { Pagination } from "pages/Main/Components/AdminPanel/Options";
import {
  ENTITY_PER_PAGE,
  PROP_NAME_INPUT_TEXT,
  PROP_NAME_METHOD,
  PROP_NAME_NEXT_TAB,
  PROP_NAME_PURPOSE,
  PROP_NAME_SKIP_ADS,
  PROP_NAME_SORTBY,
} from "helpers/constants";
import { UserNameRatingPhotoComponent } from "Components/UserNameRatingPhotoComponent";
import { ArrowDownPaymentIcon, ArrowUpPaymentIcon } from "Common/Icons/ArrowPayment";
import { useDebounce, useRequest } from "ahooks";
import { getUserPayments, SortByUserPayments } from "helpers/backend_helper";
import { useSearchParams } from "react-router-dom";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { PURPOSE_OPTIONS, TABS_MAP } from "../TablePayments";
import { CopyButton } from "Components/CopyButton";
import { onSort } from "helpers/helper";
import { DealsInfo } from "Components/DealsInfo";

type Props = {
  countryUserId: string;
};

export const RequestPayments = ({ countryUserId }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const [pagination, setPagination] = useState<Pagination>({ skip: 0, take: ENTITY_PER_PAGE, total: 0 });
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [idOfRent, setIdOfRent] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [skipAds, setSkipAds] = useState(+searchParams.get(PROP_NAME_SKIP_ADS) || 0);

  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT); // debounced
  const paymentMethodFilter = searchParams.get(PROP_NAME_METHOD);
  const purposFilter = searchParams.get(PROP_NAME_PURPOSE);
  const mainTabs = searchParams.get(PROP_NAME_NEXT_TAB);
  const sortByFilter = searchParams.get(PROP_NAME_SORTBY);
  const skipAdsFilter = searchParams.get(PROP_NAME_SKIP_ADS);
  const [sortBy, setSortBy] = useState<SortByUserPayments>("");
  const debouncedSearch = useDebounce(searchParams, { wait: 100 });

  useEffect(() => {
    if (skipAdsFilter) {
      setSkipAds(+skipAdsFilter);
    } else {
      setSkipAds(0);
    }
  }, [skipAdsFilter]);

  useEffect(() => {
    if (sortByFilter) {
      setSortBy(sortByFilter as SortByUserPayments);
    } else {
      setSortBy("");
    }
  }, [sortByFilter]);
  const { data: userPayments, loading: isPaymentLoading } = useRequest(
    () =>
      getUserPayments(
        countryUserId,
        {
          search: searchText,
          paymentMethod: paymentMethodFilter
            ? paymentMethodFilter.split(",").length > 1
              ? ""
              : (paymentMethodFilter as any)
            : "",
          purpose: purposFilter as any,
          transactionType: TABS_MAP[mainTabs] || "",
          pagination: { skip: skipAds, take: ENTITY_PER_PAGE, total: pagination.total },
          sort: { sortBy },
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      ready: !!localCountry?.iso,
      refreshDeps: [debouncedSearch, localCountry?.iso],
      onError: (err: any) => {
        openNotificationWithIcon("error", err?.response?.data?.message || err.message);
      },
      onSuccess: ({ total }) => {
        setPagination((prev) => ({ ...prev, total: +total }));
      },
    },
  );
  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Date</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="usagePercentage"
                  style={{ width: 16, height: 16, transform: sortBy === "created_at" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSort("created_at", searchParams, sortBy, setSearchParams);
              }}
            />
          </div>
        ),
        dataIndex: "date",
        key: "date",
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Amount</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="usagePercentage"
                  style={{ width: 16, height: 16, transform: sortBy === "amount" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSort("amount", searchParams, sortBy, setSearchParams);
              }}
            />
          </div>
        ),
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "Deal ID",
        dataIndex: "deal_id",
        key: "deal_id",
      },
      {
        title: "Purpose",
        dataIndex: "purpose",
        key: "purpose",
      },
      {
        title: " ",
        dataIndex: "actions",
        key: "actions",
      },
    ],
    [sortBy],
  );

  const source = useMemo(
    () =>
      userPayments?.transactions?.length
        ? userPayments.transactions.map((transaction) => {
            const transactionCreatedAt = new Date(transaction.createdAt);
            return {
              id: (
                <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
                  {transaction.processType === "incoming" ? (
                    <ArrowDownPaymentIcon size={16} />
                  ) : (
                    <ArrowUpPaymentIcon size={16} />
                  )}
                  <CopyButton
                    copyText="copied!"
                    text={transaction.transactionId}
                    showText={transaction.transactionId.slice(0, 7)}
                    wait={500}
                    className={styles.copyButtonStyle}
                  />
                </div>
              ),
              date: transactionCreatedAt.toLocaleDateString("eng", {
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }),
              user: (
                <UserNameRatingPhotoComponent
                  el={{
                    avatar: transaction.sender.avatar,
                    firstName: transaction.sender.firstName,
                    lastName: transaction.sender.lastName,
                    rating: +transaction.sender?.rating || 0,
                    isOnlyName: true,
                  }}
                />
              ),
              amount: (
                <div style={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {transaction.type !== "BANK" ? (
                    <img src={c2c_black} alt="c2c token" />
                  ) : (
                    <span style={{ lineHeight: "16px" }}>€</span>
                  )}
                  <span style={{ lineHeight: "16px" }}>{+transaction.amount}</span>
                </div>
              ),
              deal_id: (
                <CopyButton
                  copyText="copied!"
                  text={transaction.rentId}
                  showText={transaction.rentId.slice(0, 7)}
                  wait={500}
                  className={styles.copyButtonStyle}
                />
              ),
              purpose: PURPOSE_OPTIONS[transaction.transactionType],
              actions: (
                <div className={styles.buttonWrapper}>
                  <Button
                    className={styles.button}
                    type="primary"
                    onClick={() => {
                      setIsDetailsOpen(true);
                      setIdOfRent(transaction.rentId);
                    }}
                  >
                    Details
                  </Button>
                </div>
              ),
            };
          })
        : [],
    [userPayments?.transactions],
  );
  return (
    <div className={styles.container}>
      <TableWithPagination
        paginationTable={{ pagination, skipAds }}
        propsTable={{
          columns,
          dataSource: source,
          headerShow: true,
          isLoading: isPaymentLoading,
        }}
      />
      <DealsInfo
        close={() => {
          setIsDetailsOpen(false);
        }}
        isOpen={isDetailsOpen}
        id={idOfRent}
      />
    </div>
  );
};
