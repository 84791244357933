import { useContext, useMemo, useState } from "react";
import { Button, Divider, Pagination, Popconfirm, Table } from "antd";
import { useRequest } from "ahooks";
import { NotificationContext } from "context/notificationContext";
import { deleteCity, getCities } from "helpers/backend_helper";
import { Language } from "../Languages";
import { AdminContext } from "context/adminContext";
import NewCityModal from "./Components/NewModal";

import styles from "./index.module.css";
import EditCityModal from "./Components/EditModal";
import EditGeoModal from "./Components/EditGeoModal";
import type { Pagination as PagType } from "../Options";
import { ENTITY_PER_PAGE } from "helpers/constants";

export type Country = {
  active: boolean;
  id: string;
  iso: string;
  name: string;
  wallet: string;
  languages: Language[];
  defaultLanguageId: number;
  defaultLanguage: Language;
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "formattedData",
    key: "formattedData",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Delivery price",
    dataIndex: "deliveryPrice",
    key: "deliveryPrice",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];

const Cities = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [pagination, setPagination] = useState<PagType>({ take: ENTITY_PER_PAGE, skip: 0, total: 0 });
  const {
    data,
    loading: getCitiesLoading,
    refresh: refreshCities,
  } = useRequest(() => getCities(localCountry.iso, false, pagination), {
    refreshDeps: [pagination.skip, pagination.take, localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || error.message);
    },
    onSuccess: (response) => {
      if (response.count) {
        setPagination((prev) => ({ ...prev, total: response.count }));
      }
    },
  });
  const { loading: deleteLoading, run: deleteCityHandler } = useRequest((id) => deleteCity(localCountry.iso, id), {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || error.message);
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "city was deleted");
      refreshCities();
    },
  });

  const onPaginationChange = (page: number) => {
    setPagination((prev) => ({ ...prev, skip: page * 10 - 10 }));
  };

  const source = useMemo(
    () =>
      data?.cities?.map((el, i) => ({
        ...el,
        key: i,
        id: <div>{el.id}</div>,
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setSelectedCity(el);
                setIsModalOpen(true);
              }}
            >
              Edit
            </Button>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setSelectedCity(el);
                setIsEditModalOpen(true);
              }}
            >
              Geozones
            </Button>
            <Popconfirm
              title={`Delete ${el.formattedData}`}
              description="Are you sure to delete this city?"
              onConfirm={() => deleteCityHandler(el.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger loading={deleteLoading}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        ),
      })) || [],
    [data, deleteCityHandler, deleteLoading],
  );

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Cities
      </Divider>
      <Table
        loading={getCitiesLoading}
        dataSource={source}
        columns={columns}
        className={styles.table}
        pagination={false}
      />
      <div className={styles.pagination}>
        <Pagination
          total={pagination.total}
          current={pagination.skip / 10 + 1}
          onChange={onPaginationChange}
          defaultPageSize={10}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
          Add new city
        </Button>
      </div>
      <EditCityModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedCity={selectedCity}
        refreshCities={refreshCities}
      />
      <EditGeoModal isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} selectedCity={selectedCity} />
      <NewCityModal
        refresh={refreshCities}
        setIsCreateModalOpen={setIsCreateModalOpen}
        isCreateModalOpen={isCreateModalOpen}
      />
    </div>
  );
};

export default Cities;
