import { message, Spin, Upload, UploadFile, UploadProps } from "antd";
import { PropsWithChildren, useContext, useEffect, useState } from "react";

import { PaperClipIcon } from "Common/Icons/PaperClip";
import { SpinnerIcon } from "Common/Icons/Spinner";

import styles from "./index.module.css";
import { ChatsContext } from "context/chatsContext";
import { AdminContext } from "context/adminContext";
import { API_ORIGIN } from "pages/Main/Components/AdminPanel/Debate/DetailsModal/utils";
import { AppConfigContext } from "context/appConfigContext";
import { useDebounce } from "ahooks";

type Props = {
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  fileList: UploadFile[];
  isArbitr: boolean;
  chatId: string;
};

const LINK_TMP_SUPPORT = "support-chats/admin/tmp-file";
const LINK_TMP_ARBITR = "chats/tmp-file";
export const UploadFilesComponent = (props: PropsWithChildren<Props>) => {
  const { children, isArbitr, chatId } = props;
  const { localCountry } = useContext(AdminContext);
  const { fileList, setFileList, setBunchFiles } = useContext(ChatsContext);
  const [isAlotFiles, setIsAlotFiles] = useState(false);
  const [isBigFiles, setIsBigFiles] = useState(false);
  const isBigFilesMB = useDebounce(isBigFiles, { wait: 100 });
  const isMoreThanAmount = useDebounce(isAlotFiles, { wait: 100 });
  const { data: appSettings } = useContext(AppConfigContext);
  useEffect(() => {
    if (!fileList.length) return;
    const isDone = fileList.every(
      (file) => file.status === "done" || file.status === "error" || file.status === "removed",
    );
    setBunchFiles((prev) => ({ ...prev, status: isDone ? "DONE" : "LOADING" }));
  }, [fileList, setBunchFiles]);

  let actionLink: string;
  if (isArbitr) {
    actionLink = `${API_ORIGIN}/${LINK_TMP_ARBITR}/${chatId}`;
  } else {
    actionLink = `${API_ORIGIN}/${LINK_TMP_SUPPORT}/${chatId}`;
  }

  const uploadPropsFile: UploadProps = {
    accept:
      appSettings?.value?.file_types_chat ||
      "image/png,image/jpeg,image/jpg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain",
    action: actionLink,
    headers: {
      authorization: "Bearer " + localStorage.getItem("accessToken"),
      "country-iso-code": localCountry.iso,
      "accept-language": "EN",
    },
    multiple: true,
    maxCount: appSettings?.value?.max_photo_upload_count + 1,
    onChange: ({ fileList, file }) => {
      setFileList(fileList);
      if (file.status === "removed") {
        setBunchFiles((prev) => {
          prev.files = prev.files.filter((fileToUpload) => file.name !== fileToUpload.originalName);
          return { ...prev };
        });
        setFileList((prev) => {
          console.log(prev.filter((v) => v.name !== file.name));
          return prev.filter((v) => v.name !== file.name);
        });
        return;
      }
      if (file?.response && file.status === "done") {
        setBunchFiles((prev) => {
          const existingFile = prev.files.find((v) => v.originalName === file.response.originalName);
          if (!existingFile) {
            prev.files.push(file.response);
            return { ...prev };
          }
          return prev;
        });
      }
    },
    iconRender: function (file) {
      if (file.status === "uploading") return <Spin indicator={<SpinnerIcon />} spinning size="small" />;
      return <PaperClipIcon />;
    },
    beforeUpload: (file, currentFileList) => {
      if (
        fileList.length + 1 > appSettings?.value?.max_photo_upload_count ||
        currentFileList.length > appSettings?.value?.max_photo_upload_count
      ) {
        setIsAlotFiles(true);
        return Upload.LIST_IGNORE;
      }

      if (file.size > 1024 * 1024 * appSettings?.value?.max_photo_upload_size) {
        setIsBigFiles(true);
        return Upload.LIST_IGNORE;
      }
      setBunchFiles((prev) => ({ ...prev, status: "LOADING" }));
      return true;
    },
    fileList: fileList,
  };

  useEffect(() => {
    if (isMoreThanAmount) {
      message.error(`You can't load more than ${appSettings?.value?.max_photo_upload_count} files`);
      setIsAlotFiles(false);
    }
  }, [isMoreThanAmount, appSettings?.value?.max_photo_upload_count]);

  useEffect(() => {
    if (isBigFilesMB) {
      message.error(`You can't load a file that more than ${appSettings?.value?.max_photo_upload_size}MB`);
      setIsBigFiles(false);
    }
  }, [isBigFilesMB, appSettings?.value?.max_photo_upload_size]);
  return (
    <Upload {...uploadPropsFile} className={styles.uploadFiles}>
      {children}
    </Upload>
  );
};
