import { Button, Input } from "antd";
import { SelectData, PropsSelectData } from "./SelectData";
import styles from "./index.module.css";
import xCircle from "assets/svg/XCircle.svg";
import mgGlass from "assets/svg/magnifying-glass.svg";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "ahooks";
import { PROP_NAME_FROM_DATE_SEARCH_MAIN, PROP_NAME_SKIP_ADS, PROP_NAME_TO_DATE_SEARCH_MAIN } from "helpers/constants";
import { RangePickerComponent } from "Components/RangePickerComponent";

type SelectDataWithLabel = Omit<PropsSelectData, "onChange" | "value"> & { label: string; queryName: string };
type Props = {
  selectData: SelectDataWithLabel[];
  inputData: {
    palceHolder?: string;
    queryTextName: string;
  };
  isDateRangeField?: boolean;
};

export const SearchComponent = ({ selectData, inputData, isDateRangeField }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get(inputData.queryTextName) || "");
  const searchTextDebounce = useDebounce(searchText, { wait: 500 });

  const fromDateFilter = searchParams.get(PROP_NAME_FROM_DATE_SEARCH_MAIN);
  const toDateFilter = searchParams.get(PROP_NAME_TO_DATE_SEARCH_MAIN);

  const fromToDate = useMemo(
    () => ({
      from: fromDateFilter || "",
      to: toDateFilter || "",
    }),
    [fromDateFilter, toDateFilter],
  );

  useEffect(() => {
    if (!searchTextDebounce && !searchParams.get(inputData.queryTextName)) return;

    if (searchTextDebounce) {
      searchParams.set(inputData.queryTextName, searchTextDebounce);
    } else {
      searchParams.delete(inputData.queryTextName);
    }
    setSearchParams(searchParams, { replace: true });
  }, [searchTextDebounce, searchParams]);

  const resetAll = useCallback(() => {
    selectData.forEach((v) => {
      searchParams.delete(v.queryName);
    });
    searchParams.delete(PROP_NAME_SKIP_ADS);
    searchParams.delete(PROP_NAME_FROM_DATE_SEARCH_MAIN);
    searchParams.delete(PROP_NAME_TO_DATE_SEARCH_MAIN);
    if (searchText) {
      setSearchText("");
    } else {
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, searchText, selectData, setSearchParams]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.selectorsDataWrapper}>
        {selectData.map(({ options, placeHolder, style, label, isLoadingSelect, queryName }, i) => {
          const propName = searchParams.get(queryName);
          const newOptions = options.map((v) => {
            v.value = v.value.toString();
            return v;
          });
          let newValue: string[] = [""];
          if (propName?.length) {
            const splitedProp = propName.split(",");
            newValue = splitedProp.filter((v) => v !== "");
          }
          return (
            <div key={i} className={styles.selectdataWrapper}>
              <div className={styles.selectLabel}>{label}</div>
              <SelectData
                onChange={(v) => {
                  if (queryName) {
                    if (v[v.length - 1] === "" || v.length === 0) {
                      searchParams.delete(queryName);
                    } else {
                      searchParams.set(queryName, v.filter((v) => v).join(","));
                    }
                    setSearchParams(searchParams, { replace: true });
                  }
                }}
                options={newOptions}
                placeHolder={placeHolder}
                style={style}
                value={newValue}
                isLoadingSelect={isLoadingSelect}
              />
            </div>
          );
        })}
        {isDateRangeField && (
          <div className={styles.selectdataWrapper} style={{ height: 64 }}>
            <div className={styles.selectLabel}>DateRange</div>
            <RangePickerComponent
              defaultValue={fromDateFilter ? fromToDate : null}
              onChange={(_, date) => {
                const dateSplitedFrom = date[0].split(".");
                const dateSplitedTo = date[1].split(".");
                const dateCorrectFrom = `${dateSplitedFrom[2]}-${dateSplitedFrom[1]}-${dateSplitedFrom[0]}`;
                const dateCorrectTo = `${dateSplitedTo[2]}-${dateSplitedTo[1]}-${dateSplitedTo[0]}`;

                searchParams.set(PROP_NAME_FROM_DATE_SEARCH_MAIN, dateCorrectFrom);
                searchParams.set(PROP_NAME_TO_DATE_SEARCH_MAIN, dateCorrectTo);
                setSearchParams(searchParams);
              }}
              onDelete={() => {
                searchParams.delete(PROP_NAME_FROM_DATE_SEARCH_MAIN);
                searchParams.delete(PROP_NAME_TO_DATE_SEARCH_MAIN);
                setSearchParams(searchParams);
              }}
              value={fromDateFilter ? [new Date(fromToDate.from), new Date(fromToDate.to)] : null}
            />
          </div>
        )}
        <Button
          type="primary"
          ghost
          onClick={resetAll}
          className={styles.resetButton}
          icon={<img src={xCircle} alt="reset" />}
        >
          Reset
        </Button>
      </div>

      <Input
        allowClear
        className={styles.inputSearch}
        placeholder={inputData.palceHolder}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        value={searchText}
        suffix={<img src={mgGlass} alt="magnifyingglass" />}
      />
    </div>
  );
};
