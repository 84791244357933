import { Badge, Divider, Tooltip } from "antd";
import { RequestPayments } from "./RequestPayments";
import styles from "./index.module.css";
import { TabsComponent } from "Components/TabsComponent";
import c2cToken from "assets/svg/c2c-token.svg";
import { PaymentMethodsComponent } from "Components/PaymentMethodsComponent";
import { SearchComponent } from "Components/SearchComponent";
import { useContext, useMemo } from "react";
import { InfoComponent } from "Components/InfoComponent";
import {
  PROP_NAME_INPUT_TEXT,
  PROP_NAME_NEXT_TAB,
  PROP_NAME_METHOD,
  PROP_NAME_PURPOSE,
  PROP_NAME_SKIP_ADS,
  PROP_NAME_SORTBY,
  PROP_NAME_IS_RENTER,
} from "helpers/constants";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import { useDebounce, useRequest } from "ahooks";
import { getUserPaymentMetrics, UserDetails } from "helpers/backend_helper";
import { useSearchParams } from "react-router-dom";
import { ExistPaymentMethods } from "helpers/types";

const getItems = (countryUserId: string) => [
  {
    key: "0",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>All</div>
      </Badge>
    ),
    children: <RequestPayments countryUserId={countryUserId} />,
  },
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Incoming</div>
      </Badge>
    ),
    children: <RequestPayments countryUserId={countryUserId} />,
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Outgoing</div>
      </Badge>
    ),
    children: <RequestPayments countryUserId={countryUserId} />,
  },
];

export const TABS_MAP = {
  0: "",
  1: "incoming",
  2: "outgoing",
};
export type Balances = {
  balance: number;
  debt: number;
  deposit: number;
};
type Props = {
  countryUserId: string;
  userDetails: UserDetails;
};
export const PURPOSE_OPTIONS = {
  FREEZE_AMOUNT: "Freeze",
  CHARGE_SERVICE_INSURANCE: "Insurance",
  CHARGE_RENT_LESSOR_BY_LESSEE: "Rent",
  UNFEEZE_AMOUNT: "Unfreeze",
  CHARGE_RENT_LESSOR_BY_SERVICE: "Commission",
  CHARGE_RENT_LESSEE_BY_SERVICE: "ServiceFee",
  CHARGE_DEBT_INTO_SERVICE: "Debt",
};

export const TOKEN_DECIMAL = 9;

export const TablePayments = ({ countryUserId, userDetails: { paymentMethods, balance, debt, deposit } }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT); // debounced
  const paymentMethodFilter = searchParams.get(PROP_NAME_METHOD);
  const purposFilter = searchParams.get(PROP_NAME_PURPOSE);
  const nextTabs = searchParams.get(PROP_NAME_NEXT_TAB);
  const isRenter = searchParams.get(PROP_NAME_IS_RENTER);
  const debauncedSearchParams = useDebounce(searchParams, { wait: 100 });

  const paymentsMethodDefault: { PAYOUT: ExistPaymentMethods; PAYMENT: ExistPaymentMethods } = useMemo(
    () =>
      paymentMethods?.paymentMethods?.reduce((result: any, curr) => {
        result[curr.purpose] = curr;
        return result;
      }, {}) || [],
    [paymentMethods],
  );

  const { data: userMetrics, loading: isUserMetricsLoading } = useRequest(
    () =>
      getUserPaymentMetrics(
        countryUserId,
        {
          search: searchText,
          paymentMethod: paymentMethodFilter
            ? paymentMethodFilter.split(",").length > 1
              ? ""
              : (paymentMethodFilter as any)
            : "",
          purpose: purposFilter as any,
          transactionType: TABS_MAP[nextTabs] || "",
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      ready: !!localCountry?.iso,
      refreshDeps: [debauncedSearchParams, localCountry?.iso],
      onError: (err: any) => {
        openNotificationWithIcon("error", err?.response?.data?.message || err.message);
      },
    },
  );
  const parsedBalance = balance / 10 ** TOKEN_DECIMAL;
  return (
    <div className={styles.container}>
      <div className={styles.methodsContainer}>
        <div className={styles.balanceWrapper}>
          <div className={styles.balance}>
            <span>Total Balance</span>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <img src={c2cToken} alt="c2cToken" />
              <Tooltip title={parsedBalance}>
                <span className={styles.number}>
                  {+parsedBalance.toFixed() === parsedBalance
                    ? parsedBalance
                    : parsedBalance.toString().split(".")[0] + "." + parsedBalance.toString().split(".")[1].slice(0, 3)}
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={styles.balance}>
            <div className={styles.balance} style={{ gap: 5 }}>
              <span style={{ color: "#868686" }}>Deposit</span>
              <Tooltip title={"User's Deposit"}>
                <span className={styles.infoIcon} />
              </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <img src={c2cToken} alt="c2cToken" />
              <span className={styles.number}>{deposit}</span>
            </div>
          </div>
          <div className={styles.balance}>
            <span>Debt</span>
            <span className={styles.number} style={{ color: "red" }}>
              <span style={{ marginRight: 10 }}>€</span>
              {debt}
            </span>
          </div>
        </div>
        {paymentMethods?.card && (
          <PaymentMethodsComponent
            isDefault={
              isRenter === "true" ? !!paymentsMethodDefault.PAYMENT.cardId : !!paymentsMethodDefault.PAYOUT.cardId
            }
            isTon={false}
            info={paymentMethods.card as any}
          />
        )}
        {paymentMethods?.wallet && (
          <PaymentMethodsComponent
            isDefault={
              isRenter === "true" ? !!paymentsMethodDefault.PAYMENT.walletId : !!paymentsMethodDefault.PAYOUT.walletId
            }
            isTon={true}
            info={paymentMethods.wallet as any}
          />
        )}
      </div>
      <SearchComponent
        inputData={{
          queryTextName: PROP_NAME_INPUT_TEXT,
        }}
        selectData={[
          {
            options: [
              { label: "All", value: "" },
              { label: "Card", value: "Card" },
              { label: "Wallet", value: "Wallet" },
            ],
            label: "Method",
            isLoadingSelect: false,
            queryName: PROP_NAME_METHOD,
          },
          {
            options: [
              { label: "All", value: "" },
              { label: PURPOSE_OPTIONS["FREEZE_AMOUNT"], value: "FREEZE_AMOUNT" },
              { label: PURPOSE_OPTIONS["CHARGE_SERVICE_INSURANCE"], value: "CHARGE_SERVICE_INSURANCE" },
              { label: PURPOSE_OPTIONS["CHARGE_RENT_LESSOR_BY_LESSEE"], value: "CHARGE_RENT_LESSOR_BY_LESSEE" },
              { label: PURPOSE_OPTIONS["UNFEEZE_AMOUNT"], value: "UNFEEZE_AMOUNT" },
              { label: PURPOSE_OPTIONS["CHARGE_RENT_LESSOR_BY_SERVICE"], value: "CHARGE_RENT_LESSOR_BY_SERVICE" },
              { label: PURPOSE_OPTIONS["CHARGE_RENT_LESSEE_BY_SERVICE"], value: "CHARGE_RENT_LESSEE_BY_SERVICE" },
              { label: PURPOSE_OPTIONS["CHARGE_DEBT_INTO_SERVICE"], value: "CHARGE_DEBT_INTO_SERVICE" },
            ],
            label: "Purpose",
            isLoadingSelect: false,
            queryName: PROP_NAME_PURPOSE,
          },
        ]}
      />
      <Divider style={{ borderWidth: 2 }} />
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        {[
          {
            infoOfData: "Amount",
            isLoading: isUserMetricsLoading,
            label: "Amount",
            value: (
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <img src={c2cToken} alt="c2cToken" />
                <span>{userMetrics?.walletAmount}</span>
              </div>
            ),
            moreValue: {
              value: "€" + userMetrics?.bankAmount,
              moreInfo: null,
            },
          },
          {
            infoOfData: "Service Fees",
            label: "Service Fees",
            value: "€" + userMetrics?.serviceFee,
            isLoading: isUserMetricsLoading,
          },
          { infoOfData: "VAT", label: "VAT", value: userMetrics?.vat + "%", isLoading: isUserMetricsLoading },
        ].map(({ infoOfData, label, value, isLoading, moreValue }, i) => (
          <InfoComponent
            infoOfData={infoOfData}
            label={label}
            value={value}
            key={i}
            isLoading={isLoading}
            moreValue={moreValue}
          />
        ))}
      </div>
      <TabsComponent
        defaultActiveKey={searchParams.get(PROP_NAME_NEXT_TAB) || "0"}
        items={getItems(countryUserId)}
        className={styles.tabs}
        destroyInactiveTabPane
        onChange={(activeKey) => {
          searchParams.set(PROP_NAME_NEXT_TAB, getItems(countryUserId).find((v) => v.key === activeKey).key);
          searchParams.delete(PROP_NAME_SORTBY);
          searchParams.delete(PROP_NAME_SKIP_ADS);
          searchParams.delete(PROP_NAME_SORTBY);

          setSearchParams(searchParams, { replace: true });
        }}
      />
    </div>
  );
};
