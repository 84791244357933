import { useContext, useEffect } from "react";
import { AdminContext } from "context/adminContext";
import { useNavigate } from "react-router";

const AuthGuard = ({ children }) => {
  const { isAuthorized } = useContext(AdminContext);
  const navigator = useNavigate();
  useEffect(() => {
    if (!isAuthorized && !localStorage.getItem("accessToken")) {
      navigator("/login");
    }
  }, [isAuthorized, navigator]);

  return isAuthorized ? children : null;
};

export default AuthGuard;
