import { Button, Input, Modal, Spin } from "antd";

import Review, { ItemReview } from "./Review";

import styles from "./index.module.css";
import mgGlass from "assets/svg/magnifying-glass.svg";
import closeX from "assets/svg/closeX.svg";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  reviews: ItemReview[];
  isModalOpen: boolean;
  reviewCount: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: (id: string) => void;
  hasMore: boolean;
  loadMore: () => void;
};

const CommentsModal = ({ reviews, isModalOpen, setIsModalOpen, reviewCount, onDelete, hasMore, loadMore }: Props) => {
  return (
    <Modal
      title={`${reviewCount} Review${reviewCount ? (reviewCount === 1 ? "" : "s") : "s"}`}
      open={isModalOpen}
      footer={null}
      className={styles.modal}
      onCancel={() => setIsModalOpen(false)}
      closeIcon={<img src={closeX} alt="close" width="24px" height="24px" />}
    >
      {!reviews ? (
        <Spin />
      ) : (
        <>
          <Input
            className={styles.inputSearch}
            placeholder="Search"
            suffix={<img src={mgGlass} alt="magnifyingglass" />}
          />
          <div className={styles.list} id="scrollReviewItemElement">
            <InfiniteScroll
              dataLength={reviews.length}
              hasMore={hasMore}
              className={styles.infiniteScroll}
              loader={<Spin />}
              height={488}
              next={() => {
                loadMore();
              }}
              scrollableTarget="scrollReviewItemElement"
            >
              {reviews.map((item) => (
                <div key={item.id} className={styles.reviewWrapper}>
                  <Review item={item} onDelete={onDelete} />
                </div>
              ))}
            </InfiniteScroll>
          </div>
          <div className={styles.buttonsWrapper}>
            <Button className={styles.closeButton} type="primary" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CommentsModal;
