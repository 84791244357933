import { Badge } from "antd";
import { RequestDeals } from "./RequestDeals";
import styles from "./index.module.css";
import { InfoComponent } from "Components/InfoComponent";
import { TabsComponent } from "Components/TabsComponent";
import { useRequest } from "ahooks";
import { getUserDealsMetrics } from "helpers/backend_helper";
import { useContext, useMemo } from "react";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { useSearchParams } from "react-router-dom";
import { PROP_NAME_NEXT_TAB, PROP_NAME_SKIP_ADS, PROP_NAME_SORTBY } from "helpers/constants";

const getItems = (countryUserId: string, isRenter: boolean) => [
  {
    key: "0",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Request</div>
      </Badge>
    ),
    children: <RequestDeals countryUserId={countryUserId} isRenter={isRenter} status="request" />,
  },
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Booked</div>
      </Badge>
    ),
    children: <RequestDeals countryUserId={countryUserId} isRenter={isRenter} status="booked" />,
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>In progress</div>
      </Badge>
    ),
    children: <RequestDeals countryUserId={countryUserId} isRenter={isRenter} status="in_progress" />,
  },
  {
    key: "3",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Completed</div>
      </Badge>
    ),
    children: <RequestDeals countryUserId={countryUserId} isRenter={isRenter} status="completed" />,
  },
  {
    key: "4",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Declined</div>
      </Badge>
    ),
    children: <RequestDeals countryUserId={countryUserId} isRenter={isRenter} status="declined" />,
  },
  {
    key: "5",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Dispute</div>
      </Badge>
    ),
    children: <RequestDeals countryUserId={countryUserId} isRenter={isRenter} status="disputed" />,
  },
];

type Props = {
  countryUserId: string;
  isRenter: boolean;
};

export const TableDeals = ({ countryUserId, isRenter }: Props) => {
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const items = useMemo(() => getItems(countryUserId, isRenter), [countryUserId, isRenter]);
  const { data, loading } = useRequest(
    () => {
      return getUserDealsMetrics(
        { countryUserId, initiator: isRenter ? "lessee" : "lessor" },
        localCountry.iso.toUpperCase(),
      );
    },
    {
      ready: !!localCountry?.iso,
      refreshDeps: [isRenter],
      onError: (error: any) => {
        openNotificationWithIcon("error", error?.data?.message || error.message);
      },
    },
  );

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        {[
          { infoDate: "Amount of Deals", label: "Deals", value: data?.dealsCount, loading },
          { infoDate: "Sum price of deals", label: "Amount", value: "€" + data?.sumPrice, loading },
          { infoDate: "Average Period", label: "Avg period", value: data?.avgPeriodDays.toFixed(2) + " days", loading },
          { infoDate: "Success Rate", label: "Success Rate", value: data?.successRate + "%", loading },
        ].map(({ infoDate, label, value, loading }, i) => (
          <InfoComponent key={i} infoOfData={infoDate} label={label} value={value} isLoading={loading} />
        ))}
      </div>
      <TabsComponent
        defaultActiveKey={searchParams.get(PROP_NAME_NEXT_TAB) || "0"}
        items={items}
        className={styles.tabs}
        destroyInactiveTabPane
        onChange={(activeKey) => {
          searchParams.set(PROP_NAME_NEXT_TAB, items.find((v) => v.key === activeKey).key);
          searchParams.delete(PROP_NAME_SKIP_ADS);
          searchParams.delete(PROP_NAME_SORTBY);
          setSearchParams(searchParams, { replace: true });
        }}
      />
    </div>
  );
};
