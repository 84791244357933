import { Button } from "antd";
import { useCallback, useState } from "react";

type Props = {
  text: string;
  sliceOfText?: number;
  className?: string;
  wait: number;
  copyText: string;
  showText?: string;
};

export const CopyButton = ({ className, copyText, text, wait, sliceOfText, showText }: Props) => {
  const [isCopy, setIsCopy] = useState(false);
  const onClick = useCallback(() => {
    navigator.clipboard.writeText(text);
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, wait);
  }, [text, wait]);
  return (
    <Button type="text" onClick={onClick} className={className} style={{ padding: 0, letterSpacing: "1px" }}>
      {isCopy
        ? copyText
        : sliceOfText !== null && sliceOfText !== undefined
        ? showText
          ? showText.slice(0, sliceOfText)
          : text.slice(0, sliceOfText)
        : showText
        ? showText
        : text}
    </Button>
  );
};
