import style from "./index.module.css";

export const ArrowIcon = ({ size }: { size?: number | string }) => {
  const correctSize = typeof size === "number" ? size.toString() + "px" : size;
  return <div className={style.arrow} style={{ backgroundSize: correctSize, backgroundPosition: "center" }} />;
};
export const ArrowUpIcon = ({ size }: { size?: number | string }) => {
  const correctSize = typeof size === "number" ? size.toString() + "px" : size;
  return <div className={style.arrowUp} style={{ backgroundSize: correctSize, backgroundPosition: "center" }} />;
};
export const ArrowLeftIcon = ({ size }: { size?: number | string }) => {
  const correctSize = typeof size === "number" ? size.toString() + "px" : size;
  return <div className={style.arrowLeft} style={{ backgroundSize: correctSize, backgroundPosition: "center" }} />;
};
export const ArrowRightIcon = ({ size }: { size?: number | string }) => {
  const correctSize = typeof size === "number" ? size.toString() + "px" : size;
  return <div className={style.arrowRight} style={{ backgroundSize: correctSize, backgroundPosition: "center" }}></div>;
};
