import { createContext, useContext } from "react";
import { AdminContext } from "./adminContext";
import { useRequest } from "ahooks";
import { NotificationContext } from "./notificationContext";
import { AppSettingResponse, getAppSettings } from "helpers/backend_helper";

type ContextProps = {
  data: AppSettingResponse;
  loading: boolean;
};
export const AppConfigContext = createContext({} as ContextProps);

export const AppConfigProvider = ({ children }: { children: JSX.Element }) => {
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);

  const { data, loading } = useRequest(() => getAppSettings(localCountry?.iso?.toUpperCase()), {
    ready: !!localCountry?.iso,
    refreshDeps: [localCountry],
    onError: (err: any) => {
      openNotificationWithIcon("error", err?.response?.data?.message || err?.message);
    },
  });
  return <AppConfigContext.Provider value={{ data, loading }}>{children}</AppConfigContext.Provider>;
};
