import { CSSProperties } from "react";
import styles from "./index.module.css";
import { Button, Form, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  buttons: {
    confirm: {
      className?: string;
      style?: CSSProperties;
      label: string;
      onConfirm: () => void;
      isDanger?: boolean;
    };
    decline: {
      className?: string;
      style?: CSSProperties;
      label: string;
      onDecline: () => void;
      isDanger?: boolean;
    };
  };
  content: string | React.ReactElement;
  inputField?: {
    onChange: (text: string) => void;
  };
};

type FieldType = {
  comment: string;
};

export const ModalComponent = ({ isOpen, onClose, title, buttons, inputField, content }: Props) => {
  const [form] = Form.useForm<FieldType>();

  const onFinish = (values) => {
    if (values) {
      if (inputField) inputField.onChange(values.textArea);
      buttons.confirm.onConfirm();
      onClose();
      form.resetFields();
    }
  };
  return (
    <Modal
      className={styles.modal}
      title={title}
      footer={false}
      onCancel={onClose}
      open={isOpen}
      closeIcon={<CloseOutlined style={{ fontSize: 22, color: "#000" }} />}
    >
      <div className={styles.content} style={!inputField && { marginBottom: 45 }}>
        {content}
      </div>
      <Form form={form} onFinish={onFinish} autoComplete="off">
        {inputField && (
          <Form.Item
            rules={[
              {
                required: true,
                message: "Field must not be empty",
              },
            ]}
            style={{ marginBottom: 48 }}
            name="textArea"
          >
            <TextArea onChange={(e) => inputField.onChange(e.target.value)} className={styles.inputArea} />
          </Form.Item>
        )}
        <div className={styles.btnWrapper}>
          <Button
            type="primary"
            ghost
            className={
              buttons.decline.isDanger ? styles.danger + " " + buttons.decline.className : buttons.decline.className
            }
            style={buttons.decline.style}
            onClick={() => buttons.decline.onDecline()}
            htmlType="reset"
            danger={buttons.decline.isDanger}
          >
            {buttons.decline.label}
          </Button>
          <Button
            type="primary"
            style={buttons.confirm.style}
            htmlType="submit"
            className={
              buttons.confirm.isDanger ? styles.danger + " " + buttons.confirm.className : buttons.confirm.className
            }
            danger={buttons.confirm.isDanger}
          >
            {buttons.confirm.label}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
