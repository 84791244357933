import { Button, Drawer, Spin } from "antd";
import styles from "./index.module.css";
import closeX from "assets/svg/closeDealsX.svg";
import { CopyButton } from "Components/CopyButton";
import { AllInfoComponent } from "./AllInfoComponent";
import { HistoryComponent } from "./HistoryComponent";
import { AdminContext } from "context/adminContext";
import { useContext } from "react";
import { NotificationContext } from "context/notificationContext";
import { getRentsDetails } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import { getPeriod } from "helpers/helper";

type Props = {
  close: () => void;
  isOpen: boolean;
  id: string;
};

export const DealsInfo = ({ close, isOpen, id }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const { data, loading } = useRequest(() => getRentsDetails(id, localCountry.iso.toUpperCase()), {
    ready: !!localCountry.iso && !!id,
    refreshDeps: [id],
    onError: (err: any) => {
      openNotificationWithIcon("error", err.response?.data?.message || err.message);
    },
  });

  return (
    <Drawer
      placement="bottom"
      closable={false}
      open={isOpen}
      className={styles.draver}
      classNames={{
        mask: styles.mask,
        wrapper: styles.wrapperDraver,
      }}
    >
      {loading || !data ? (
        <>
          <Button onClick={close} type="text" className={styles.closeBtn}>
            Close <img src={closeX} alt="close" width={24} height={24} />
          </Button>
          <Spin style={{ left: "50%", top: "50%", transform: "translate(50% -50%)", width: "100%", height: "100%" }} />
        </>
      ) : (
        <div className={styles.container}>
          <div className={styles.topInfoWrapper}>
            <div className={styles.mainInfo}>
              <span>Deal Info</span>
              <span>ID</span>
              <CopyButton copyText="copied" text={id} wait={500} className={styles.copyBtn} />
              <Button type="primary" className={styles.buttonChat}>
                Chat
              </Button>
            </div>
            <Button onClick={close} type="text" className={styles.closeBtn}>
              Close <img src={closeX} alt="close" width={24} height={24} />
            </Button>
          </div>
          <div className={styles.allInfo}>
            <AllInfoComponent
              contentFirst={[
                { content: data.productInfo.productName },
                { label: "ID", content: data.productInfo.productId, isContentID: true, sliceLength: 7 },
              ]}
              label="Product"
            />
            <AllInfoComponent
              contentFirst={[
                { content: data.lesseeInfo.firstName + " " + data.lesseeInfo.lastName },
                { label: "ID", content: data.lesseeInfo.id, isContentID: true, sliceLength: 7 },
              ]}
              label="Renter"
            />
            <AllInfoComponent
              contentFirst={[
                { content: data.lessorInfo.firstName + " " + data.lessorInfo.lastName },
                { label: "ID", content: data.lessorInfo.id, isContentID: true, sliceLength: 7 },
              ]}
              label="Lessor"
            />
            <AllInfoComponent
              contentFirst={[
                { content: getPeriod(data.dateStart, data.dateEnd), label: "Period" },
                { label: "Amount", content: "€" + data.amount },
              ]}
              label="Deal"
            />
            <AllInfoComponent
              contentFirst={[
                { content: (+data.insurance.value).toString(), label: "Rate" },
                {
                  label: data.insurance.insuranceType === "PERCENTAGE" ? "Fee" : "Deposit",
                  content:
                    "€" + (data.insurance.insuranceType === "DEPOSIT" ? data.insurance.deposit : data.insurance.value),
                },
              ]}
              label="Insurance"
            />
            <AllInfoComponent
              style={{ marginRight: 40 }}
              contentFirst={[
                { content: data.method.payIn, label: "Pay-in" },
                { label: "Pay-out", content: data.method.payOut },
              ]}
              label="Method"
            />
          </div>
          <div className={styles.historyWrapper}>
            <HistoryComponent steps={data.rentSteps} />
          </div>
        </div>
      )}
    </Drawer>
  );
};
