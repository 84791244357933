import { TitleComponent } from "Components/Title";
import styles from "./index.module.css";
import { SearchComponent } from "Components/SearchComponent";
import { ReactNode, useContext, useState } from "react";
import verified from "assets/svg/Verified.svg";
import tonKeeperConnected from "assets/svg/ConnectedTon.svg";
import lock from "assets/svg/Lock.svg";
import briefcase from "assets/svg/Briefcase.svg";
import { useRequest } from "ahooks";
import { AdminDisputesStatuses, getCities } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import { Badge, Divider } from "antd";
import c2cToken from "assets/svg/c2c-token.svg";
import { InfoComponent } from "Components/InfoComponent";
import { TabsComponent } from "Components/TabsComponent";
import { RangePickerComponent } from "Components/RangePickerComponent";
import { RequestDisputes } from "./RequestDisputes";
import { PROP_NAME_CITY, PROP_NAME_INPUT_TEXT, PROP_NAME_INSURANCE } from "helpers/constants";

export type StatusInfoUsers = "verivied" | "connected_ton_keeper" | "blocked" | "lessor";

export const getDesignedStatuses = (value: StatusInfoUsers) => {
  switch (value) {
    case "verivied":
      return <img src={verified} alt="verified" />;
    case "connected_ton_keeper":
      return <img src={tonKeeperConnected} alt="tonKeeperConnected" />;
    case "blocked":
      return <img src={lock} alt="lock" />;
    case "lessor":
      return <img src={briefcase} alt="briefcase" />;
    default:
      return <div>{value}</div>;
  }
};

const getItems = ({
  cityIds,
  insuranceIds,
  searchText,
  dateRange,
}: {
  cityIds: string[];
  insuranceIds: string[];
  searchText: string;
  dateRange: { to: string; from: string };
}) => [
  {
    key: "0",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Requests</div>
      </Badge>
    ),
    children: <RequestDisputes cityIds={cityIds} insurance={insuranceIds} searchText={searchText} status="request" />,
  },
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>In process</div>
      </Badge>
    ),
    children: (
      <RequestDisputes cityIds={cityIds} insurance={insuranceIds} searchText={searchText} status="in_progress" />
    ),
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Completed</div>
      </Badge>
    ),
    children: (
      <RequestDisputes
        cityIds={cityIds}
        insurance={insuranceIds}
        searchText={searchText}
        status="completed"
        dateRange={dateRange}
      />
    ),
  },
  {
    key: "3",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Declined</div>
      </Badge>
    ),
    children: <RequestDisputes cityIds={cityIds} insurance={insuranceIds} searchText={searchText} status="declined" />,
  },
];

const STATUSES_ADMIN: AdminDisputesStatuses[] = ["request", "in_progress", "completed", "declined"];

export const NewDebates = () => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const [cities, setCities] = useState<{ label: string | ReactNode; value: string }[]>([]);
  const [skipAds, setSkipAds] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [fromToDate, setFromToDate] = useState({ from: "", to: "" });
  const [currentStatus, setCurrentStatus] = useState<AdminDisputesStatuses>("request");

  console.log(skipAds, sortBy, setSortBy, setSkipAds);

  const { loading: getCitiesLoading } = useRequest(() => getCities(localCountry.iso, true), {
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: (data) => {
      setCities(() => {
        return [
          { label: "All", value: "" },
          ...data.cities.map((city) => {
            const upperCaseFirstLt = city.formattedData[0].toUpperCase() + city.formattedData.slice(1);
            return { label: upperCaseFirstLt, value: city.id };
          }),
        ];
      });
    },
  });

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 20 }}>
        <TitleComponent text="Dispute" />
      </div>
      <SearchComponent
        inputData={{
          palceHolder: "Search",
          queryTextName: PROP_NAME_INPUT_TEXT,
        }}
        selectData={[
          {
            options: cities,
            label: "City",
            isLoadingSelect: getCitiesLoading,
            queryName: PROP_NAME_CITY,
          },
          {
            isLoadingSelect: false,
            options: [
              { label: "All", value: "" },
              {
                label: "Deposit",
                value: "DEPOSIT",
              },
              {
                label: "Percentage",
                value: "PERCENTAGE",
              },
            ],
            label: "Insurance",
            queryName: PROP_NAME_INSURANCE,
          },
        ]}
      />
      <Divider style={{ borderWidth: 2 }} />
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        {[
          {
            infoOfData: "Amount of disputes",
            label: "Disputes",
            value: 502,
            isLoading: false,
          },
          {
            infoOfData: "Amount",
            label: "Amount",
            value: (
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <img src={c2cToken} alt="c2cToken" />
                <span>2,234</span>
              </div>
            ),
            isLoading: false,
          },
          currentStatus === "completed" && {
            infoOfData: "Payout Rate",
            label: "Payout Rate",
            value: "20%",
            isLoading: false,
          },
        ].map((v, i) => {
          if (!v) return null;
          const { infoOfData, label, value, isLoading } = v;
          return <InfoComponent infoOfData={infoOfData} label={label} value={value} key={i} isLoading={isLoading} />;
        })}
      </div>
      <TabsComponent
        defaultActiveKey="0"
        items={getItems({
          cityIds: [""],
          dateRange: fromToDate,
          insuranceIds: [""],
          searchText: "",
        })}
        className={styles.tabs}
        destroyInactiveTabPane
        onChange={(active) => {
          console.log(STATUSES_ADMIN[active]);
          setCurrentStatus(STATUSES_ADMIN[active]);
        }}
        tabBarExtraContent={{
          right:
            currentStatus === "completed" ? (
              <div
                style={{
                  position: "relative",
                  top: 5,
                  left: 20,
                }}
              >
                <RangePickerComponent
                  defaultValue={fromToDate.from ? fromToDate : null}
                  onChange={(_, date) => {
                    const dateSplitedFrom = date[0].split(".");
                    const dateSplitedTo = date[1].split(".");
                    const dateCorrectFrom = `${dateSplitedFrom[2]}-${dateSplitedFrom[1]}-${dateSplitedFrom[0]}`;
                    const dateCorrectTo = `${dateSplitedTo[2]}-${dateSplitedTo[1]}-${dateSplitedTo[0]}`;

                    setFromToDate({ from: dateCorrectFrom, to: dateCorrectTo });
                  }}
                  onDelete={() => {
                    setFromToDate({ from: "", to: "" });
                  }}
                />
              </div>
            ) : null,
        }}
      />
    </div>
  );
};
