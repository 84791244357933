import { Divider } from "antd";
import { HistoryLine } from "./HistoryLine";
import styles from "./index.module.css";
import { StepData } from "helpers/backend_helper";

type Props = {
  steps: StepData[];
};
export const HistoryComponent = ({ steps }: Props) => {
  return (
    <div className={styles.historyWrapper}>
      <div className={styles.header}>
        <span>Step</span>
        <span>Renter, Maxim</span>
        <span>Lessor, David</span>
        <span>Documents</span>
      </div>
      <Divider style={{ margin: "10px 0px" }} />

      {steps.map((v, i) => (
        <div key={v.step}>
          <HistoryLine step={v} />
          {i !== steps.length - 1 && <Divider style={{ margin: "10px 0px" }} />}
        </div>
      ))}
    </div>
  );
};
