import { Button, Rate } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";

import Review, { ItemReview } from "./Components/Comments/Review";

import CommentsModal from "./Components/Comments";
import styles from "./index.module.css";
import { useRequest } from "ahooks";
import { deleteProductReview, getProductReviews } from "helpers/backend_helper";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";

type Props = { itemId: string; reviewCount: number; onReviewChangeCount: (n: number) => void; rating: number };
const ItemReviews = ({ itemId, reviewCount, onReviewChangeCount, rating }: Props) => {
  const [isProductReviewModalOpen, setIsProductReviewModalOpen] = useState(Boolean);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const [reviews, setReviews] = useState<ItemReview[]>([]);
  const [cursorReview, setCursorReview] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [showLast, setShowLast] = useState<ItemReview[]>([]);

  const { run: onDeleteReview } = useRequest((id: string) => deleteProductReview(+id, localCountry.iso.toUpperCase()), {
    ready: !!localCountry?.iso,
    refreshDeps: [localCountry, itemId],
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: (_, params) => {
      setReviews((prev) => prev.filter((v) => v.id !== params[0]));
      setShowLast((prev) => prev.filter((v) => v.id !== params[0]));
      onReviewChangeCount(reviewCount - 1);

      if (hasMore) {
        getReviews();
      }
    },
  });

  const { run: getReviews } = useRequest(
    () => getProductReviews(itemId, cursorReview, localCountry.iso.toUpperCase()),
    {
      ready: !!localCountry?.iso,
      refreshDeps: [localCountry, itemId, cursorReview],
      manual: true,
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
      },
      onSuccess: (data) => {
        if (!data.reviews.length) {
          setHasMore(false);
        }
        if (reviews.length) {
          setShowLast([...showLast, ...data.reviews]);
          setReviews([...reviews, ...data.reviews]);
        } else {
          setShowLast(data.reviews);
          setReviews(data.reviews);
        }
        setCursorReview(data.cursor);
      },
    },
  );

  useEffect(() => {
    getReviews();

    return () => {
      setReviews([]);
      setShowLast([]);
    };
  }, []);

  const loadMore = useCallback(() => getReviews(), [getReviews]);

  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <div className={styles.watchAllWrapper}>
          <span className={styles.revText}>{"Reviews"}</span>
          <span className={styles.revText}>{reviewCount}</span>
          <Button className={styles.navButton} type="text" onClick={() => setIsProductReviewModalOpen(true)}>
            Watch all
          </Button>
        </div>
        <div className={styles.rate}>
          <span>{rating?.toFixed(1)}</span>
          <Rate disabled defaultValue={+rating} className={styles.rateComponent} allowHalf />
        </div>
      </div>
      <div className={styles.list}>
        {showLast.slice(0, 4).map((item) => (
          <div key={item.id} className={styles.reviewWrapper}>
            <Review item={item} onDelete={onDeleteReview} />
          </div>
        ))}
      </div>
      <CommentsModal
        hasMore={hasMore}
        loadMore={loadMore}
        isModalOpen={isProductReviewModalOpen}
        setIsModalOpen={setIsProductReviewModalOpen}
        reviews={reviews}
        reviewCount={reviewCount}
        onDelete={onDeleteReview}
      />
    </div>
  );
};

export default ItemReviews;
