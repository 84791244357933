import React from "react";

import styles from "./index.module.css";

type Props = {
  isRenter: boolean;
  onChangeType: (isRenterProp: boolean) => void;
};

const RentSwitcher = ({ isRenter, onChangeType }: Props) => {
  return (
    <div className={styles.switcherContainer}>
      <label className={styles.switcherLabel} htmlFor="switchCheckbox">
        <div className={styles.text}>{!isRenter ? "" : "Lessor"}</div>
        <div className={styles.text}>{isRenter ? "" : "Renter"}</div>
        <input
          type="checkbox"
          id="switchCheckbox"
          checked={isRenter}
          onChange={() => {
            onChangeType(!isRenter);
          }}
        />
        <span className={styles.switcherSlider}>{!isRenter ? "Lessor" : "Renter"}</span>
      </label>
    </div>
  );
};

export default RentSwitcher;
