import { CarouselComponent } from "Components/CarouselComponent";
import styles from "./index.module.css";
import { FeaturesComponent } from "./FeaturesComponent";

const getOptions = (option: any) => {
  const res = {
    name: option.option.name,
    value: "",
  };
  if (option.value) {
    res.value = option.value;
  } else {
    res.value = option.selectValues.map((val) => val.value).join(", ");
  }
  return res;
};

type Props = {
  photos: { preview: string }[];
  description: string;
  productOption: any[];
};
export const ProductComponent = ({ description, photos, productOption }: Props) => {
  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 26 }}>
        <CarouselComponent photos={photos} />
      </div>
      <div className={styles.productDescWrapper}>
        <div style={{ fontWeight: 700, fontSize: 18, marginBottom: 16 }}>Product description</div>
        <p style={{ fontSize: 14, fontFamily: "Manrope" }}>{description?.replaceAll("\\n", "\n")}</p>
      </div>
      <div className={styles.featuresWrapper}>
        <FeaturesComponent features={productOption?.map((v) => getOptions(v))} />
      </div>
    </div>
  );
};
