import type { User } from "context/usersContext";
import { get, post, del, patch } from "./api_helper";
import { Pagination } from "pages/Main/Components/AdminPanel/Options";
import { AxiosRequestConfig } from "axios";
import { FinishDebateRequestDto, PaymentMethodsType } from "./types";
import { UserPropInfo } from "pages/Main/Components/AdminPanel/Users/UserComponent/UserComponent";
import { getQueryFromArray } from "./helper";

export type translation = {
  name?: string;
  description?: string;
  languageCode: string;
  value?: string;
};

type value = {
  translations: translation[];
};

type optionType = {
  translations: translation[];
  productGroupId: number;
  type: string;
  select: {
    translations: translation[];
    values: value[];
  };
  numericRange?: { max: number; min: number };
};

export const loginAdmin = (email: string, password: string) => {
  return post(`/auth/internal/login`, { email, password });
};

export const registerAdmin = (email: string, password: string, firstName: string, lastName: string) => {
  return post(`/auth/internal/register`, {
    email,
    password,
    passwordRepeat: password,
    firstName,
    lastName,
    role: "ADMIN",
  });
};

export const getUsers = (localCountry: string) => {
  return get(`/internal/users`, { headers: { "country-iso-code": localCountry } });
};

export const getAccount = (localCountry: string) => {
  return get(`/internal/user`, { headers: { "country-iso-code": localCountry } });
};

export const approveUser = (userId: string, role: string, localCountry: string) => {
  return post(`/internal/user/approve`, { userId, role }, { headers: { "country-iso-code": localCountry } });
};

export const editUser = (user: User, localCountry: string) => {
  return patch(`/internal/user`, user, { headers: { "country-iso-code": localCountry } });
};

export const editUserById = (user: User, id: string, localCountry: string) => {
  return patch(`/internal/user/${id}`, user, { headers: { "country-iso-code": localCountry } });
};

export const assignRole = (userId: string, role: string, localCountry: string) => {
  console.log(role);
  return patch(`/internal/assign-roles/${userId}`, { role }, { headers: { "country-iso-code": localCountry } });
};

export const activateUser = (userId: string, localCountry: string) => {
  return patch(`/internal/activate/${userId}`, { headers: { "country-iso-code": localCountry } });
};

export const deleteUser = (userId: string, localCountry: string) => {
  return del(`/internal/user/${userId}`, { headers: { "country-iso-code": localCountry } });
};

export const createWallet = (storage: string, localCountry: string) => {
  return post(`/internal/wallet`, { storage }, { headers: { "country-iso-code": localCountry } });
};

export const deleteWallet = (localCountry: string) => {
  return del(`/internal/wallet`, { headers: { "country-iso-code": localCountry } });
};

export const createCountry = (iso: string, name: string, languageIds: string[], defaultLanguageId?: number) => {
  return post(`/country`, { iso, name, languageIds, defaultLanguageId });
};

export const getCountry = (localCountry: string) => {
  return get(`/country`, { headers: { "country-iso-code": localCountry } });
};

export const updateCountry = (
  id: string,
  active: boolean,
  name: string,
  languageIds: string,
  localCountry: string,
  defaultLanguageId?: number,
) => {
  return patch(
    `/country/${id}`,
    { active, name, languageIds, defaultLanguageId },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const findCountryById = (id: string, localCountry: string) => {
  return get(`/country/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const deleteCountry = (id: string, localCountry: string) => {
  return del(`/country/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const getContract = (localCountry: string) => {
  return get(`/contract`, { headers: { "country-iso-code": localCountry } });
};

export const mintContract = (jettonAmount: number) => {
  return post(`/contract/mint`, { jettonAmount });
};

export const burnContract = (jettonAmount: number) => {
  return post(`/contract/burn`, { jettonAmount });
};

export const transferContract = (jettonAmount: number, to: string) => {
  return post(`/contract/transfer`, { jettonAmount, to });
};

export const getLanguages = (localCountry: string) => {
  return get(`/language`, { headers: { "country-iso-code": localCountry } });
};

export const createLanguage = (code: string, name: string, localCountry: string) => {
  return post(`/language`, { code, name }, { headers: { "country-iso-code": localCountry } });
};

export const updateLanguage = (id: string, name: string, localCountry: string) => {
  return patch(`/language/${id}`, { name }, { headers: { "country-iso-code": localCountry } });
};

export const deleteLanguage = (id: string, localCountry: string) => {
  return del(`/language/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const getApproveContract = () => {
  return get(`/contract/approve`);
};

export const approveContract = (localCountry: string) => {
  return post(`/contract/approve`, { headers: { "country-iso-code": localCountry } });
};

export const declineContract = (localCountry: string) => {
  return del(`/contract/approve`, { headers: { "country-iso-code": localCountry } });
};

export const getCategories = (localCountry: string, withGroup?: boolean) => {
  const strWithGroup = withGroup ? "?withGroup=true" : "";
  return get(`/category${strWithGroup}`, { headers: { "country-iso-code": localCountry } });
};

export const getCategoryById = (id: string, localCountry: string, withGroup?: boolean) => {
  const strWithGroup = withGroup ? "?withGroup=true" : "";
  return get(`/category/${id}?${strWithGroup}`, { headers: { "country-iso-code": localCountry } });
};

export const getCategoriesBrunchById = (id: string, localCountry: string) => {
  return get(`/category/brunch/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const createCategory = (translations: translation[], localCountry: string, parentId?: number) => {
  return post(
    `/category`,
    { translations, parentId: parentId ? parentId : undefined },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const updateCategory = (translations: translation[], localCountry: string, id: string) => {
  return patch(`/category/${id}`, { translations }, { headers: { "country-iso-code": localCountry } });
};

export const deleteCategory = (id: number, localCountry: string) => {
  return del(`/category/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const updateLevelCategory = (id: string, newParentId: string, localCountry: string) => {
  return patch(
    `/category/level/${id}`,
    { newParentId: +newParentId },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const updateOrderCategory = (id: string, order: string, localCountry: string) => {
  return patch(`/category/order/${id}`, { newOrder: +order }, { headers: { "country-iso-code": localCountry } });
};

export const createGroup = (translations: translation[], categoryIds: number[], localCountry: string) => {
  return post(`/product-groups`, { translations, categoryIds }, { headers: { "country-iso-code": localCountry } });
};

export const getGroups = (localCountry: string) => {
  return get(`/product-groups`, { headers: { "country-iso-code": localCountry } });
};

export const getGroupById = (id: string, localCountry: string) => {
  return get(`/product-groups/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const updateGroupById = (translations: translation[], localCountry: string, id: number) => {
  return patch(`/product-groups/${id}`, { translations }, { headers: { "country-iso-code": localCountry } });
};

export const deleteGroup = (id: string, localCountry: string) => {
  return del(`/product-groups/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const deleteGroupFromCategory = (id: number, categoryId: number, localCountry: string) => {
  return patch(
    `/product-groups/category/remove/${id}`,
    {
      categoryId,
    },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const addGroupToCategory = (id: number, categoryId: number, localCountry: string) => {
  return patch(
    `/product-groups/category/add/${id}`,
    {
      categoryId,
    },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const getGroupOptionSelects = (localCountry: string) => {
  return get(`/option-selects`, { headers: { "country-iso-code": localCountry } });
};

export const getGroupOptions = (localCountry: string, productGroupId: string, pagination?: Pagination) => {
  let take = "";
  let skip = "";
  if (pagination) {
    take = pagination?.take ? `&take=${pagination?.take}` : "";
    skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  }

  return get(`/options?${productGroupId ? `productGroupId=${productGroupId}` : ""}${take}${skip}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export const createOption = (optionType: optionType, localCountry: string) => {
  return post(`/options`, optionType, { headers: { "country-iso-code": localCountry } });
};

export const updateOption = (id: number, optionType: optionType, localCountry: string) => {
  return patch(`/options/${id}`, optionType, { headers: { "country-iso-code": localCountry } });
};

export const deleteOption = (id: string, localCountry: string) => {
  return del(`/options/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const geyOptionById = (id: number, localCountry: string) => {
  return get(`/options/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const updateOrderOption = (id: string, order: string, localCountry: string) => {
  return patch(`/options/${id}`, { optionOrder: +order }, { headers: { "country-iso-code": localCountry } });
};

export const getProducts = (localCountry: string, pagination: Pagination, statuses?: string, productId?: string) => {
  const status = statuses ? `&statuses=${statuses}` : "";
  const id = productId ? `&id=${productId}` : "";
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "&skip=0";
  return get(`/admin/product?${status}${status}${id}${take}${skip}`, {
    headers: { "country-iso-code": localCountry },
  });
};
type ProductsV1Status = "Draft" | "Active" | "Moderating" | "Archived" | "Stopped";
export type SortByForProducts = "createdAt" | "usagePercentage" | "qualityRate" | "moderatedAt" | "";
type GetProductsV1Props = {
  pagination: Pagination;
  cities?: string[];
  categories?: string[];
  searchText: string;
  status: ProductsV1Status;
  sort?: { sortBy: SortByForProducts; sortQuery?: string };
};
type ResponceProducts = {
  count: number;
  products: {
    user: {
      avatar: string;
      countryUserId: string;
      email: string;
      firstName: string;
      id: string;
      lastName: string;
      patronymic: string;
      userRating: number;
    };
    categoryName: string;
    createdAt: string;
    id: string;
    moderatedAt: string;
    name: string;
    productPhoto: {
      original: string;
      preview: string;
      path: string;
    };
    qualityRate: string;
    usagePercentage: string;
  }[];
};
export const getProductsV1 = (
  { pagination, searchText, status, sort, categories, cities }: GetProductsV1Props,
  localCountry: string,
): Promise<ResponceProducts> => {
  const existCat = categories.join(",");
  const existCities = cities.join(",");
  const cityIds = existCities ? getQueryFromArray(cities, "cities") : "";
  const statusQuery = status ? `&status=${status}` : "";
  const search = searchText ? `&search=${searchText}` : "";
  const sortBy = sort?.sortBy ? `&sortBy=${sort.sortBy}` : "";
  const sortQuery = sort?.sortQuery ? `&sortBy=${sort.sortQuery}` : "";
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  const categoriesQuery = existCat ? getQueryFromArray(categories, "categories") : "";
  return get(
    `/admin/product/v1?${statusQuery}${cityIds}${search}${sortBy}${sortQuery}${take}${skip}${categoriesQuery}`,
    {
      headers: { "country-iso-code": localCountry },
    },
  );
};

export const updateProductStatus = (id: number | string, data, localCountry: string) => {
  return patch(`/admin/product/${id}`, data, { headers: { "country-iso-code": localCountry } });
};

export const getProduct = (id: number | string, localCountry: string) => {
  return get(`/admin/product/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const getUserById = (id: string, localCountry: string) => {
  return get(`/account/country/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const createCity = (localCountry: string, city) => {
  return post(`/location/city`, city, { headers: { "country-iso-code": localCountry, "accept-language": "EN" } });
};

export const editCity = (localCountry: string, id: string, address: string, deliveryPrice: number) => {
  return patch(
    `/location/city/${id}`,
    { address, deliveryPrice },
    { headers: { "country-iso-code": localCountry, "accept-language": "EN" } },
  );
};

export const getCityWithGeozones = (localCountry: string, id: string) => {
  return get(`/location/city-info/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const getCities = (localCountry: string, isAll: boolean, pagination?: Pagination) => {
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  return get(`/location/city?${take}${skip}&all=${isAll}`, { headers: { "country-iso-code": localCountry } });
};

export const deleteCity = (localCountry: string, id: string) => {
  return del(`/location/city/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const createGeoZone = (localCountry: string, id: string, geo) => {
  return post(`/location/geozone/${id}`, geo, {
    headers: { "country-iso-code": localCountry, "accept-language": "EN" },
  });
};

export const deleteGeoZone = (localCountry: string, id: string) => {
  return del(`/location/geozone/${id}`, { headers: { "country-iso-code": localCountry, "accept-language": "EN" } });
};

export const autoCompleteCities = (data) => {
  return get(`https://maps.googleapis.com/maps/api/place/autocomplete/json`, {
    "Access-Control-Allow-Origin": "*",
    ...data,
  });
};

export type OtherGetChatsProps = {
  pagination?: Pagination;
  inProgress?: boolean;
  userData?: string;
};
export const getChats = (
  localCountry: string,
  statuses: string[],
  { pagination, inProgress, userData = "" }: OtherGetChatsProps,
) => {
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  const queryStatuses = statuses.map((curr) => `statuses=${curr}`).join("&");
  return get(
    `/support-chats/admin?${queryStatuses}${take}${skip}${inProgress ? "&myChat=true" : ""}&userData=${userData}`,
    {
      headers: { "country-iso-code": localCountry },
    },
  );
};

export const endSupportChat = (localCountry: string, chatId: string) => {
  return post(`/support-chats/admin/close-request/${chatId}`, {}, { headers: { "country-iso-code": localCountry } });
};

export const startSupportChat = (localCountry: string, chatId: string) => {
  return post(
    `/support-chats/admin/start-serve-request/${chatId}`,
    {},
    { headers: { "country-iso-code": localCountry } },
  );
};

export const getSupportChat = (cursor = 0, localCountry: string, chatId: string) => {
  // const take = pagination?.take ? `&take=${pagination?.take}` : "";
  // const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  return post(
    `/support-chats/admin/getRequestHistory/${chatId}`,
    { cursor, limit: 10 },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const getPossibleChangeSupports = (chatId: string, localCountry: string) => {
  return get(`/support-chats/admin/get-possible-change-supports/${chatId}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export const postChangeSupport = (chatId: string, supportId: string, localCountry: string) => {
  return post(
    `/support-chats/admin/change-support/${chatId}`,
    {
      supportId,
    },
    {
      headers: { "country-iso-code": localCountry },
    },
  );
};

export const getArbitrChat = (cursor = 0, localCountry: string, chatId: string) => {
  return get(`/chats/messages/${chatId}?cursor=${cursor}`, { headers: { "country-iso-code": localCountry } });
};

export const getDebate = (localCountry: string, pagination: Pagination, meMode: boolean, status?: string) => {
  return get(
    `/debate?take=${pagination?.take}&skip=${pagination?.skip}${meMode ? "&meMode=true" : ""}${
      status ? `&status=${status}` : ""
    }`,
    {
      headers: { "country-iso-code": localCountry },
    },
  );
};

export const getDebateDetails = (id: string, localCountry: string) => {
  return get(`/debate/${id}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export const startDebateProcess = (id, localCountry: string) => {
  return post(`/debate/start-process/${id}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export const finishDebateProcess = (id: string, data: FinishDebateRequestDto, localCountry: string) => {
  return post(`/debate/finish/${id}`, data, {
    headers: { "country-iso-code": localCountry },
  });
};

export const postFileChat = (chatId: string, data: any, isArbitr: boolean, config?: AxiosRequestConfig) => {
  if (isArbitr) {
    return post(`/chats/files/${chatId}`, data, config);
  } else {
    return post(`/support-chats/admin/files/${chatId}`, data, config);
  }
};
export type AdsMetrics = {
  usage_percentage: number;
  ads_metric: number;
  quality_rate: number;
};
type AdsMetricsProps = {
  categories: string[];
  cities: string[];
  searchText: string;
};

export const getAdsMetrics = (
  { categories, cities, searchText }: AdsMetricsProps,
  localCountry: string,
): Promise<AdsMetrics> => {
  const existCat = categories.join(",");
  const existCities = cities.join(",");
  const cityIds = existCities ? getQueryFromArray(cities, "cities") : "";
  const categoriesQuery = existCat ? getQueryFromArray(categories, "categories") : "";
  const search = searchText ? `&search=${searchText}` : "";
  return get(`/admin/product/metrics?${cityIds}${categoriesQuery}${search}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export type UserMetrics = { debtsAmount: string; userCounts: number; balanceAmount: string };
export type UserProps = {
  citiesId: string[];
  statuses: string[];
  searchText: string;
};
export const getUserMetrics = (
  { citiesId, searchText, statuses }: UserProps,
  localCountry: string,
): Promise<UserMetrics> => {
  const cityIds = citiesId?.length ? `&cityId=${citiesId.join(",")}` : "";
  const status = statuses?.length ? `&status=${statuses.join(",")}` : "";
  const search = searchText ? `&search=${searchText}` : "";
  return get(`/admin/account/metrics?${cityIds}${status}${search}`, {
    headers: { "country-iso-code": localCountry, "accept-language": "EN" },
  });
};

export const getUserAccounts = (
  { citiesId, searchText, statuses }: UserProps,
  localCountry: string,
  pagination?: Pagination,
  sort?: { sortBy: string; sortQuery?: string },
): Promise<{ count: number; users: UserPropInfo[] }> => {
  const cityIds = citiesId?.length ? `&cityId=${citiesId.join(",")}` : "";
  const status = statuses?.length ? `&status=${statuses.join(",")}` : "";
  const search = searchText ? `&search=${searchText}` : "";
  const sortBy = sort?.sortBy ? `&sortBy=${sort.sortBy}` : "";
  const sortQuery = sort?.sortQuery ? `&sortBy=${sort.sortQuery}` : "";
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  return get(`/admin/account?${cityIds}${status}${search}${sortBy}${sortQuery}${take}${skip}`, {
    headers: { "country-iso-code": localCountry, "accept-language": "EN" },
  });
};

export type UserDetails = {
  address: string;
  avatar: string;
  createdAt: string;
  defaultCountryLang: string;
  description: string;
  email: string;
  firstName: string;
  frozen: boolean;
  insuranceRate: number;
  lastName: string;
  lastOnlineDate: string;
  lessor: boolean;
  moderationBage: number;
  online: boolean;
  patronymic: string;
  phone: string;
  rating: number;
  supportChatId: string;
  verified: boolean;
  paymentMethods: PaymentMethodsType;
  debt: number;
  balance: number;
  deposit: number;
};

export const getUserDetails = (userId: string, localCountry: string): Promise<UserDetails> => {
  return get(`/admin/account/details/${userId}`, { header: { "country-iso-code": localCountry } });
};

export const frozeUser = (userId: string, data: { froze: boolean }, localCountry: string) => {
  return post(`/admin/account/froze/${userId}`, data, {
    header: { "country-iso-code": localCountry },
  });
};

export const getUserAdsMetrics = (
  countryUserId: string,
  localCountry: string,
): Promise<{ adsMetric: number; qualityRate: number; usagePercentage: number }> => {
  return get(`/admin/account/details/lessor/adds/metrics/${countryUserId}`, {
    header: { "country-iso-code": localCountry },
  });
};

export type UserAdsStatuses = "Moderating" | "Active" | "Stopped" | "Draft" | "Archived";
export type sortByAdsUser = "createdAt" | "usagePercentage" | "qualityRate" | "moderatedAt" | "";
type UserAdsProps = {
  countryUserId: string;
  status: UserAdsStatuses;
  localCountry: string;
  pagination?: Pagination;
  sort?: { sortBy: sortByAdsUser; sortQuery?: string };
};
export const getUserAds = ({
  countryUserId,
  localCountry,
  pagination,
  sort,
  status,
}: UserAdsProps): Promise<{ count: number; products: any[] }> => {
  const sortBy = sort?.sortBy ? `&sortBy=${sort.sortBy}` : "";
  const sortQuery = sort?.sortQuery ? `&sortBy=${sort.sortQuery}` : "";
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  const statusQuery = `&status=${status}`;
  return get(`/admin/account/details/lessor/adds/${countryUserId}?${statusQuery}${sortBy}${sortQuery}${take}${skip}`, {
    header: { "country-iso-code": localCountry },
  });
};

type Initiator = "lessee" | "lessor";
type UserDealsMetricsProps = {
  countryUserId: string;
  initiator: Initiator;
};
export const getUserDealsMetrics = (
  { countryUserId, initiator }: UserDealsMetricsProps,
  localCountry: string,
): Promise<{ avgPeriodDays: number; dealsCount: number; successRate: number; sumPrice: number }> => {
  return get(`/admin/account/details/deals/metrics/${countryUserId}?initiator=${initiator}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export type UserDealsStatuses = "request" | "booked" | "in_progress" | "completed" | "declined" | "disputed";

export type sortByDealsUser = "period" | "amount" | "dateRequest" | "";
type UserDealsProps = {
  countryUserId: string;
  initiator: Initiator;
  status: UserDealsStatuses;
  pagination?: Pagination;
  sort?: { sortBy: sortByDealsUser; sortQuery?: string };
};
type ResponceUserDeals = {
  count: number;
  deals: {
    avatar: string;
    count: string;
    createdAt: string;
    dateEnd: string;
    dateStart: string;
    firstName: string;
    id: string;
    lastName: string;
    period_days: number;
    productPhoto: {
      original: string;
      path: string;
      preview: string;
    };
    rating: number;
    rentPrice: number;
    productName: string;
  }[];
};
export const getUserDeals = (
  { countryUserId, initiator, pagination, sort, status }: UserDealsProps,
  localCountry: string,
): Promise<ResponceUserDeals> => {
  const sortBy = sort?.sortBy ? `&sortBy=${sort.sortBy}` : "";
  const sortQuery = sort?.sortQuery ? `&sortBy=${sort.sortQuery}` : "";
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  const statusQuery = `&status=${status}`;
  return get(
    `/admin/account/details/deals/${countryUserId}?initiator=${initiator}${statusQuery}${sortBy}${sortQuery}${take}${skip}`,
    {
      header: { "country-iso-code": localCountry },
    },
  );
};

type ItemDealsProps = {
  itemId: string;
  status: UserDealsStatuses;
  pagination?: Pagination;
  sort?: { sortBy: sortByDealsUser; sortQuery?: string };
};
export const getItemDeals = (
  { itemId, pagination, sort, status }: ItemDealsProps,
  localCountry: string,
): Promise<ResponceUserDeals> => {
  const sortBy = sort?.sortBy ? `&sortBy=${sort.sortBy}` : "";
  const sortQuery = sort?.sortQuery ? `&sortBy=${sort.sortQuery}` : "";
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  const statusQuery = `&status=${status}`;
  return get(`/admin/product/deals/${itemId}?${statusQuery}${sortBy}${sortQuery}${take}${skip}`, {
    header: { "country-iso-code": localCountry },
  });
};

type DetailsMetrics = {
  itemId: string;
};
export const getItemDealsMetrics = (
  { itemId }: DetailsMetrics,
  localCountry: string,
): Promise<{ dealsCount: number; avgPeriodDays: number; successRate: number; sumPrice: number }> => {
  return get(`/admin/product/deals/metrics/${itemId}`, {
    headers: { "country-iso-code": localCountry },
  });
};

type UserReviewsProps = {
  countryUserId: string;
  initiator: Initiator;
  pagination?: Pagination;
  search: string;
  rating?: 0 | 1 | 2 | 3 | 4 | 5 | null;
};

export type ReviewRespond = {
  id: number;
  reviewId: number;
  authorId: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
};
export type Reviews = {
  id: number;
  authorId: string;
  recipientId: string;
  rentId: string;
  rating: string;
  comment: string;
  comments: ReviewRespond[];
  createdAt: string;
  updatedAt: string;
  author: {
    id: string;
    avatar: string;
    firstName: string;
    lastName: string;
    patronymic: string;
  };
};

export type UserReviewsResult = {
  count: number;
  reviews: Reviews[];
};
export const getReviewsOfAdmin = (
  { countryUserId, initiator, rating, search, pagination }: UserReviewsProps,
  localCountry: string,
): Promise<UserReviewsResult> => {
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  const searchQuery = search ? `&search=${search}` : "";
  const ratingQuery = rating ? `&rating=${rating}` : "";
  return get(
    `/admin/account/details/reviews/${countryUserId}?${take}${skip}${searchQuery}${ratingQuery}&initiator=${initiator}`,
    {
      header: { "country-iso-code": localCountry },
    },
  );
};

export const deleteReview = (id: number, localCountry: string) => {
  return del(`/admin/account/details/reviews/${id}`, {
    header: { "country-iso-code": localCountry },
  });
};

export const deleteProductReview = (id: number, localCountry: string) => {
  return del(`/admin/product/reviews/${id}`, {
    header: { "country-iso-code": localCountry },
  });
};

export const getProductReviews = (id: string, cursor: number, localCountry: string) => {
  return get(`/admin/product/reviews/${id}?cursor=${cursor}&limit=2`, {
    header: { "country-iso-code": localCountry },
  });
};

export type AdminRentsStatus = "request" | "booked" | "in_progress" | "completed" | "declined" | "disputed";
export type AdminRents = {
  status: AdminRentsStatus;
  cityIds: string[];
  productCategories: string[];
  dateRange: { from: string; to: string };
};

export type ResponceAdminRentsMetrics = {
  amountChangePercentage: string;
  avgPeriodDaysChangePercentage: string;
  countChangePercentage: string;
  currentAmount: string;
  currentAvgPeriodDays: string;
  currentCount: number;
  currentSuccessRate: string;
  currentAvgInsuranceCoefficient: string;
  insuranceCoefficientChangePercentage: string;
  prevAmount: string;
  prevAvgPeriodDays: string;
  prevCount: number;
  prevSuccessRate: string;
  successRateChangePercentage: string;
};
export const getAdminRentsMetrics = (
  { cityIds, productCategories, status, dateRange }: AdminRents,
  localCountry: string,
): Promise<ResponceAdminRentsMetrics> => {
  const citiesIds = cityIds?.join(",").length ? `&cityId=${cityIds.join(",")}` : "";
  const prodCat = productCategories?.join(",")?.length ? `&productCategoryId=${productCategories.join(",")}` : "";
  const dateRangeQuery = dateRange?.from || dateRange?.to ? `&date_from=${dateRange.from}&date_to=${dateRange.to}` : "";

  return get(`/admin/rents/metrics?status=${status}${citiesIds}${prodCat}${dateRangeQuery}`, {
    header: {
      "country-iso-code": localCountry,
    },
  });
};

export type SortByAdminRents = "period" | "amount" | "dateRequest" | "";
export type SortByDisputes = "date" | "period" | "price" | "paid_out" | "";

type AdminRentsProps = {
  status: AdminRentsStatus;
  cityIds: string[];
  productCategories: string[];
  pagination?: Pagination;
  searchText: string;
  dateRange: { from: string; to: string };
  sort?: { sortBy: SortByAdminRents; sortOrder?: string };
};
export const getAdminRents = (
  { pagination, sort, cityIds, productCategories, searchText, status, dateRange }: AdminRentsProps,
  localCountry: string,
): Promise<any> => {
  const sortBy = sort?.sortBy ? `&sortBy=${sort.sortBy}` : "";
  const sortQuery = sort?.sortOrder ? `&sortOrder=${sort.sortOrder}` : "";
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  const citiesIds = cityIds?.join(",").length ? `&cityId=${cityIds.join(",")}` : "";
  const prodCat = productCategories?.join(",")?.length ? `&productCategoryId=${productCategories.join(",")}` : "";
  const search = searchText ? `&search=${searchText}` : "";
  const statusQuery = `&status=${status}`;
  const dateRangeQuery = dateRange?.from || dateRange?.to ? `&date_from=${dateRange.from}&date_to=${dateRange.to}` : "";
  return get(
    `/admin/rents/v1?${statusQuery}${sortBy}${sortQuery}${take}${skip}${citiesIds}${prodCat}${search}${dateRangeQuery}`,
    {
      header: { "country-iso-code": localCountry },
    },
  );
};

export type StepData = {
  step: string;
  lessee: {
    action: string;
    date: string;
    temp?: boolean;
  };
  lessor: {
    action: string;
    date: string;
    temp?: boolean;
  };
};
type RentsDetailsResponce = {
  rentId: string;
  dateStart: string;
  dateEnd: string;
  period: number;
  amount: string;
  chatId: string;
  insurance: {
    insuranceType: "PERCENTAGE" | "DEPOSIT";
    deposit: string;
    value: string;
  };
  method: {
    payIn: string;
    payOut: string;
  };
  productInfo: {
    productCategory: string;
    productId: string;
    productName: string;
  };
  lesseeInfo: {
    firstName: string;
    lastName: string;
    id: string;
  };
  lessorInfo: {
    firstName: string;
    lastName: string;
    id: string;
  };
  rentSteps: StepData[];
};

export const getRentsDetails = (id: string, localCountry: string): Promise<RentsDetailsResponce> => {
  return get(`/admin/rents/details/${id}`, { header: { "country-iso-code": localCountry } });
};

export type AdminDisputesStatuses = "request" | "in_progress" | "completed" | "declined";
export type AppSettingResponse = {
  id: string;
  key: string;
  value: {
    [key: string]: any;
  };
};
export const getAppSettings = (localCountry: string): Promise<AppSettingResponse> => {
  return get("/config/app-settings", { headers: { "country-iso-code": localCountry } });
};

type TransactionMetrics = {
  vat: number;
  serviceFee: number;
  bankAmount: number;
  walletAmount: number;
};
type TransactionType = "" | "incoming" | "outgoing";
type PaymentMethod = "Card" | "Wallet" | "";
type PurposePayment =
  | ""
  | "FREEZE_AMOUNT"
  | "CHARGE_SERVICE_INSURANCE"
  | "CHARGE_RENT_LESSOR_BY_LESSEE"
  | "UNFEEZE_AMOUNT"
  | "CHARGE_RENT_LESSOR_BY_SERVICE"
  | "CHARGE_RENT_LESSEE_BY_SERVICE"
  | "CHARGE_DEBT_INTO_SERVICE";

type PaymentMetrics = {
  paymentMethod: PaymentMethod;
  purpose: PurposePayment;
  transactionType: TransactionType;
  search: string;
};
export const getUserPaymentMetrics = (
  clientId: string,
  { paymentMethod, purpose, search, transactionType }: PaymentMetrics,
  localCountry: string,
): Promise<TransactionMetrics> => {
  const paymentMethodQuery = paymentMethod ? `&paymentMethod=${paymentMethod}` : "";
  const purposeQuery = purpose ? getQueryFromArray(purpose.split(","), "purpose") : "";
  const searchQuery = search ? `&search=${search}` : "";
  const transactionTypeQuery = transactionType ? `&transactionType=${transactionType}` : "";

  return get(
    `/admin/account/details/payments/metrics/${clientId}?${searchQuery}${purposeQuery}${paymentMethodQuery}${transactionTypeQuery}`,
    { headers: { "country-iso-code": localCountry } },
  );
};

export const getProductPaymentMetrics = (
  productId: string,
  { paymentMethod, purpose, search, transactionType }: PaymentMetrics,
  localCountry: string,
): Promise<TransactionMetrics> => {
  const paymentMethodQuery = paymentMethod ? `&paymentMethod=${paymentMethod}` : "";
  const purposeQuery = purpose ? getQueryFromArray(purpose.split(","), "purpose") : "";
  const searchQuery = search ? `&search=${search}` : "";
  const transactionTypeQuery = transactionType ? `&transactionType=${transactionType}` : "";
  return get(
    `/admin/product/payments/metrics/${productId}?${searchQuery}${purposeQuery}${paymentMethodQuery}${transactionTypeQuery}`,
    { headers: { "country-iso-code": localCountry } },
  );
};

export type SortByUserPayments = "created_at" | "amount" | "";
type PaymentsType = {
  transactionType: TransactionType;
  paymentMethod: PaymentMethod;
  purpose: PurposePayment;
  pagination?: Pagination;
  search: string;
  sort?: { sortBy: SortByUserPayments; sortOrder?: string };
};

type TransactionPayment = {
  rentId: string;
  createdAt: string;
  processType: string;
  transactionType: string;
  paymentOperation: string;
  amount: string;
  status: string;
  type: string;
  taxValue: number;
  sender: {
    id: string;
    avatar: string | null;
    rating: string | null;
    lastName: string;
    firstName: string;
  };
  transactionId: string;
};
type UserPaymentsDetails = {
  total: string | number;
  transactions: TransactionPayment[];
};
export const getUserPayments = (
  clientId: string,
  { paymentMethod, purpose, search, transactionType, pagination, sort }: PaymentsType,
  localCountry: string,
): Promise<UserPaymentsDetails> => {
  console.log(purpose);
  const paymentMethodQuery = paymentMethod ? `&paymentMethod=${paymentMethod}` : "";
  const purposeQuery = purpose ? getQueryFromArray(purpose.split(","), "purpose") : "";
  const searchQuery = search ? `&search=${search}` : "";
  const transactionTypeQuery = transactionType ? `&transactionType=${transactionType}` : "";
  const skipQuery = pagination.skip ? `&skip=${pagination.skip}` : "";
  const takeQuery = pagination.take ? `&take=${pagination.take}` : "";
  const sortByQuery = sort.sortBy ? `&sortBy=${sort.sortBy}` : "";
  const sortOrderQuery = sort.sortOrder ? `&sortOrder=${sort.sortOrder}` : "";
  return get(
    `/admin/account/details/payments/${clientId}?${searchQuery}${purposeQuery}${paymentMethodQuery}${transactionTypeQuery}${skipQuery}${takeQuery}${sortByQuery}${sortOrderQuery}`,
    { headers: { "country-iso-code": localCountry } },
  );
};

export const getProductPayments = (
  productId: string,
  { paymentMethod, purpose, search, transactionType, pagination, sort }: PaymentsType,
  localCountry: string,
) => {
  const paymentMethodQuery = paymentMethod ? `&paymentMethod=${paymentMethod}` : "";
  const purposeQuery = purpose ? getQueryFromArray(purpose.split(","), "purpose") : "";
  const searchQuery = search ? `&search=${search}` : "";
  const transactionTypeQuery = transactionType ? `&transactionType=${transactionType}` : "";
  const skipQuery = pagination.skip ? `&skip=${pagination.skip}` : "";
  const takeQuery = pagination.take ? `&take=${pagination.take}` : "";
  const sortByQuery = sort.sortBy ? `&sortBy=${sort.sortBy}` : "";
  const sortOrderQuery = sort.sortOrder ? `&sortOrder=${sort.sortOrder}` : "";
  return get(
    `/admin/product/payments/${productId}?${skipQuery}${takeQuery}${paymentMethodQuery}${purposeQuery}${searchQuery}${transactionTypeQuery}${sortByQuery}${sortOrderQuery}`,
    { headers: { "country-iso-code": localCountry } },
  );
};

export type RateLimits = {
  ku: {
    min: number;
    max: number;
  };
  kp: {
    min: number;
    max: number;
  };
  kc: {
    min: number;
    max: number;
  };
  kt: {
    min: number;
    max: number;
  };
  m: number;
};
export const getInsuranceRatioLimits = (localCountry: string): Promise<RateLimits> => {
  return get("/insurance/ratio-limits", { headers: { "country-iso-code": localCountry } });
};

export const updateInsuranceRatioLimits = (rateLimits: RateLimits, localCountry: string): Promise<boolean> => {
  return post("/insurance/ratio-limits", rateLimits, { headers: { "country-iso-code": localCountry } });
};

export type SortInsuranceBy = "created_at" | "amount" | "";
export type InusranceStatuses = "" | "incoming" | "outgoing";
export type InsuranceType = "DEPOSIT" | "PERCENTAGE" | "";
type GetInsuranceProps = {
  pagination: Pagination;
  cities?: string[];
  insuranceType?: InsuranceType[];
  searchText: string;
  dateEnd?: string;
  dateStart?: string;
  processType: InusranceStatuses;
  sort?: { sortBy: SortInsuranceBy; sortQuery?: string };
};
export const getInsurance = (
  { pagination, searchText, processType, sort, insuranceType, cities, dateEnd, dateStart }: GetInsuranceProps,
  localCountry: string,
): Promise<any> => {
  const existInsuranceType = insuranceType.join(",");
  const existCities = cities.join(",");
  const cityIds = existCities ? `&cityId=${existCities}` : "";
  const statusQuery = processType ? `&processType=${processType}` : "";
  const search = searchText ? `&search=${searchText}` : "";
  const sortBy = sort?.sortBy ? `&sortBy=${sort.sortBy}` : "";
  const sortQuery = sort?.sortQuery ? `&sortBy=${sort.sortQuery}` : "";
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  const dateEndQ = dateEnd ? `&dateTo=${dateEnd}` : "";
  const dateFromQ = dateStart ? `&dateFrom=${dateStart}` : "";
  const categoriesQuery = existInsuranceType && insuranceType.length < 2 ? `&insuranceType=${existInsuranceType}` : "";
  return get(
    `/insurance?${statusQuery}${cityIds}${search}${sortBy}${sortQuery}${take}${skip}${categoriesQuery}${dateEndQ}${dateFromQ}`,
    {
      headers: { "country-iso-code": localCountry },
    },
  );
};

type InsuranceMetrics = {
  bankAmount: {
    value: number;
    change?: number;
  };
  walletAmount: {
    value: number;
    change?: number;
  };
  bankDepositAmount: {
    value: number;
    change?: number;
  };
  walletDepositAmount: {
    value: number;
    change?: number;
  };
  payOutRate: {
    value: number;
    change?: number;
  };
  depositRate?: {
    value: number;
    change?: number;
  };
};
export const getInsuranceMetrics = (
  {
    searchText,
    processType,
    insuranceType,
    cities,
    dateEnd,
    dateStart,
  }: Omit<GetInsuranceProps, "sort" | "pagination">,
  localCountry: string,
): Promise<InsuranceMetrics> => {
  const existInsuranceType = insuranceType.join(",");
  const existCities = cities.join(",");
  const cityIds = existCities ? `&cityId=${existCities}` : "";
  const statusQuery = processType ? `&processType=${processType}` : "";
  const search = searchText ? `&search=${searchText}` : "";
  const dateEndQ = dateEnd ? `&dateTo=${dateEnd}` : "";
  const dateFromQ = dateStart ? `&dateFrom=${dateStart}` : "";
  const categoriesQuery = existInsuranceType && insuranceType.length < 2 ? `&insuranceType=${existInsuranceType}` : "";
  return get(`/insurance/metrics?${statusQuery}${cityIds}${search}${categoriesQuery}${dateEndQ}${dateFromQ}`, {
    headers: { "country-iso-code": localCountry },
  });
};
