import styles from "./index.module.css";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useDebounce, useRequest } from "ahooks";
import { Input, Pagination, Rate, Spin } from "antd";
import mgGlass from "assets/svg/magnifying-glass.svg";
import { Pagination as PaginationData } from "pages/Main/Components/AdminPanel/Options";
import { ReviewComponent } from "Components/ReviewComponent";
import { ArrowLeftIcon, ArrowRightIcon } from "Common/Icons/Arrow";
import { deleteReview, getReviewsOfAdmin, UserDetails } from "helpers/backend_helper";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { PROP_NAME_INPUT_TEXT, PROP_NAME_SKIP_ADS } from "helpers/constants";
import { useSearchParams } from "react-router-dom";

type Props = {
  countryUserId: string;
  isRenter: boolean;
  rateAll: number;
  userDetails: UserDetails;
};

const ENTITY_PER_PAGE_REVIEWS = 12;
export const TableReviews = ({ countryUserId, isRenter, rateAll, userDetails }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { localCountry } = useContext(AdminContext);
  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [skipAds, setSkipAds] = useState(0);

  const [pagination, setPagination] = useState<PaginationData>({ skip: 0, take: ENTITY_PER_PAGE_REVIEWS, total: 0 });
  const [reviews, setReviews] = useState<{ element: ReactElement; id: number }[]>([]);

  const skipAdsFilter = searchParams.get(PROP_NAME_SKIP_ADS);
  const searchTextDebounced = useDebounce(searchText, { wait: 500 });

  useEffect(() => {
    if (skipAdsFilter) {
      setSkipAds(+skipAdsFilter);
    } else {
      setSkipAds(0);
    }
  }, [skipAdsFilter]);

  const { data, loading } = useRequest(
    () =>
      getReviewsOfAdmin(
        {
          countryUserId,
          initiator: isRenter ? "lessee" : "lessor",
          search: searchTextDebounced,
          pagination: {
            skip: skipAds,
            take: ENTITY_PER_PAGE_REVIEWS,
            total: pagination.total,
          },
        },
        localCountry?.iso?.toUpperCase(),
      ),
    {
      ready: !!localCountry?.iso,
      refreshDeps: [skipAds, searchTextDebounced, isRenter, localCountry],
      onError: (err: any) => {
        openNotificationWithIcon("error", err.response?.data?.message || err?.message);
      },
      onSuccess: (data) => {
        setPagination((prev) => ({ ...prev, total: data.count }));
      },
    },
  );

  const { run: deleteReviewF } = useRequest((id: number) => deleteReview(id, localCountry.iso.toUpperCase()), {
    ready: !!localCountry?.iso,
    manual: true,
    refreshDeps: [localCountry],
    onError: (err: any) => {
      openNotificationWithIcon("error", err.response?.data?.message || err?.message);
    },
    onSuccess: (data, params) => {
      if (data)
        setReviews((prev) => {
          return prev.filter((v) => v.id !== params[0]);
        });
    },
  });

  useEffect(() => {
    if (!data) return;
    setReviews(
      data.reviews.map((review) => {
        return {
          id: review.id,
          element: (
            <ReviewComponent
              key={review.id}
              author={review.author}
              comment={review.comment}
              createdAt={review.createdAt}
              id={review.id}
              onDelete={(id) => {
                deleteReviewF(id);
              }}
              rating={review.rating}
              comments={review.comments}
              userDetails={userDetails}
              isRenter={isRenter}
            />
          ),
        };
      }),
    );
  }, [data, deleteReviewF]);
  return (
    <div className={styles.container}>
      <div className={styles.mainInfoReviews}>
        <div className={styles.amountRate}>
          <span>Reviews</span>
          <span style={{ marginLeft: 19, color: "#868686", fontSize: 18 }}>{pagination.total}</span>
          <div className={styles.flexDisplay}>
            <span style={{ color: "#868686", fontSize: 20 }}>{rateAll?.toFixed(1)}</span>
            <Rate disabled defaultValue={rateAll} className={styles.ratingOfUser} />
          </div>
        </div>
        <Input
          allowClear
          className={styles.inputSearch}
          placeholder="Search"
          onChange={(e) => {
            const value = e.target.value;
            if (value) {
              searchParams.set(PROP_NAME_INPUT_TEXT, value);
              searchParams.delete(PROP_NAME_SKIP_ADS);
            } else {
              searchParams.delete(PROP_NAME_INPUT_TEXT);
              searchParams.delete(PROP_NAME_SKIP_ADS);
            }
            setSearchParams(searchParams, { replace: true });
          }}
          value={searchText}
          suffix={<img src={mgGlass} alt="magnifyingglass" />}
        />
      </div>
      <div className={styles.reviewsContainer}>
        {loading ? (
          <Spin style={{ left: "50%", top: "50%", position: "absolute", transform: "translate(50%, -50%)" }} />
        ) : (
          <>
            <div className={styles.reviewsContainerOne}>{reviews.slice(0, 6).map((v) => v.element)}</div>
            <div className={styles.reviewsContainerTwo}>{reviews.slice(5).map((v) => v.element)}</div>
          </>
        )}
      </div>
      <Pagination
        className={styles.paginationBottom}
        hideOnSinglePage
        showSizeChanger={false}
        total={pagination.total}
        current={Math.floor(skipAds / ENTITY_PER_PAGE_REVIEWS + 1)}
        onChange={(page) => {
          const pageMinus = page - 1;
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set(PROP_NAME_SKIP_ADS, (pageMinus * ENTITY_PER_PAGE_REVIEWS).toString());
          setSearchParams(newSearchParams, { replace: true });
        }}
        defaultPageSize={ENTITY_PER_PAGE_REVIEWS}
        nextIcon={<ArrowRightIcon />}
        prevIcon={<ArrowLeftIcon />}
      />
    </div>
  );
};
