import { Button, Carousel, Segmented } from "antd";
import { useEffect, useRef, useState } from "react";
import caretRight from "assets/svg/CaretRight.svg";
import seen from "assets/svg/seen.svg";

import styles from "./index.module.css";
// import { CarouselRef } from "antd/es/carousel";

type Props = {
  photos: {
    preview: string;
  }[];
};
type Cur = {
  goTo: (slide: number, dontAnimate?: boolean) => void;
};

export const CarouselComponent = ({ photos }: Props) => {
  const [selected, setSelected] = useState("0");
  const slider = useRef<Cur>();
  const [viewed, setViewed] = useState<string[]>([]);
  const onChange = (currentslide: string) => {
    setSelected(currentslide);
  };

  useEffect(() => {
    slider.current?.goTo(Number(selected));
  }, [selected]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperCarousel}>
        <Carousel
          beforeChange={(beforeChange, next) => {
            if (next !== beforeChange) {
              onChange(String(next));
              setViewed((prev) => {
                if (prev.findIndex((v) => v === beforeChange.toString()) > -1) return prev;
                return [...prev, beforeChange.toString()];
              });
            }
          }}
          className={styles.carousel}
          arrows
          nextArrow={<Button type="primary" icon={<img src={caretRight} alt="rightArrow" />} />}
          prevArrow={
            <Button
              type="primary"
              icon={<img src={caretRight} alt="rightArrow" style={{ transform: "rotate(180deg)" }} />}
            />
          }
          ref={(node) => {
            if (!slider.current) slider.current = node;
          }}
        >
          {photos?.map((photo) => <img key={photo.preview} src={photo.preview} width={580} height={408}></img>)}
        </Carousel>
      </div>
      {viewed.find((v) => v === selected) && (
        <div className={styles.seen}>
          <img src={seen} alt="seen" />
          <span>viewed</span>
        </div>
      )}
      <div className={styles.segmentWrapper}>
        <Segmented
          options={photos?.map((photo, i) => ({
            label: <img src={photo.preview} width={86} height={72} />,
            value: String(i),
          }))}
          value={selected}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
