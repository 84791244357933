import style from "./index.module.css";
import arrowPayment from "assets/svg/ArrowUp.svg";
import arrowDown from "assets/svg/ArrowDownGreen.svg";

export const ArrowDownPaymentIcon = ({ size }: { size?: number | string }) => {
  const correctSize = typeof size === "number" ? size.toString() + "px" : size;
  return (
    <img
      src={arrowDown}
      className={style.arrow + " " + style.flexDisplay}
      style={{ width: correctSize, height: correctSize }}
    />
  );
};
export const ArrowUpPaymentIcon = ({ size }: { size?: number | string }) => {
  const correctSize = typeof size === "number" ? size.toString() + "px" : size;
  return (
    <img
      src={arrowPayment}
      className={style.arrow + " " + style.flexDisplay}
      style={{ width: correctSize, height: correctSize }}
    />
  );
};
