import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Layout, ConfigProvider } from "antd";
import "antd/dist/reset.css";
import "./index.module.css";
import "./style.css";
import MainPage, { dataKeys } from "pages/Main";
import { AdminProvider } from "context/adminContext";
import LoginPage from "pages/Login";
import AuthGuard from "helpers/AuthGuard";
import { TonProvider } from "context/tonContext";
import { UsersProvider } from "context/usersContext";
import { SocketProvider } from "context/socketContext";

import styles from "./index.module.css";
import { ChatsProvider } from "context/chatsContext";
import { NotificationProvider } from "context/notificationContext";
import { Users } from "pages/Main/Components/AdminPanel/Users";
import Wallet from "pages/Main/Components/AdminPanel/Wallet";
import Chats from "pages/Main/Components/AdminPanel/Chats";
import Options from "pages/Main/Components/AdminPanel/Options";
import Languages from "pages/Main/Components/AdminPanel/Languages";
import { Deals } from "pages/Main/Components/AdminPanel/Deals";
import updateLocale from "dayjs/plugin/updateLocale";
import Groups from "pages/Main/Components/AdminPanel/Groups";
import Countries from "pages/Main/Components/AdminPanel/Countries";
import Cities from "pages/Main/Components/AdminPanel/Cities";
import Categories from "pages/Main/Components/AdminPanel/Categories";
import { Debate } from "pages/Main/Components/AdminPanel/Debate";
import AdminList from "pages/Main/Components/AdminsList";
import Products from "pages/Main/Components/AdminPanel/Products";
import { UserComponent } from "pages/Main/Components/AdminPanel/Users/UserComponent";
import { ItemComponent } from "pages/Main/Components/AdminPanel/Products/ItemComponent";

import "antd/dist/reset.css";
import "./index.module.css";
import dayjs from "dayjs";
import { NewDebates } from "pages/Main/Components/AdminPanel/NewDebates";
import { DisputeDetails } from "pages/Main/Components/AdminPanel/NewDebates/DisputeDetails";
import { AppConfigProvider } from "context/appConfigContext";
import { Insurance } from "pages/Main/Components/AdminPanel/Insurance";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

const App: React.FC = () => {
  const lastPath = localStorage.getItem("menuKey");
  const currentNavigation = dataKeys[lastPath];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#ffcc4a",
          colorInfo: "#ffcc4a",
          fontFamily: "Mont",
        },
      }}
    >
      <NotificationProvider>
        <AdminProvider>
          <AppConfigProvider>
            <SocketProvider>
              <ChatsProvider>
                <UsersProvider>
                  <TonProvider>
                    <Layout className={styles.layout}>
                      <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route
                          path="/"
                          element={
                            <AuthGuard>
                              {currentNavigation ? (
                                <Navigate to={`/${currentNavigation}`} replace />
                              ) : (
                                <Navigate to={`/countries`} replace />
                              )}
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="*"
                          element={
                            <AuthGuard>
                              <MainPage />
                            </AuthGuard>
                          }
                        >
                          <Route
                            path="wallet"
                            element={
                              <AuthGuard>
                                <Wallet />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="adminList"
                            element={
                              <AuthGuard>
                                <AdminList />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="products"
                            element={
                              <AuthGuard>
                                <Products />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="products/item/:itemId"
                            element={
                              <AuthGuard>
                                <ItemComponent />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="chats"
                            element={
                              <AuthGuard>
                                <Chats />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="languages"
                            element={
                              <AuthGuard>
                                <Languages />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="countries"
                            element={
                              <AuthGuard>
                                <Countries />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="cities"
                            element={
                              <AuthGuard>
                                <Cities />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="categories"
                            element={
                              <AuthGuard>
                                <Categories />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="groups"
                            element={
                              <AuthGuard>
                                <Groups />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="options"
                            element={
                              <AuthGuard>
                                <Options />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="debate"
                            element={
                              <AuthGuard>
                                <Debate />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="users"
                            element={
                              <AuthGuard>
                                <Users />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="users/user"
                            element={
                              <AuthGuard>
                                <UserComponent />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="deals"
                            element={
                              <AuthGuard>
                                <Deals />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="disputes"
                            element={
                              <AuthGuard>
                                <NewDebates />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="dispute/:dealId"
                            element={
                              <AuthGuard>
                                <DisputeDetails />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="insurance"
                            element={
                              <AuthGuard>
                                <Insurance />
                              </AuthGuard>
                            }
                          />
                        </Route>
                      </Routes>
                    </Layout>
                  </TonProvider>
                </UsersProvider>
              </ChatsProvider>
            </SocketProvider>
          </AppConfigProvider>
        </AdminProvider>
      </NotificationProvider>
    </ConfigProvider>
  );
};

export default App;
