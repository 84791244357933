import { Badge } from "antd";
import { RequestDeals } from "./RequestDeals";
import styles from "./index.module.css";
import { InfoComponent } from "Components/InfoComponent";
import { TabsComponent } from "Components/TabsComponent";
import { useRequest } from "ahooks";
import { getItemDealsMetrics } from "helpers/backend_helper";
import { useContext, useMemo } from "react";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { PROP_NAME_NEXT_TAB, PROP_NAME_SKIP_ADS } from "helpers/constants";
import { useSearchParams } from "react-router-dom";

const getItems = (itemId: string) => [
  {
    key: "0",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Request</div>
      </Badge>
    ),
    children: <RequestDeals itemId={itemId} status="request" />,
  },
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Booked</div>
      </Badge>
    ),
    children: <RequestDeals itemId={itemId} status="booked" />,
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>In progress</div>
      </Badge>
    ),
    children: <RequestDeals itemId={itemId} status="in_progress" />,
  },
  {
    key: "3",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Completed</div>
      </Badge>
    ),
    children: <RequestDeals itemId={itemId} status="completed" />,
  },
  {
    key: "4",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Declined</div>
      </Badge>
    ),
    children: <RequestDeals itemId={itemId} status="declined" />,
  },
  {
    key: "5",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Dispute</div>
      </Badge>
    ),
    children: <RequestDeals itemId={itemId} status="disputed" />,
  },
];

type Props = {
  itemId: string;
};

export const TableDeals = ({ itemId }: Props) => {
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const items = useMemo(() => getItems(itemId), [itemId]);
  const { data, loading } = useRequest(
    () => {
      return getItemDealsMetrics({ itemId }, localCountry.iso.toUpperCase());
    },
    {
      ready: !!localCountry?.iso,
      refreshDeps: [itemId],
      onError: (error: any) => {
        openNotificationWithIcon("error", error?.data?.message || error.message);
      },
    },
  );

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        {[
          { infoDate: "Amount of Deals", label: "Deals", value: data?.dealsCount ? data?.dealsCount : 0, loading },
          {
            infoDate: "Sum price of deals",
            label: "Amount",
            value: data?.sumPrice ? "€" + data?.sumPrice : "€0",
            loading,
          },
          {
            infoDate: "Average Period",
            label: "Avg period",
            value: data?.avgPeriodDays ? data?.avgPeriodDays.toFixed(2) + " days" : "0 days",
            loading,
          },
          {
            infoDate: "Success Rate",
            label: "Success Rate",
            value: data?.successRate ? data?.successRate + "%" : "0%",
            loading,
          },
        ].map(({ infoDate, label, value, loading }, i) => (
          <InfoComponent key={i} infoOfData={infoDate} label={label} value={value} isLoading={loading} />
        ))}
      </div>
      <TabsComponent
        defaultActiveKey={searchParams.get(PROP_NAME_NEXT_TAB) || "0"}
        items={items}
        className={styles.tabs}
        destroyInactiveTabPane
        onChange={(activeKey) => {
          searchParams.set(PROP_NAME_NEXT_TAB, items.find((v) => v.key === activeKey).key);
          searchParams.delete(PROP_NAME_SKIP_ADS);
          setSearchParams(searchParams, { replace: true });
        }}
      />
    </div>
  );
};
