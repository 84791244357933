import { TitleComponent } from "Components/Title";
import styles from "./index.module.css";
import { SearchComponent } from "Components/SearchComponent";
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import verified from "assets/svg/Verified.svg";
import tonKeeperConnected from "assets/svg/ConnectedTon.svg";
import { Pagination as PaginationData } from "../Options";
import lock from "assets/svg/Lock.svg";
import briefcase from "assets/svg/Briefcase.svg";
import { useRequest } from "ahooks";
import { getCities, getUserAccounts, getUserMetrics } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import { Button, Divider } from "antd";
import c2cToken from "assets/svg/c2c-token.svg";
import sortIcon from "assets/svg/SortAscending.svg";
import { InfoComponent } from "Components/InfoComponent";
import { ENTITY_PER_PAGE, PROP_NAME_IS_RENTER, PROP_NAME_SKIP_ADS, PROP_NAME_SORTBY } from "helpers/constants";
import { UserNameRatingPhotoComponent } from "Components/UserNameRatingPhotoComponent";
import { TableWithPagination } from "Components/TableWithPagination";
import { Link, useSearchParams } from "react-router-dom";
import { PROP_NAME_CITY, PROP_NAME_INPUT_TEXT, PROP_NAME_STATUS } from "helpers/constants";
import { onSort } from "helpers/helper";

export type StatusInfoUsers = "verivied" | "connected_ton_keeper" | "blocked" | "lessor";

export const getDesignedStatuses = (value: StatusInfoUsers) => {
  switch (value) {
    case "verivied":
      return <img src={verified} alt="verified" />;
    case "connected_ton_keeper":
      return <img src={tonKeeperConnected} alt="tonKeeperConnected" />;
    case "blocked":
      return <img src={lock} alt="lock" />;
    case "lessor":
      return <img src={briefcase} alt="briefcase" />;
    default:
      return <div>{value}</div>;
  }
};

export const Users = () => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const [cities, setCities] = useState<{ label: string | ReactNode; value: string }[]>([]);
  const [skipAds, setSkipAds] = useState(0);
  const [pagination, setPagination] = useState<PaginationData>({ skip: 0, take: ENTITY_PER_PAGE, total: 0 });
  const [isUserInput, setIsUserInput] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT); // debounced
  const citiesFilter = searchParams.get(PROP_NAME_CITY);
  const statusFilter = searchParams.get(PROP_NAME_STATUS);
  const sortByFilter = searchParams.get(PROP_NAME_SORTBY);
  const skipAdsFilter = searchParams.get(PROP_NAME_SKIP_ADS);
  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    if (searchText && isUserInput) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(PROP_NAME_SKIP_ADS);
      setSearchParams(newSearchParams, { replace: true });
    } else {
      setIsUserInput(true);
    }
  }, [searchText]);

  useEffect(() => {
    if (sortByFilter) {
      setSortBy(sortByFilter);
    } else {
      setSortBy("");
    }
  }, [sortByFilter]);

  useEffect(() => {
    if (skipAdsFilter) {
      setSkipAds(+skipAdsFilter);
    } else {
      setSkipAds(0);
    }
  }, [skipAdsFilter]);

  const onSortBy = useCallback(
    (sortName: string) => {
      onSort(sortName, searchParams, sortBy, setSearchParams);
    },
    [searchParams, setSearchParams, sortBy],
  );

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Name & Surname",
        dataIndex: "nameSurname",
        key: "nameSurname",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Balance</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="sortBallance"
                  style={{ width: 16, height: 16, transform: sortBy === "ballance" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("ballance");
              }}
            />
          </div>
        ),
        dataIndex: "balance",
        key: "balance",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Debt</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="sortBallance"
                  style={{ width: 16, height: 16, transform: sortBy === "debt" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("debt");
              }}
            />
          </div>
        ),
        dataIndex: "debt",
        key: "debt",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Insurance Rate</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="sortBallance"
                  style={{ width: 16, height: 16, transform: sortBy === "insurance_rate" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("insurance_rate");
              }}
            />
          </div>
        ),
        dataIndex: "insurRate",
        key: "insurRate",
      },
      {
        title: " ",
        dataIndex: "actions",
        key: "actions",
      },
    ],
    [onSortBy, sortBy],
  );

  const { data: userMetrics, loading: isUserMetricsLoading } = useRequest(
    () =>
      getUserMetrics(
        {
          citiesId: citiesFilter?.length ? citiesFilter.split(",") : null,
          statuses: statusFilter?.length ? statusFilter.split(",") : null,
          searchText: searchText ? searchText : "",
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      refreshDeps: [searchParams],
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message?.toString() || error?.toString());
      },
    },
  );

  const { data: userAccounts, loading: isUserAccountsLoading } = useRequest(
    () =>
      getUserAccounts(
        {
          citiesId: citiesFilter?.length ? citiesFilter.split(",") : null,
          statuses: statusFilter?.length ? statusFilter.split(",") : null,
          searchText: searchText ? searchText : "",
        },
        localCountry.iso.toUpperCase(),
        {
          skip: skipAds,
          take: ENTITY_PER_PAGE,
          total: pagination.total,
        },
        { sortBy: sortBy },
      ),
    {
      refreshDeps: [searchParams, skipAds, sortBy],
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message?.toString() || error?.toString());
      },
      onSuccess: (data) => {
        setPagination((prev) => ({ ...prev, total: data.count }));
      },
    },
  );

  const { loading: getCitiesLoading } = useRequest(() => getCities(localCountry.iso, true), {
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: (data) => {
      setCities(() => {
        return [
          { label: "All", value: "" },
          ...data.cities.map((city) => {
            const upperCaseFirstLt = city.formattedData[0].toUpperCase() + city.formattedData.slice(1);
            return { label: upperCaseFirstLt, value: city.id.toString() };
          }),
        ];
      });
    },
  });

  const source = useMemo(
    () =>
      userAccounts?.users?.map((el, i) => ({
        ...el,
        key: i,
        id: el.id.slice(0, 4),
        phone: "+" + el.phone,
        nameSurname: <UserNameRatingPhotoComponent el={el} />,
        status: (
          <div style={{ display: "flex", justifyContent: "left", gap: 5 }}>
            {el?.status.map((v, i) => {
              return <div key={i}>{getDesignedStatuses(v)}</div>;
            })}
          </div>
        ),
        balance: (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            {el?.balance && <img src={c2cToken} alt="c2cToken" />}
            <span>{el?.balance ? el.balance : "—"}</span>
          </div>
        ),
        debt: el.debt ? <div style={el.debt ? { color: "#F40909" } : null}>{`€${el.debt}`}</div> : <div>—</div>,
        insurRate: el?.insuranceRate ? el?.insuranceRate.toFixed(2) : "—",

        actions: (
          <div className={styles.buttonWrapper}>
            <Link
              to={{
                search: `?countryUserId=${el.id}&mainId=${el.mainId}&${PROP_NAME_IS_RENTER}=true`,
                pathname: `user`,
              }}
            >
              <Button type="primary" className={styles.button}>
                Details
              </Button>
            </Link>
          </div>
        ),
      })) || [],
    [userAccounts?.users],
  );

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 20 }}>
        <TitleComponent text="Users" />
      </div>
      <SearchComponent
        inputData={{
          queryTextName: PROP_NAME_INPUT_TEXT,
          palceHolder: "Search",
        }}
        selectData={[
          {
            options: cities,
            label: "City",
            isLoadingSelect: getCitiesLoading,
            queryName: PROP_NAME_CITY,
          },
          {
            isLoadingSelect: false,
            options: [
              { label: "All", value: "" },
              {
                label: <div className={styles.selectWrapper}>Verified</div>,
                value: "verivied",
              },
              {
                label: (
                  <div className={styles.selectWrapper}>
                    <span style={{ wordWrap: "break-word", width: "80%" }}>Connected Ton Keeper</span>
                  </div>
                ),
                value: "connected_ton_keeper",
              },
              {
                label: <div className={styles.selectWrapper}>Blocked</div>,
                value: "blocked",
              },
              {
                label: <div className={styles.selectWrapper}>Lessor</div>,
                value: "lessor",
              },
            ],
            queryName: PROP_NAME_STATUS,
            label: "Status",
          },
        ]}
      />
      <Divider style={{ borderWidth: 2 }} />
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        {[
          {
            infoOfData: "Amoint of users",
            label: "Users",
            value: userMetrics?.userCounts ? userMetrics?.userCounts : 0,
            isLoading: isUserMetricsLoading,
          },
          {
            infoOfData: "Balance in coins",
            label: "Balance",
            value: (
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <img src={c2cToken} alt="c2cToken" />
                <span>{userMetrics?.balanceAmount ? userMetrics?.balanceAmount : 0}</span>
              </div>
            ),
            isLoading: isUserMetricsLoading,
          },
          {
            infoOfData: "Debt to the sevice",
            label: "Debt",
            value: "€" + (userMetrics?.debtsAmount ? userMetrics.debtsAmount : 0),
            isLoading: isUserMetricsLoading,
            isWarning: true,
          },
        ].map(({ infoOfData, label, value, isLoading, isWarning }, i) => (
          <InfoComponent
            isWarning={isWarning}
            infoOfData={infoOfData}
            label={label}
            value={value}
            key={i}
            isLoading={isLoading}
          />
        ))}
      </div>
      <TableWithPagination
        paginationTable={{
          pagination,
          skipAds,
        }}
        propsTable={{
          columns,
          dataSource: source,
          headerShow: true,
          isLoading: isUserAccountsLoading,
        }}
      />
    </div>
  );
};
