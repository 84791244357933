import { Chat } from "pages/Main/Components/Chat";
import styles from "./index.module.css";
import { useContext, useEffect, useState } from "react";
import { Button } from "antd";
import { startSupportChat } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import { AdminContext, Chats, initialMessages } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { SocketContext } from "context/socketContext";

type Props = {
  idChat: string;
  className?: string;
};
export const UserChat = ({ idChat, className }: Props) => {
  const [revealedChat, setRevealedChat] = useState<boolean>();
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { joinChatAdmin, sendMessageSupport } = useContext(SocketContext);
  const { localCountry, getChatHistory, getHistoryLoading, chats, hasMore, setChats } = useContext(AdminContext);

  const { loading, run: startSupport } = useRequest(() => startSupportChat(localCountry?.iso, idChat), {
    manual: true,
    ready: !!idChat,
    refreshDeps: [idChat],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      joinChatAdmin(idChat);
    },
  });

  useEffect(() => {
    if (idChat) getChatHistory(idChat, 0);
    return () => {
      setChats({ supportChat: initialMessages, debateChat: initialMessages } as Chats);
    };
  }, [getChatHistory, idChat, setChats]);
  return (
    <div className={className ? className + " " + styles.container : styles.container}>
      {!revealedChat && (
        <div className={styles.layer}>
          <div>You have no messages</div>
          <Button
            type="primary"
            className={styles.button}
            onClick={() => {
              startSupport();
              setRevealedChat(true);
            }}
          >
            Reveal Chat
          </Button>
        </div>
      )}
      <Chat
        chatData={chats?.supportChat}
        chatId={idChat}
        cursor={chats?.supportChat?.cursor}
        hasMore={hasMore.current}
        isArbitr={false}
        isDebateChat={false}
        loadMore={(cursor) => getChatHistory(idChat, cursor)}
        loading={loading || getHistoryLoading}
        sendSocketMessage={sendMessageSupport}
      />
    </div>
  );
};
