import { CloseOutlined } from "@ant-design/icons";
import { Modal, Form, Input, Button, Spin } from "antd";
import styles from "./index.module.css";
import { useForm } from "antd/es/form/Form";
import { useRequest } from "ahooks";
import { useContext } from "react";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import { getInsuranceRatioLimits, RateLimits, updateInsuranceRatioLimits } from "helpers/backend_helper";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const RatioLimitModal = ({ isOpen, onClose }: Props) => {
  const [form] = useForm();
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const { data: rateLimits, loading: isRateLimitsLoading } = useRequest(
    () => getInsuranceRatioLimits(localCountry.iso.toUpperCase()),
    {
      ready: !!localCountry?.iso,
      refreshDeps: [localCountry.iso],
      onError: (err: any) => {
        openNotificationWithIcon("error", err?.response?.data?.message || err.message);
      },
    },
  );

  const { run: updateRateLimits, loading: isPostRateLimitLoading } = useRequest(
    (limits: RateLimits) => updateInsuranceRatioLimits(limits, localCountry.iso.toUpperCase()),
    {
      ready: !!localCountry?.iso,
      refreshDeps: [localCountry.iso],
      manual: true,
      onError: (err: any) => {
        openNotificationWithIcon("error", err?.response?.data?.message || err.message);
      },
      onSuccess: () => {
        openNotificationWithIcon("success", "Rate limit has been updated successfully");
        onClose();
      },
    },
  );
  const onFinish = (values: RateLimits) => {
    for (const prop in values) {
      if (values[prop] instanceof Object) {
        values[prop].min = +values[prop].min;
        values[prop].max = +values[prop].max;
      } else {
        values[prop] = +values[prop];
      }
    }
    updateRateLimits(values);
  };

  const validateMinMax = (rule: any, value: number, callback: (error?: string) => void) => {
    const minValue = +form.getFieldValue([rule.field.split(".")[0], "min"]);
    const maxValue = +form.getFieldValue([rule.field.split(".")[0], "max"]);

    if (rule.field.endsWith("min") && value > maxValue) {
      callback("Min > Max");
    } else if (rule.field.endsWith("max") && value < minValue) {
      callback("Max < Min");
    } else {
      callback();
    }
  };
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Ratio Limits"
      footer={false}
      className={styles.modal}
      closeIcon={<CloseOutlined style={{ fontSize: 20, color: "#000" }} />}
    >
      <Form layout="vertical" form={form} className={styles.cascaderWrapper} onFinish={onFinish}>
        {isRateLimitsLoading ? (
          <Spin style={{ left: "50%", top: "50%", transform: "translate(50% -50%)", width: "100%", height: "100%" }} />
        ) : (
          <>
            {rateLimits &&
              Object.entries(rateLimits).map((v) => {
                if (v[1] instanceof Object)
                  return (
                    <Form.Item
                      key={v[0]}
                      validateStatus={
                        form.getFieldError([v[0], "min"]).length > 0 || form.getFieldError([v[0], "max"]).length > 0
                          ? "error"
                          : ""
                      }
                      help={form.getFieldError([v[0], "min"]).join(" ") || form.getFieldError([v[0], "max"]).join(" ")}
                      style={{ margin: 0 }}
                    >
                      <div className={styles.allWrapInfo}>
                        <span className={styles.labelOfInfo}>{v[0][0].toUpperCase() + v[0].slice(1)}</span>
                        <div className={styles.range}>
                          <Form.Item
                            name={[v[0], "min"]}
                            className={styles.formWrapperInput}
                            initialValue={v[1].min}
                            rules={[
                              { required: true, message: "This field is required" },
                              { validator: validateMinMax },
                              { pattern: /^\d*\.?\d*$/, message: "Please enter a valid number" },
                            ]}
                          >
                            <Input
                              className={`${styles.inputInRange} ${
                                form.getFieldError([v[0], "min"]).length > 0 ? styles.errorBorder : ""
                              }`}
                              suffix="min"
                              type="number"
                            />
                          </Form.Item>
                          <div style={{ marginTop: 9 }}>-</div>
                          <Form.Item
                            name={[v[0], "max"]}
                            className={styles.formWrapperInput}
                            initialValue={v[1].max}
                            rules={[
                              { required: true, message: "This field is required" },
                              { validator: validateMinMax },
                              { pattern: /^\d*\.?\d*$/, message: "Please enter a valid number" },
                            ]}
                          >
                            <Input
                              className={`${styles.inputInRange} ${
                                form.getFieldError([v[0], "max"]).length > 0 ? styles.errorBorder : ""
                              }`}
                              suffix="max"
                              type="number"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {(form.getFieldError([v[0], "min"]).length > 0 ||
                        form.getFieldError([v[0], "max"]).length > 0) && (
                        <div className={styles.errorMessage}>
                          {form.getFieldError([v[0], "min"]).join(" ") || form.getFieldError([v[0], "max"]).join(" ")}
                        </div>
                      )}
                    </Form.Item>
                  );

                return (
                  <Form.Item key={v[0]}>
                    <div className={styles.allWrapInfo}>
                      <span className={styles.labelOfInfo}>{v[0].toUpperCase()}</span>
                      <Form.Item
                        name={v[0]}
                        className={styles.formWrapperInput}
                        initialValue={v[1]}
                        rules={[
                          { required: true, message: "This field is required" },
                          { pattern: /^\d*\.?\d*$/, message: "Please enter a valid number" },
                        ]}
                      >
                        <Input
                          type="number"
                          className={styles.oneInput + " " + styles.inputInRange}
                          style={{ width: 347 }}
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                );
              })}
            <div className={styles.btnWrapper}>
              <Button type="primary" ghost onClick={onClose} disabled={isPostRateLimitLoading}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" disabled={isPostRateLimitLoading}>
                Update
              </Button>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};
