import styles from "./index.module.css";
import masterCard from "assets/svg/masterCard.svg";
import tonkeeper from "assets/svg/tonkeeper.svg";
import { WalletType, CardType } from "helpers/types";

type Props = {
  isDefault?: boolean;
  isTon: boolean;
  info: WalletType & CardType;
};
export const PaymentMethodsComponent = ({ isDefault, isTon, info }: Props) => {
  const classNameCard = isDefault ? styles.container + " " + styles.default : styles.container;
  return (
    <div className={classNameCard}>
      {isTon && (
        <>
          <div>
            <div className={styles.numberWrapper}>
              <img src={tonkeeper} alt="tonkeeper" />
              <span>{`${info.address.slice(0, 3)}...${info.address.slice(-5)}`}</span>
            </div>
            {isDefault && <div className={styles.defaultSpan}>Default</div>}
          </div>
        </>
      )}
      {!isTon && (
        <>
          <div>
            <div className={styles.numberWrapper}>
              <img src={masterCard} alt="masterCard" />
              <span>{`**** **** **** ${info.number.slice(-4)}`}</span>
            </div>
            {isDefault && <div className={styles.defaultSpan}>Default</div>}
          </div>
          <div className={styles.date}>{info?.strExpireDate}</div>
        </>
      )}
    </div>
  );
};
