import { Button } from "antd";
import { TableWithPagination } from "Components/TableWithPagination";
import sortIcon from "assets/svg/SortAscending.svg";
import styles from "./index.module.css";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Pagination } from "pages/Main/Components/AdminPanel/Options";
import { ENTITY_PER_PAGE, PROP_NAME_SKIP_ADS, PROP_NAME_SORTBY } from "helpers/constants";
import { UserNameRatingPhotoComponent } from "Components/UserNameRatingPhotoComponent";
import { PhotoNameTab } from "Components/PhotoNameTab";
import { useRequest } from "ahooks";
import { getUserDeals, sortByDealsUser, UserDealsStatuses } from "helpers/backend_helper";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { CopyButton } from "Components/CopyButton";
import dayjs from "dayjs";
import { DealsInfo } from "Components/DealsInfo";
import { useSearchParams } from "react-router-dom";
import { onSort } from "helpers/helper";

export function formatDateRange(startDate: Date, endDate: Date): string {
  if (!(startDate instanceof Date && endDate instanceof Date) || startDate > endDate) {
    console.error(`Invalid date input. Start date: ${startDate}. End date: ${endDate}`);
    return "Invalid Date";
  }
  const options: Intl.DateTimeFormatOptions = { month: "short", day: "numeric" };
  const formattedStartDate = startDate.toLocaleDateString("en-US", options);
  const formattedEndDate = endDate.toLocaleDateString("en-US", options);

  return `${formattedStartDate} – ${formattedEndDate}`;
}

type Props = {
  countryUserId: string;
  isRenter: boolean;
  status: UserDealsStatuses;
};

export const RequestDeals = ({ countryUserId, isRenter, status }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const [skipAds, setSkipAds] = useState(0);
  const [sortBy, setSortBy] = useState<sortByDealsUser>("");
  const [isOpenDeals, setIsOpenDeals] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const sortByFilter = searchParams.get(PROP_NAME_SORTBY);
  const skipAdsFilter = searchParams.get(PROP_NAME_SKIP_ADS);

  useEffect(() => {
    if (skipAdsFilter) {
      setSkipAds(+skipAdsFilter);
    } else {
      setSkipAds(0);
    }
  }, [skipAdsFilter]);
  useEffect(() => {
    if (sortByFilter) {
      setSortBy(sortByFilter as sortByDealsUser);
    } else {
      setSortBy("");
    }
  }, [sortByFilter]);

  const onSortBy = useCallback(
    (sortName: string) => {
      onSort(sortName, searchParams, sortBy, setSearchParams);
    },
    [searchParams, setSearchParams, sortBy],
  );
  const [pagination, setPagination] = useState<Pagination>({ skip: 0, take: ENTITY_PER_PAGE, total: 0 });

  const { data, loading } = useRequest(
    () =>
      getUserDeals(
        {
          countryUserId,
          initiator: isRenter ? "lessee" : "lessor",
          status,
          pagination: {
            skip: skipAds,
            take: ENTITY_PER_PAGE,
            total: pagination.total,
          },
          sort: { sortBy },
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      ready: !!localCountry.iso,
      refreshDeps: [sortBy, skipAds, isRenter],
      onError: (err: any) => {
        openNotificationWithIcon("error", err.response?.data?.message || err.message);
      },
      onSuccess: (data) => {
        setPagination((prev) => ({ ...prev, total: data.count }));
      },
    },
  );
  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Item",
        dataIndex: "item",
        key: "item",
      },
      {
        title: "Renter",
        dataIndex: "renter",
        key: "renter",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Period</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="period"
                  style={{ width: 16, height: 16, transform: sortBy === "period" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("period");
              }}
            />
          </div>
        ),
        dataIndex: "period",
        key: "period",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Amount</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="amount"
                  style={{ width: 16, height: 16, transform: sortBy === "amount" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("amount");
              }}
            />
          </div>
        ),
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Data Request</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="moderatedAt"
                  style={{ width: 16, height: 16, transform: sortBy === "dateRequest" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("dateRequest");
              }}
            />
          </div>
        ),
        dataIndex: "data_request",
        key: "data_request",
      },
      {
        title: " ",
        dataIndex: "actions",
        key: "actions",
      },
    ],
    [onSortBy, sortBy],
  );

  const source = useMemo(
    () =>
      data?.deals.map((el) => ({
        key: el.id,
        id: <CopyButton copyText="copied" sliceOfText={6} text={el.id} wait={1000} key={el.id} />,
        period: (
          <div style={{ width: 170 }}>
            <div>{formatDateRange(new Date(el.dateStart), new Date(el.dateEnd)) + ","}</div>
            <div>{el.period_days + " days"} </div>
          </div>
        ),
        amount: "€" + el.rentPrice,
        data_request: dayjs(new Date(el.dateStart)).format("DD.MM.YYYY"),
        renter: (
          <UserNameRatingPhotoComponent
            el={{
              avatar: el.avatar,
              firstName: el.firstName,
              lastName: el.lastName,
              rating: el.rating,
              isOnlyName: true,
            }}
          />
        ),
        item: <PhotoNameTab photoFullPath={el.productPhoto.preview} title={el.productName} />,
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              type="primary"
              onClick={() => {
                setIsOpenDeals(true);
                setSelectedId(el.id);
              }}
            >
              Details
            </Button>
          </div>
        ),
      })),
    [data],
  );
  return (
    <>
      <TableWithPagination
        paginationTable={{
          pagination,
          skipAds: skipAds,
        }}
        propsTable={{
          columns,
          dataSource: source,
          headerShow: true,
          isLoading: loading,
        }}
      />
      <DealsInfo
        close={() => {
          setIsOpenDeals(false);
        }}
        isOpen={isOpenDeals}
        id={selectedId}
      />
    </>
  );
};
