import { TitleComponent } from "Components/Title";
import styles from "./index.module.css";
import { SearchComponent } from "Components/SearchComponent";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useRequest } from "ahooks";
import { AdminRentsStatus, getAdminRentsMetrics, getCategories, getCities } from "helpers/backend_helper";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { Badge, Divider } from "antd";
import { TabsComponent } from "Components/TabsComponent";
import { RequestDeals } from "./RequestDeals";
import { InfoComponent } from "Components/InfoComponent";
import { RangePickerComponent } from "Components/RangePickerComponent";
import {
  PROP_NAME_CITY,
  PROP_NAME_FROM_DATE,
  PROP_NAME_INPUT_TEXT,
  PROP_NAME_MAIN_TAB,
  PROP_NAME_PRODUCT_CATEGORY,
  PROP_NAME_SKIP_ADS,
  PROP_NAME_TO_DATE,
} from "helpers/constants";
import { useSearchParams } from "react-router-dom";

const STATUSES_ADMIN: AdminRentsStatus[] = ["request", "booked", "in_progress", "completed", "declined"];

const getItems = ({ dateRange }: { dateRange: { to: string; from: string } }) => [
  {
    key: "0",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Request</div>
      </Badge>
    ),
    children: <RequestDeals status="request" />,
  },
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Booked</div>
      </Badge>
    ),
    children: <RequestDeals status="booked" />,
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>In progress</div>
      </Badge>
    ),
    children: <RequestDeals status="in_progress" />,
  },
  {
    key: "3",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Completed</div>
      </Badge>
    ),
    children: <RequestDeals status="completed" dateRange={dateRange} />,
  },
  {
    key: "4",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Declined</div>
      </Badge>
    ),
    children: <RequestDeals status="declined" />,
  },
];

type CatLabelId = { children: CatLabelId; name: string; id: string; productCategories: CatLabelId }[];
const getAllCategoriesLabelIds = (data: CatLabelId) => {
  let res: { label: string; value: string }[] = [];

  for (let i = 0; i < data.length; i++) {
    res.push({ label: data[i].name, value: data[i].id });

    if (data[i].children.length) {
      const result = getAllCategoriesLabelIds(data[i].children);
      if (result) {
        res = res.concat(result);
      }
    }
  }

  return res;
};
export const Deals = () => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const [cities, setCities] = useState<{ label: string | ReactNode; value: string }[]>([]);
  const [categories, setCategories] = useState<{ label: string | ReactNode; value: string }[]>([]);
  const [currentStatus, setCurrentStatus] = useState<AdminRentsStatus>("request");

  const [searchParams, setSearchParams] = useSearchParams();

  const citiesFilter = searchParams.get(PROP_NAME_CITY);
  const categoriesFilter = searchParams.get(PROP_NAME_PRODUCT_CATEGORY);
  const statusTabFilter = searchParams.get(PROP_NAME_MAIN_TAB);
  const fromDateFilter = searchParams.get(PROP_NAME_FROM_DATE);
  const toDateFilter = searchParams.get(PROP_NAME_TO_DATE);

  const fromToDate = useMemo(
    () => ({
      from: fromDateFilter || "",
      to: toDateFilter || "",
    }),
    [fromDateFilter, toDateFilter],
  );
  const items = useMemo(
    () =>
      getItems({
        dateRange: fromToDate,
      }),
    [fromToDate],
  );
  useEffect(() => {
    if (statusTabFilter) {
      setCurrentStatus(STATUSES_ADMIN[statusTabFilter]);
    }
  }, [statusTabFilter]);
  const { loading: getCitiesLoading } = useRequest(() => getCities(localCountry.iso, true), {
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || error.message);
    },
    onSuccess: (data) => {
      setCities(() => {
        return [
          { label: "All", value: "" },
          ...data.cities.map((city) => {
            const upperCaseFirstLt = city.formattedData[0].toUpperCase() + city.formattedData.slice(1);
            return { label: upperCaseFirstLt, value: city.id };
          }),
        ];
      });
    },
  });

  const { loading: getLoading } = useRequest(() => getCategories(localCountry?.iso, true), {
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || error.message);
    },
    onSuccess: (data) => {
      const result = getAllCategoriesLabelIds(data);
      setCategories(() => {
        return [
          { label: "All", value: "" },
          ...result.map((category) => ({
            label: category.label[0].toUpperCase() + category.label.slice(1),
            value: category.value,
          })),
        ];
      });
    },
  });

  const { data, loading } = useRequest(
    () =>
      getAdminRentsMetrics(
        {
          cityIds: citiesFilter?.length ? citiesFilter.split(",") : [],
          productCategories: categoriesFilter?.length ? categoriesFilter.split(",") : [],
          status: currentStatus,
          dateRange: currentStatus === "completed" && fromToDate,
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      refreshDeps: [localCountry, currentStatus, searchParams],
      ready: !!localCountry.iso,
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message || error.message);
      },
    },
  );

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 20 }}>
        <TitleComponent text="Deals" />
      </div>
      <SearchComponent
        selectData={[
          {
            isLoadingSelect: getCitiesLoading,
            options: cities,
            label: "City",
            queryName: PROP_NAME_CITY,
          },
          {
            isLoadingSelect: getLoading,
            options: categories,
            label: "Product Category",
            queryName: PROP_NAME_PRODUCT_CATEGORY,
          },
        ]}
        inputData={{
          palceHolder: "Search",
          queryTextName: PROP_NAME_INPUT_TEXT,
        }}
      />
      <Divider style={{ borderWidth: 2 }} />
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        {currentStatus !== "completed"
          ? [
              {
                infoDate: "Amount of Deals",
                label: "Deals",
                value: data?.currentCount,
                loading: loading,
              },
              {
                infoDate: "Sum price of deals",
                label: "Amount",
                value: "€" + +data?.currentAmount,
                loading: loading,
              },
              {
                infoDate: "Average Period",
                label: "Avg period",
                value: +data?.currentAvgPeriodDays + " days",
                loading: loading,
              },
            ].map(({ infoDate, label, value, loading }, i) => (
              <InfoComponent key={i} infoOfData={infoDate} label={label} value={value} isLoading={loading} />
            ))
          : [
              {
                infoDate: "Amount of Deals",
                label: "Deals",
                value: +data?.currentCount,
                loading: loading,
                moreInfo: data?.countChangePercentage !== "0.00" &&
                  data?.countChangePercentage && {
                    value: data.countChangePercentage + "%",
                    isPositive: data.countChangePercentage[0] !== "-",
                  },
              },
              {
                infoDate: "Sum price of deals",
                label: "Amount",
                value: "€" + +data?.currentAmount,
                loading: loading,
                moreInfo: data?.amountChangePercentage !== "0.00" &&
                  data?.amountChangePercentage && {
                    value: data.amountChangePercentage + "%",
                    isPositive: data.amountChangePercentage[0] !== "-",
                  },
              },
              {
                infoDate: "Average Period",
                label: "Avg period",
                value:
                  +data?.currentAvgPeriodDays === 1
                    ? +data?.currentAvgPeriodDays + " day"
                    : +data?.currentAvgPeriodDays + " days",
                loading: loading,
                moreInfo: data?.avgPeriodDaysChangePercentage !== "0.00" &&
                  data?.avgPeriodDaysChangePercentage && {
                    value: data.avgPeriodDaysChangePercentage + "%",
                    isPositive: data.avgPeriodDaysChangePercentage[0] !== "-",
                  },
              },
              {
                infoDate: "Insurance Rate",
                label: "Insurance Rate",
                value: data?.currentAvgInsuranceCoefficient,
                loading: loading,
                moreInfo: data?.insuranceCoefficientChangePercentage !== "0.00" &&
                  data?.insuranceCoefficientChangePercentage && {
                    value: data?.insuranceCoefficientChangePercentage + "%",
                    isPositive: data?.insuranceCoefficientChangePercentage[0] !== "-",
                  },
              },
              {
                infoDate: "Success Rate",
                label: "Success Rate",
                value: +data?.currentSuccessRate + "%",
                loading: loading,
                moreInfo: data?.successRateChangePercentage !== "0.00" &&
                  data?.successRateChangePercentage && {
                    value: data.successRateChangePercentage + "%",
                    isPositive: data.successRateChangePercentage[0] !== "-",
                  },
              },
            ].map(({ infoDate, label, value, loading, moreInfo }, i) => {
              if (i === 2) {
                return (
                  <div key={i} style={{ marginRight: 20 }}>
                    <InfoComponent
                      key={i}
                      infoOfData={infoDate}
                      label={label}
                      value={value}
                      isLoading={loading}
                      moreInfo={moreInfo}
                    />
                  </div>
                );
              }

              return (
                <InfoComponent
                  key={i}
                  infoOfData={infoDate}
                  label={label}
                  value={value}
                  isLoading={loading}
                  moreInfo={moreInfo}
                />
              );
            })}
      </div>
      <TabsComponent
        defaultActiveKey={searchParams.get(PROP_NAME_MAIN_TAB) || "0"}
        items={items}
        tabBarExtraContent={{
          right:
            currentStatus === "completed" ? (
              <div
                style={{
                  position: "relative",
                  top: 5,
                  left: 20,
                }}
              >
                <RangePickerComponent
                  defaultValue={fromDateFilter ? fromToDate : null}
                  onChange={(_, date) => {
                    const dateSplitedFrom = date[0].split(".");
                    const dateSplitedTo = date[1].split(".");
                    const dateCorrectFrom = `${dateSplitedFrom[2]}-${dateSplitedFrom[1]}-${dateSplitedFrom[0]}`;
                    const dateCorrectTo = `${dateSplitedTo[2]}-${dateSplitedTo[1]}-${dateSplitedTo[0]}`;

                    searchParams.set(PROP_NAME_FROM_DATE, dateCorrectFrom);
                    searchParams.set(PROP_NAME_TO_DATE, dateCorrectTo);
                    setSearchParams(searchParams);
                  }}
                  onDelete={() => {
                    searchParams.delete(PROP_NAME_FROM_DATE);
                    searchParams.delete(PROP_NAME_TO_DATE);
                    setSearchParams(searchParams);
                  }}
                />
              </div>
            ) : null,
        }}
        className={styles.tabs}
        destroyInactiveTabPane
        onChange={(activeKey) => {
          searchParams.set(PROP_NAME_MAIN_TAB, items.find((v) => v.key === activeKey).key);
          searchParams.delete(PROP_NAME_SKIP_ADS);
          searchParams.delete(PROP_NAME_FROM_DATE);
          searchParams.delete(PROP_NAME_TO_DATE);
          setSearchParams(searchParams, { replace: true });
        }}
      />
    </div>
  );
};
