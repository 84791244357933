import { useDebounce, useRequest } from "ahooks";
import { TableWithPagination } from "Components/TableWithPagination";
import { Pagination as PaginationData } from "pages/Main/Components/AdminPanel/Options";
import {
  getInsurance,
  getInsuranceMetrics,
  InsuranceType,
  InusranceStatuses,
  SortInsuranceBy,
} from "helpers/backend_helper";
import arrowDown from "assets/svg/ArrowDownGreen.svg";
import sortIcon from "assets/svg/SortAscending.svg";
import {
  ENTITY_PER_PAGE,
  PROP_NAME_CITY,
  PROP_NAME_FROM_DATE_SEARCH_MAIN,
  PROP_NAME_INPUT_TEXT,
  PROP_NAME_INSURANCE,
  PROP_NAME_SKIP_ADS,
  PROP_NAME_SORTBY,
  PROP_NAME_TO_DATE_SEARCH_MAIN,
} from "helpers/constants";
import { NotificationContext } from "context/notificationContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AdminContext } from "context/adminContext";
import styles from "./index.module.css";
import { Button, Tooltip } from "antd";
import { useSearchParams } from "react-router-dom";
import { onSort } from "helpers/helper";
import { UserNameRatingPhotoComponent } from "Components/UserNameRatingPhotoComponent";
import { DealsInfo } from "Components/DealsInfo";
import c2cToken from "assets/svg/c2c-token.svg";
import { InfoComponent } from "Components/InfoComponent";

type Props = {
  status: InusranceStatuses;
};

const INS_TYPE = {
  PERCENTAGE: "Ins fee",
  DEPOSIT: "Deposit",
};
export const RequestInsurance = ({ status }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [sortBy, setSortBy] = useState<SortInsuranceBy>("");
  const [skipAds, setSkipAds] = useState(+searchParams.get(PROP_NAME_SKIP_ADS) || 0);
  const [pagination, setPagination] = useState<PaginationData>({ skip: 0, take: ENTITY_PER_PAGE, total: 0 });
  const sortByFilter = searchParams.get(PROP_NAME_SORTBY);
  const [selectedId, setSelectedId] = useState("");
  const [isDealOpen, setIsDealOpen] = useState(false);
  const [isUserInput, setIsUserInput] = useState(false);
  const isRefresh = useDebounce(searchParams, { wait: 100 });

  const skipAdsFilter = searchParams.get(PROP_NAME_SKIP_ADS);
  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT); // debounced
  const citiesFilter = searchParams.get(PROP_NAME_CITY);
  const searchToDate = searchParams.get(PROP_NAME_TO_DATE_SEARCH_MAIN);
  const searchFromDate = searchParams.get(PROP_NAME_FROM_DATE_SEARCH_MAIN);
  const searchInsurance = searchParams.get(PROP_NAME_INSURANCE);

  useEffect(() => {
    if (searchText && isUserInput) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(PROP_NAME_SKIP_ADS);
      setSearchParams(newSearchParams, { replace: true });
    } else {
      setIsUserInput(true);
    }
  }, [searchText]);

  useEffect(() => {
    if (sortByFilter) {
      setSortBy(sortByFilter as SortInsuranceBy);
    } else {
      setSortBy("");
    }
  }, [sortByFilter]);

  const onSortBy = useCallback(
    (sortName: string) => {
      onSort(sortName, searchParams, sortBy, setSearchParams);
    },
    [searchParams, setSearchParams, sortBy],
  );

  useEffect(() => {
    if (skipAdsFilter) {
      setSkipAds(+skipAdsFilter);
    } else {
      setSkipAds(0);
    }
  }, [skipAdsFilter]);

  const { loading, data: insuranceData } = useRequest(
    () =>
      getInsurance(
        {
          pagination: {
            skip: skipAds,
            take: ENTITY_PER_PAGE,
            total: pagination.total,
          },
          processType: status,
          searchText,
          cities: citiesFilter?.length ? citiesFilter.split(",") : [],
          dateEnd: searchToDate,
          dateStart: searchFromDate,
          insuranceType: searchInsurance?.length ? (searchInsurance.split(",") as InsuranceType[]) : [],
          sort: { sortBy },
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      ready: !!localCountry?.iso,
      refreshDeps: [localCountry, isRefresh],
      onError: (err: any) => {
        openNotificationWithIcon("error", err.response?.data?.message || err?.message);
      },
      onSuccess: (data) => {
        setPagination((prev) => ({ ...prev, total: +data.total }));
      },
    },
  );

  const { loading: isMetricsLoading, data: metrics } = useRequest(
    () =>
      getInsuranceMetrics(
        {
          processType: status,
          searchText,
          cities: citiesFilter?.length ? citiesFilter.split(",") : [],
          dateEnd: searchToDate,
          dateStart: searchFromDate,
          insuranceType: searchInsurance?.length ? (searchInsurance.split(",") as InsuranceType[]) : [],
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      ready: !!localCountry?.iso,
      refreshDeps: [localCountry, isRefresh],
      onError: (err: any) => {
        openNotificationWithIcon("error", err.response?.data?.message || err?.message);
      },
    },
  );

  const source = useMemo(
    () =>
      insuranceData?.data.map((el, i) => {
        const date = new Date(el.date);
        return {
          ...el,
          key: i,
          id: (
            <Tooltip title={el.transactionId}>
              <div style={{ display: "flex", gap: 15 }}>
                <img src={arrowDown} alt="arrow down" />
                <div>{`${el?.transactionId?.slice(0, 5)}`}</div>
              </div>
            </Tooltip>
          ),
          date: date.toLocaleDateString("eng", {
            day: "2-digit",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
          }),
          user: (
            <UserNameRatingPhotoComponent
              el={{
                avatar: el.user.avatar,
                firstName: el.user.name,
                isOnlyName: true,
                rating: el.user.rating,
                lastName: "",
              }}
            />
          ),
          amount: <div>{el?.amount ? `€${el.amount.toFixed(3)}` : "-"}</div>,
          dealId: (
            <Tooltip title={el.rentId}>
              <div>{`${el.rentId.slice(0, 5)}`}</div>
            </Tooltip>
          ),
          insurance: INS_TYPE[el.insuranceType],
          actions: (
            <div className={styles.buttonWrapper}>
              <Button type="primary" ghost className={styles.button} style={{ borderWidth: 2, color: "#3E3E3E" }}>
                Invoice
              </Button>
              <Button
                type="primary"
                className={styles.button}
                onClick={() => {
                  setIsDealOpen(true);
                  setSelectedId(el.rentId);
                }}
              >
                Details
              </Button>
            </div>
          ),
        };
      }) || [],
    [insuranceData?.data],
  );

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Date</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="Date"
                  style={{ width: 16, height: 16, transform: sortBy === "created_at" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("created_at");
              }}
            />
          </div>
        ),
        dataIndex: "date",
        key: "date",
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Amount</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="amount"
                  style={{ width: 16, height: 16, transform: sortBy === "amount" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("amount");
              }}
            />
          </div>
        ),
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "Deal ID",
        dataIndex: "dealId",
        key: "dealId",
      },
      {
        title: "Insurance",
        dataIndex: "insurance",
        key: "insurance",
      },
      {
        title: " ",
        dataIndex: "actions",
        key: "actions",
      },
    ],
    [sortBy],
  );

  return (
    <div className={styles.wrapper}>
      {metrics && (
        <div style={{ display: "flex", gap: 10 }}>
          {[
            {
              infoDate: "Insurance amount in c2c tokens and euros",
              label: "Amount",
              value: (
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <img src={c2cToken} alt="c2cToken" />
                  <span style={{ lineHeight: "0" }}>{metrics.walletAmount.value}</span>
                </div>
              ),
              loading: isMetricsLoading,
              moreInfo: metrics.walletAmount.change
                ? {
                    value: `${metrics.walletAmount.change}%`,
                    isPositive: metrics.walletAmount.change > 0,
                  }
                : null,
              moreValue: {
                value: "€" + metrics.bankAmount.value,
                moreInfo: metrics.bankAmount.change
                  ? {
                      value: `${metrics.bankAmount.change}%`,
                      isPositive: metrics.bankAmount.change > 0,
                    }
                  : null,
              },
            },
            status && {
              infoDate: "Amount of Deals",
              label: "Deposit Rate",
              value: `${metrics.depositRate.value}%`,
              moreInfo: metrics.depositRate.change
                ? {
                    value: `${metrics.depositRate.change}%`,
                    isPositive: metrics.depositRate.change > 0,
                  }
                : null,
              loading: isMetricsLoading,
            },
            !status && {
              infoDate: "Freeze rate of incoming and outgoing deposits",
              label: "Deals",
              value: (
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <img src={c2cToken} alt="c2cToken" />
                  <span style={{ lineHeight: "0" }}>{metrics.walletDepositAmount.value}</span>
                </div>
              ),
              loading: isMetricsLoading,
              moreInfo: metrics.walletDepositAmount.change
                ? {
                    value: `${metrics.walletDepositAmount.change}%`,
                    isPositive: metrics.walletDepositAmount.change > 0,
                  }
                : null,
              moreValue: {
                value: "€" + metrics.bankDepositAmount.value,
                moreInfo: metrics.bankDepositAmount.change
                  ? {
                      value: `${metrics.bankDepositAmount.change}%`,
                      isPositive: metrics.bankDepositAmount.change > 0,
                    }
                  : null,
              },
            },
            !status &&
              metrics.payOutRate && {
                infoDate: "Payout to collection ratio",
                label: "Payout Rate",
                value: metrics.payOutRate.value,
                moreInfo: metrics.payOutRate.change
                  ? {
                      value: `${metrics.payOutRate.change}%`,
                      isPositive: metrics.payOutRate.change > 0,
                    }
                  : null,
                loading: isMetricsLoading,
              },
          ]
            .filter((v) => v)
            .map(({ infoDate, label, value, loading, moreValue, moreInfo }, i) => (
              <InfoComponent
                key={i}
                infoOfData={infoDate}
                label={label}
                value={value}
                isLoading={loading}
                moreValue={moreValue}
                moreInfo={moreInfo}
              />
            ))}
        </div>
      )}
      <TableWithPagination
        paginationTable={{
          pagination,
          skipAds: skipAds,
        }}
        propsTable={{ columns, dataSource: source, headerShow: true, isLoading: loading }}
      />
      <DealsInfo
        isOpen={isDealOpen}
        close={() => {
          setIsDealOpen(false);
        }}
        id={selectedId}
      />
    </div>
  );
};
