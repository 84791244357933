import dayjs from "dayjs";
import { Category } from "./types";
import { PROP_NAME_SORTBY } from "./constants";
import { SetURLSearchParams } from "react-router-dom";

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const initSearchData = { searchCity: [""], searchStatus: [""] };
export const initSearchDispute = { city: [""], insurance: [""] };

export const getQueryFromArray = (array: Array<string | number>, queryName: string) => {
  return array.reduce((all, next) => all + `&${queryName}=${next}`, "");
};

export const highlightText = (text: string, query: string, className: string): React.ReactNode => {
  if (!query) return text;

  const regex = new RegExp(query, "gi");
  let match;
  let lastIndex = 0;
  const highlightedParts: React.ReactNode[] = [];

  while ((match = regex.exec(text)) !== null) {
    highlightedParts.push(text.substring(lastIndex, match.index));
    highlightedParts.push(
      <span className={className} key={match.index}>
        {match[0]}
      </span>,
    );
    lastIndex = match.index + match[0].length;
  }
  highlightedParts.push(text.substring(lastIndex));
  return highlightedParts;
};

export const getTreeItems = (category: Category) => {
  let children: any[] = [];

  if (category.children.length && category.productCategories.length) {
    children = category.children?.map((el) => getTreeItems(el));
  }
  if (category.productCategories.length) {
    const otherChildren = category.productCategories?.map((el) => ({
      ...el,
      value: el.id,
      label: el.name,
      isProduct: true,
      children: [],
    }));

    if (children?.length) {
      children = children.concat(otherChildren);
    } else {
      children = otherChildren;
    }
  }

  if (category.children.length && !category.productCategories.length) {
    children = category.children?.map((el) => getTreeItems(el));
  }

  return {
    value: category.id,
    id: category.id,
    order: category.order,
    language: category.languageCode,
    key: category.id,
    label: category.name,
    isProduct: false,
    children,
  };
};

export const extractProductCategories = (dataArray) => {
  const result = new Set();

  function traverse(node) {
    if (node.productCategories && Array.isArray(node.productCategories)) {
      node.productCategories.forEach((category) => {
        result.add(JSON.stringify({ id: category.id, name: category.name }));
      });
    }

    if (node.children && Array.isArray(node.children)) {
      node.children.forEach((child) => traverse(child));
    }
  }

  dataArray.forEach((data) => traverse(data));

  return Array.from(result).map((item: string) => JSON.parse(item));
};

const DAY_MILLISECONDS = 86400000;
export const getDays = (from: string, to: string) => {
  const days: string[] = [];
  const fromMilliseconds = +new Date(from);
  const toMilliseconds = +new Date(to);

  let dayInMilliseconds = fromMilliseconds;
  while (dayInMilliseconds <= toMilliseconds) {
    days.push(dayjs(new Date(dayInMilliseconds)).format("YYYY-MM-DD"));

    dayInMilliseconds += DAY_MILLISECONDS;
  }

  return days;
};

export const formatDate = (date: string, options: Intl.DateTimeFormatOptions) =>
  new Date(date).toLocaleDateString("en-GB", options).replaceAll("/", ".");
export const trimEndDate = (endDate: string) => endDate.replace("T23:59:59.999", "");
const monthDayOptions: Intl.DateTimeFormatOptions = { month: "short", day: "numeric" };
const MILISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export const getPeriod = (startDate: string, endDate: string) => {
  const start = formatDate(startDate, monthDayOptions);
  const end = formatDate(trimEndDate(endDate), monthDayOptions);

  const daysCount = Math.ceil((new Date(endDate).getTime() - new Date(startDate).getTime()) / MILISECONDS_IN_DAY) - 1;

  return `${start} - ${end}, \n ${daysCount} day${daysCount > 1 ? "s" : ""}`;
};

export const onSort = (
  sortName: string,
  searchParams: URLSearchParams,
  sortBy: string,
  setSearchParams: SetURLSearchParams,
) => {
  const newSearchParams = new URLSearchParams(searchParams);
  if (sortBy === sortName) {
    newSearchParams.delete(PROP_NAME_SORTBY);
  } else {
    newSearchParams.set(PROP_NAME_SORTBY, sortName);
  }
  setSearchParams(newSearchParams, { replace: true });
};
