import { StepData } from "helpers/backend_helper";
import styles from "./index.module.css";
import dayjs from "dayjs";
import { ClockCircleOutlined } from "@ant-design/icons";

type Props = {
  step: StepData;
};

export const HistoryLine = ({ step }: Props) => {
  const lesseTime = dayjs(new Date(step.lessee?.date));
  const lessorTime = dayjs(new Date(step.lessor?.date));
  const stepLessee = step?.lessee?.temp ? (
    <div>
      <ClockCircleOutlined className={styles.clockOutlined} /> <span>{step.lessee.action}</span>
    </div>
  ) : step?.lessee ? (
    <span>{step.lessee.action}</span>
  ) : null;
  const stepLessor = step?.lessor?.temp ? (
    <div>
      <ClockCircleOutlined className={styles.clockOutlined} /> <span>{step.lessor.action}</span>
    </div>
  ) : step?.lessor ? (
    <span>{step.lessor.action}</span>
  ) : null;
  const formatedStep = step.step.match("_") ? step.step.split("_").join(" ") : step.step;
  return (
    <div className={styles.line}>
      <span>{formatedStep[0].toUpperCase() + formatedStep.slice(1)}</span>
      <span className={styles.separate}>
        {stepLessee}
        <span style={{ marginRight: 35 }}>
          {step.lessee?.date ? `${lesseTime.format("DD.MM.YY")}, ${lesseTime.format("HH:MM")}` : ""}
        </span>
      </span>
      <span className={styles.separate}>
        {stepLessor}
        <span style={{ marginRight: 35 }}>
          {step.lessor?.date ? `${lessorTime.format("DD.MM.YY")}, ${lessorTime.format("HH:MM")}` : ""}
        </span>
      </span>
      <span>Rental Agreement</span>
    </div>
  );
};
