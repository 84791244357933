import { useCallback, useState } from "react";
import { SwitcherDispute } from "./SwitcherDispute";
import { Button, DatePicker, Divider, Form, Input, Modal, Switch, Upload } from "antd";
import styles from "./index.module.css";
import { useForm } from "antd/es/form/Form";
import warning from "assets/svg/Warning.svg";
import { PaperClipIcon } from "Common/Icons/PaperClip";
import { ModalComponent } from "Components/ModalComponent";

export const ManageModalDisputes = ({ debateInfo }: { debateInfo: any }) => {
  const [choosenReturn, setChoosenReturn] = useState(2);
  const [form] = useForm();
  const [isFileAttached, setIsFileAttached] = useState<"is" | "no" | "initiate">();
  const [isCompenstation, setIsCompensation] = useState(false);
  const [isCancelDeal, setIsCancelDeal] = useState(false);
  const [isOpenModalAgreement, setIsOpenModalAgreement] = useState(false);
  const [isPaidNotTransfered, isGoodWithLessor, isNotReturned, isReturned, isReturnedOrWtf] = [
    true,
    false,
    false,
    false,
    false,
  ];

  console.log(
    choosenReturn,
    setIsFileAttached,
    setIsCompensation,
    isGoodWithLessor,
    isNotReturned,
    isReturned,
    isReturnedOrWtf,
  );
  console.log(isFileAttached);
  const onFinish = useCallback((v) => {
    console.log(v);
  }, []);
  return (
    <>
      <Modal open title="Manage Dispute" className={styles.modal} closeIcon="X" footer={false}>
        <Form layout="vertical" form={form} className={styles.cascaderWrapper} onFinish={onFinish}>
          <Form.Item
            label={
              <div className={styles.limitationWrapper}>
                <img src={warning} alt="warning" />
                <span className={styles.titleFirst}>Limitations</span>
              </div>
            }
            className={styles.limitationsWrapper}
          >
            <Form.Item name={["limitations", "lessor"]} noStyle>
              <div className={styles.limitationSwitchWrapper}>
                <Switch className={styles.switcher} />
                <span className={styles.switchLimitationLabel}>Lessor</span>
              </div>
            </Form.Item>
            <Form.Item name={["limitations", "renter"]} noStyle>
              <div className={styles.limitationSwitchWrapper}>
                <Switch className={styles.switcher} />

                <span className={styles.switchLimitationLabel}>Renter</span>
              </div>
            </Form.Item>
            <Form.Item name={["limitations", "disableAd"]} noStyle>
              <div className={styles.limitationSwitchWrapper}>
                <Switch className={styles.switcher} />

                <span className={styles.switchLimitationLabel}>Disable Ad</span>
              </div>
            </Form.Item>
          </Form.Item>
          <Divider style={{ borderWidth: 2, borderColor: "#C6C5C5" }} />
          {debateInfo.rent.status === "wds" && (
            <div className={styles.returnSwithcer}>
              <div className={styles.titleFirst} style={{ marginBottom: 20 }}>
                Return
              </div>
              <SwitcherDispute onChoose={(v) => setChoosenReturn(v)} value={choosenReturn} />
            </div>
          )}
          <Form.Item className={styles.transReturnDate} style={!isCompenstation && { marginBottom: 20 }}>
            <Form.Item name={["date", "transferDate"]} label="Transfer Date" className={styles.labelFormFont}>
              <DatePicker className={styles.date} format={{ format: "DD.MM.YYYY" }} />
            </Form.Item>
            <Form.Item name={["date", "returnDate"]} label="Return Date" className={styles.labelFormFont}>
              <DatePicker className={styles.date} format={{ format: "DD.MM.YYYY" }} />
            </Form.Item>
          </Form.Item>
          {isPaidNotTransfered && (
            <div className={styles.warningReturn}>
              <div style={{ display: "flex", gap: 4 }}>
                <img src={warning} alt="warning" />
                <span>Deal paid, but Item hasn’t been transferred</span>
              </div>
              <Button
                type="primary"
                ghost
                danger
                onClick={() => {
                  setIsCancelDeal(true);
                  setIsOpenModalAgreement(true);
                }}
              >
                Cancel the Deal
              </Button>
            </div>
          )}
          {isCompenstation && (
            <>
              <Divider style={{ borderWidth: 2, borderColor: "#C6C5C5" }} />
              <div className={styles.titleFirst} style={{ marginBottom: 20 }}>
                Compensation
              </div>
              <Form.Item className={styles.compensationWrapper} style={{ marginBottom: 20 }}>
                <Form.Item>
                  <Form.Item className={styles.rentcompwrap} style={{ marginBottom: 16 }}>
                    <span className={styles.titleFirst}>Renter</span>
                    <Form.Item name={["renter", "responsible"]} style={{ display: "inline-block" }}>
                      <div className={styles.limitationSwitchWrapper}>
                        <Switch className={styles.switcher} />
                        <span className={styles.switchLimitationLabel}>Responsible</span>
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label="Rental Return"
                    name={["renter", "rentalReturn"]}
                    className={styles.labelFormFont}
                    style={{ marginBottom: 16 }}
                  >
                    <Input className={styles.inputAntd} suffix="€" placeholder="34 max" />
                  </Form.Item>
                  <Form.Item
                    label="Compensation from Insurance"
                    name={["renter", "compIns"]}
                    className={styles.labelFormFont}
                    style={{ marginBottom: 4 }}
                  >
                    <Input className={styles.inputAntd} suffix="€" placeholder="34 max" />
                  </Form.Item>
                  <Form.Item
                    name={["renter", "provideOffline"]}
                    className={styles.labelFormFont}
                    style={{ marginBottom: 16 }}
                  >
                    <div className={styles.limitationSwitchWrapper}>
                      <Switch className={styles.switcher} />

                      <span className={styles.switchLimitationLabel}>Provided offline</span>
                    </div>
                  </Form.Item>
                  <Form.Item label="Debt" name={["renter", "debt"]} className={styles.labelFormFont}>
                    <Input className={styles.inputAntd} suffix="€" placeholder="34 max" />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <div style={{ height: 32, display: "flex", alignItems: "center", marginBottom: 16 }}>
                    <span className={styles.titleFirst}>Lessor</span>
                  </div>
                  <Form.Item
                    label="Compensation from Deposit"
                    name={["lessor", "compDep"]}
                    className={styles.labelFormFont}
                    style={{ marginBottom: 16 }}
                  >
                    <Input className={styles.inputAntd} suffix="€" placeholder="34 max" />
                  </Form.Item>
                  <Form.Item
                    label="Compensation from Insurance"
                    name={["lessor", "compIns"]}
                    className={styles.labelFormFont}
                    style={{ marginBottom: 4 }}
                  >
                    <Input className={styles.inputAntd} suffix="€" placeholder="34 max" />
                  </Form.Item>
                  <Form.Item
                    name={["lessor", "provideOffline"]}
                    className={styles.labelFormFont}
                    style={{ marginBottom: 16 }}
                  >
                    <div className={styles.limitationSwitchWrapper}>
                      <Switch className={styles.switcher} />

                      <span className={styles.switchLimitationLabel}>Provided offline</span>
                    </div>
                  </Form.Item>
                  <Form.Item label="Debt" name={["lessor", "debt"]} className={styles.labelFormFont}>
                    <Input className={styles.inputAntd} suffix="€" placeholder="34 max" />
                  </Form.Item>
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="publicComment"
                label="Public comment (will be shown in common Chat)"
                className={styles.labelFormFont}
                style={{ marginBottom: 8 }}
              >
                <Input.TextArea className={styles.textArea} placeholder="Public comment" />
              </Form.Item>
              <Form.Item
                name="reportFile"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  setIsFileAttached(e.fileList.length ? "is" : "no");
                  return e.fileList.length ? e.fileList : null;
                }}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Upload beforeUpload={() => false} className={isFileAttached === "is" && styles.upload}>
                  <Button
                    type="primary"
                    ghost
                    icon={<PaperClipIcon width="16px" height="16px" margin="0" backgroundSize="16px 16px" />}
                    className={styles.uploadReport}
                    style={isFileAttached === "initiate" ? { marginBottom: 20 } : null}
                    disabled={isFileAttached === "is"}
                  >
                    Attach Report
                  </Button>
                </Upload>
              </Form.Item>
              {isFileAttached === "no" && (
                <div className={styles.attachWarnign}>
                  <img src={warning} alt="warning" />
                  <span>Attach Report to complete the Dispute</span>
                </div>
              )}
              <Form.Item
                name="internalComment"
                label="Internal comment (will be visible only for Admins)"
                className={styles.labelFormFont}
                style={{ marginBottom: 20 }}
              >
                <Input.TextArea className={styles.textArea} placeholder="Internal Comment" />
              </Form.Item>
            </>
          )}
          <div className={styles.actionBtnsWrapper}>
            <Button
              htmlType="reset"
              className={styles.saveDraftBtn}
              style={isCompenstation && { width: 208 }}
              ghost
              onClick={() => {
                setIsFileAttached("initiate");
                setIsOpenModalAgreement(true);
              }}
            >
              Save Draft & Close
            </Button>
            {isCompenstation && (
              <Button
                htmlType="submit"
                className={styles.compliteDuspBtn}
                onClick={() => {
                  if (isFileAttached === "no" || isFileAttached === "initiate") setIsFileAttached("no");
                }}
              >
                Complete Dispute
              </Button>
            )}
          </div>
        </Form>
      </Modal>
      <ModalComponent
        buttons={{
          confirm: {
            label: isCancelDeal ? "Cancel Deal" : "Confirm",
            onConfirm: () => {
              setIsOpenModalAgreement(false);
              setIsCancelDeal(false);
            },
            isDanger: isCancelDeal,
          },
          decline: {
            label: "Cancel",
            onDecline: () => {
              setIsCancelDeal(false);
              setIsOpenModalAgreement(false);
            },
          },
        }}
        title={isCancelDeal ? "Cancel Deal" : "Change Transfer Date"}
        isOpen={isOpenModalAgreement}
        onClose={() => {
          setIsOpenModalAgreement(false);
          setIsCancelDeal(false);
        }}
        content={
          <div>
            <div style={{ marginBottom: 24 }}>
              Please make sure that the product has been transferred on the modified date
            </div>
            <div>
              12.12.2024 → <span style={{ color: "#F40909" }}>12.01.2025</span>
            </div>
          </div>
        }
      />
    </>
  );
};
