import { CopyButton } from "Components/CopyButton";
import styles from "./index.module.css";
import { CSSProperties } from "react";

type Props = {
  label: string;
  contentFirst: {
    label?: string;
    content: string;
    isContentID?: boolean;
    sliceLength?: number;
  }[];
  style?: CSSProperties;
};

export const AllInfoComponent = ({ contentFirst, label, style }: Props) => {
  return (
    <div className={styles.info} style={style}>
      <div style={{ color: "#000000", fontWeight: 700 }}>{label}</div>
      {contentFirst.map((v) => {
        const isOne = !!v.label;

        if (!isOne) {
          return <div key={v.content}>{v.content}</div>;
        } else {
          return (
            <div key={v.label} style={{ display: "flex" }}>
              <span style={{ marginRight: 10, color: "#868686" }}>{v.label}</span>
              {v.isContentID ? (
                <CopyButton
                  copyText="copied"
                  text={v.content}
                  wait={1000}
                  className={styles.copyBtn}
                  sliceOfText={v.sliceLength}
                />
              ) : (
                <div style={{ color: "#000000" }}>{v.content}</div>
              )}
            </div>
          );
        }
      })}
    </div>
  );
};
