import { Button, Cascader, Form } from "antd";
import carretRight from "assets/svg/CaretRight.svg";
import magnify from "assets/svg/magnifying-glass.svg";

import styles from "./index.module.css";
import { CSSProperties, useCallback, useContext } from "react";
import { highlightText } from "helpers/helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import { useRequest } from "ahooks";
import { getCategoriesBrunchById } from "helpers/backend_helper";

const SIZE_OF_CAT = 43.5;

type Props = {
  categoriesTree: any[];
  isCategoriesLoading: boolean;
  button: {
    label: string;
    className?: string;
    style?: CSSProperties;
  };
  parentId: string;
};
export const CategoryCascader = ({ categoriesTree, isCategoriesLoading, button, parentId }: Props) => {
  const [form] = Form.useForm<{ category: any }>();
  const onFinish = useCallback((value: { category: any }) => {
    console.log(value.category);
  }, []);

  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const { loading, data } = useRequest(() => getCategoriesBrunchById(parentId, localCountry?.iso), {
    refreshDeps: [localCountry],
    ready: !!localCountry,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  console.log(data, loading);
  return (
    <Form
      layout="vertical"
      form={form}
      className={styles.cascaderWrapper}
      onFinish={onFinish}
      //   initialValues={{ category: category.defaultValues }}
    >
      <Form.Item
        name="category"
        rules={[
          {
            required: true,
            message: "Required field",
          },
        ]}
      >
        <Cascader
          loading={isCategoriesLoading || !categoriesTree?.length}
          size="large"
          className={styles.cascader}
          options={categoriesTree}
          suffixIcon={<img src={magnify} alt="magnify" />}
          showSearch={{
            render: (v, path) => {
              return (
                <div style={{ display: "flex" }}>
                  {path.map((opt, i) => (
                    <div key={opt.value + opt.label} className={styles.searchOpt}>
                      <span>{highlightText(opt.label, v, styles.searchWord)}</span>
                      {i !== path.length - 1 && (
                        <img src={carretRight} alt="rightIcon" className={styles.arrowSpacingSearch} />
                      )}
                    </div>
                  ))}
                </div>
              );
            },
          }}
          placeholder="Choose category"
          expandIcon={<img src={carretRight} alt="rightIcon" className={styles.arrowSpacing} />}
          getPopupContainer={(props) => {
            if (!props?.querySelectorAll) return props;
            const menus = props.querySelectorAll(".ant-cascader-menu");
            if (!menus.length) return props;
            if (menus.length < 2) {
              menus[0].style.height = `175px`;
              return props;
            }

            const lastList = menus[menus.length - 1];
            const sizeOfList = lastList.querySelectorAll("li").length;
            const heightForOthers = sizeOfList * SIZE_OF_CAT;

            if (heightForOthers < 175) {
              lastList.style.height = `175px`;
              menus.forEach((v) => {
                v.style.height = `175px`;
              });
            } else if (heightForOthers > 500) {
              lastList.style.height = `500px`;
              menus.forEach((v) => {
                v.style.height = `500px`;
              });
            } else {
              lastList.style.height = `${heightForOthers}px`;
              menus.forEach((v) => {
                v.style.height = `${heightForOthers}px`;
              });
            }
            return props;
          }}
          popupClassName={styles.popup}
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        className={button.className ? styles.buttonConfirm + " " + button.className : styles.buttonConfirm}
        style={button?.style}
      >
        {button.label}
      </Button>
    </Form>
  );
};
