import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import styles from "./index.module.css";
import { CSSProperties } from "react";
import checked from "assets/svg/Checked.svg";

export type PropsSelectData = {
  placeHolder?: string;
  onChange: (value: string[]) => void;
  options: DefaultOptionType[];
  style?: CSSProperties;
  value: string[];
  isLoadingSelect: boolean;
};

export const SelectData = ({ onChange, options, placeHolder, style, value, isLoadingSelect }: PropsSelectData) => {
  return (
    <Select
      maxTagCount={1}
      maxLength={2}
      maxTagPlaceholder={`${value.length - 1}+`}
      loading={isLoadingSelect}
      mode="multiple"
      style={style}
      value={value}
      className={styles.selectData}
      options={options}
      popupClassName={styles.dropDown}
      size="small"
      allowClear
      onChange={onChange}
      placeholder={placeHolder}
      suffixIcon={<div className={styles.showIcon} />}
      menuItemSelectedIcon={<img src={checked} alt="checked" />}
    />
  );
};
