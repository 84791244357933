import { Badge, Button, Dropdown, Spin } from "antd";
import styles from "./index.module.css";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { AdminContext } from "context/adminContext";
import { ArrowRightIcon } from "Common/Icons/Arrow";
import { InfoComponent } from "Components/InfoComponent";
import { ProductComponent } from "./ProductComponent";
import { CopyButton } from "Components/CopyButton";
import carretDown from "assets/svg/CaretDown.svg";
import ItemReviews from "./Reviews";
import { TabsComponent } from "Components/TabsComponent";
import { useRequest } from "ahooks";
import { getProduct, updateProductStatus } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import dayjs from "dayjs";
import { TableDeals } from "./TableDeals";
import { ModalComponent } from "Components/ModalComponent";
import { CascaderModal } from "./CascaderModal";
import { useNavigate, useParams } from "react-router";
import { PROP_NAME_MAIN_TAB, PROP_NAME_NEXT_TAB, PROP_NAME_SKIP_ADS } from "helpers/constants";
import { useSearchParams } from "react-router-dom";
import { TablePayments } from "./TablePayments";

const getItems = (itemId: string) => {
  return [
    {
      key: "0",
      label: (
        <Badge count={0} size="small" className={styles.badge}>
          <div className={styles.deals}>Deals</div>
        </Badge>
      ),
      children: <TableDeals itemId={itemId} />,
    },
    {
      key: "1",
      label: (
        <Badge count={0} size="small" className={styles.badge}>
          <div className={styles.deals}>Payments</div>
        </Badge>
      ),
      children: <TablePayments productId={itemId} />,
    },
  ];
};

export const ItemComponent = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [dateIsOpen, setDateIsOpen] = useState(false);
  const [reviewCount, setReviewCount] = useState(0);
  const [currentStatus, setCurrentStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditGroupOpen, setIsEditGroupOpen] = useState(false);
  const inputComment = useRef("");
  const [searchParams, setSearchParams] = useSearchParams();
  const items = useMemo(() => getItems(itemId), [itemId]);

  const { data, loading } = useRequest(() => getProduct(itemId, localCountry.iso.toUpperCase()), {
    refreshDeps: [localCountry.iso],
    ready: !!localCountry.iso,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || error.message);
    },
    onSuccess: (data) => {
      setReviewCount(data?.reviewCount);
      setCurrentStatus(data?.status);
    },
  });

  const { run: updateProduct } = useRequest(
    (status: string, comment?: string) =>
      updateProductStatus(itemId, { status, comment }, localCountry.iso.toUpperCase()),
    {
      manual: true,
      ready: !!localCountry?.iso,
      refreshDeps: [localCountry],
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message || error.message);
      },
      onSuccess: (data) => {
        openNotificationWithIcon("success", "Status changed");
        setCurrentStatus(data?.status);
      },
    },
  );

  const closeModalCorrection = useCallback(() => setIsModalOpen(false), []);
  const closeModalCategory = useCallback(() => setIsEditGroupOpen(false), []);
  const openCategoryModal = useCallback(() => setIsEditGroupOpen(true), []);
  return (
    <>
      <div className={styles.container}>
        {loading ? (
          <Spin style={{ left: "50%", top: "50%", transform: "translate(50% -50%)" }} />
        ) : (
          <>
            <Dropdown
              menu={{
                items: ["Pending", "Published", "Corrections", "Archived", "STOP", "Draft"].map((v, i) => ({
                  key: i,
                  label: v,
                  onClick: () => {
                    if (v === "Corrections") {
                      updateProduct(v, "Simple man");
                    } else {
                      updateProduct(v);
                    }
                  },
                })),
              }}
            >
              <Button>Change Status</Button>
            </Dropdown>
            <div className={styles.userChoosenTop}>
              <Button
                type="text"
                style={{ padding: 0 }}
                onClick={() => {
                  navigate("/users", { replace: true });
                }}
              >
                Users
              </Button>
              <ArrowRightIcon size={17} />
              <Button
                type="text"
                style={{ padding: 0 }}
                onClick={() => {
                  navigate(
                    {
                      search: `?countryUserId=${data?.user.countryUserId}&mainId=${data?.user.mainUserId}`,
                      pathname: "/users/user",
                    },
                    { replace: true },
                  );
                }}
              >
                {`${data?.user?.firstName}`}
              </Button>
              <ArrowRightIcon size={17} />
              <div style={{ fontWeight: 700 }}>Product</div>
            </div>
            <div className={styles.mainInfo}>
              <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
                <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "24px" }}>
                  {data?.name ? data?.name : "-"}
                  <span style={{ fontWeight: 700, color: "#868686", fontSize: 24, marginLeft: 10 }}>
                    {data?.address}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: 16, fontWeight: 700, marginRight: 10 }}>ID {itemId}</div>
                </div>
              </div>
              <div className={styles.infContainer}>
                <div className={styles.changeStatus}>
                  <span>Status</span>
                  <span>{currentStatus}</span>
                  {currentStatus === "Published" ? (
                    <Button
                      type="primary"
                      className={styles.stopButton}
                      ghost
                      onClick={() => {
                        updateProduct("STOP");
                      }}
                    >
                      Stop
                    </Button>
                  ) : currentStatus === "STOP" || currentStatus === "Pending" ? (
                    <>
                      <Button
                        type="primary"
                        className={styles.confirmBtn}
                        onClick={() => {
                          updateProduct("Published");
                        }}
                      >
                        Confirm
                      </Button>
                      <Button
                        type="primary"
                        ghost
                        danger
                        className={styles.declineBtn}
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                      >
                        Decline
                      </Button>
                    </>
                  ) : null}
                </div>
                <InfoComponent
                  infoOfData="Rate of insurance"
                  label="Quality Rate"
                  value={data?.qualityRate}
                  className={styles.componentQuality}
                />
                <InfoComponent
                  infoOfData="Rate of insurance"
                  label="Usage Rate"
                  value={data?.usagePercentage + "%"}
                  className={styles.componentQuality}
                />
              </div>
            </div>
            <div className={styles.infoItemWrapper}>
              <ProductComponent
                description={data?.description}
                photos={data?.photos}
                productOption={data?.productOption}
              />
              <div className={styles.infoItem}>
                <div className={styles.infoModern}>
                  <div>
                    <div style={{ display: "flex", gap: 10, alignItems: "center", color: "#868686" }}>
                      <div style={{ fontWeight: 500 }} className={styles.lessorId}>
                        Lessor
                      </div>
                      <CopyButton
                        showText={`${data?.user.firstName}`}
                        copyText="copied"
                        text={data?.user.id}
                        wait={1000}
                        className={styles.copyBtn}
                      />
                    </div>
                  </div>
                  <div className={styles.piecesInfo}>
                    <div className={styles.piecesInfo}>
                      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div className={styles.pieceInfo}>
                          <span>Moderation Date</span>
                          <span>{dayjs(new Date(data?.moderatedAt)).format("DD.MM.YYYY")}</span>
                        </div>
                        <div className={styles.pieceInfo}>
                          <span>Product Cost</span>
                          <span>€{data?.price}</span>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                      <div className={styles.pieceInfo}>
                        <span>Product Group</span>
                        <span style={{ maxWidth: "180px" }}>{data?.productGroup}</span>
                        <Button
                          type="primary"
                          ghost
                          className={styles.editButton}
                          onClick={openCategoryModal}
                          style={{ color: "black !important" }}
                        >
                          Edit
                        </Button>
                      </div>
                      <div className={styles.pieceInfo}>
                        <span>Category</span>
                        <span style={{ maxWidth: "300px" }}>{data?.category}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.pricesAndDate}>
                  <div className={styles.pricesWrapper}>
                    <div style={{ fontSize: 21, fontWeight: 800, marginBottom: 16 }}>Prices</div>
                    <div className={styles.priceWrapper}>
                      <div className={styles.price}>
                        <span>€{data?.rentalDay}</span>
                        <span>/day</span> <span>1–7 days</span>
                      </div>
                      <div className={styles.price}>
                        <span>€{data?.rentalWeek}</span>
                        <span>/day</span> <span>8–30 days</span>
                      </div>
                      <div className={styles.price}>
                        <span>€{data?.rentalMonth}</span>
                        <span>/day</span> <span>30+ days</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.dateWrapper}>
                    <div
                      style={{
                        display: "flex",
                        gap: 10,
                        justifyContent: "space-between",
                        width: "100%",
                        lineHeight: "24px",
                        marginBottom: 10,
                      }}
                    >
                      <div style={{ fontWeight: 800, fontSize: 21 }}>Not working Dates & Days</div>
                      <Button
                        type="text"
                        className={styles.expandBtn}
                        icon={
                          <img
                            src={carretDown}
                            alt="carretDown"
                            style={dateIsOpen ? { transform: "rotate(180deg)" } : null}
                          />
                        }
                        onClick={() => {
                          setDateIsOpen((prev) => !prev);
                        }}
                      >
                        {dateIsOpen ? "Collapse" : "Expand"}
                      </Button>
                    </div>
                    {dateIsOpen && (
                      <div className={styles.dateContentWrapper}>
                        <div className={styles.notWorkingDaysWrapper}>
                          <div>Not working days</div>
                          <div>
                            {data?.unavailableWeekdays?.length ? data?.unavailableWeekdays.join("s, ") + "s" : "-"}
                          </div>
                        </div>
                        <div className={styles.daysAndRangeWrapper}>
                          <div className={styles.days}>
                            <div>Days</div>
                            <div>
                              {data?.unavailableDates.map((s, i) => (
                                <span
                                  key={i}
                                  style={{ fontSize: 18, fontWeight: 500, color: "black", marginRight: 10 }}
                                >
                                  {new Date(s).toLocaleDateString("EN", { month: "short", day: "2-digit" })}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className={styles.range}>
                            <div>Date Ranges</div>
                            <div>
                              {data?.dateRangeUnavailableDates.map((s, i) => (
                                <div
                                  key={i}
                                  style={{
                                    fontSize: 18,
                                    fontWeight: 500,
                                    color: "black",
                                    marginTop: 10,
                                    lineHeight: "18px",
                                  }}
                                >
                                  {`${new Date(s.from).toLocaleDateString("EN", {
                                    month: "short",
                                    day: "2-digit",
                                  })} - ${new Date(s.to).toLocaleDateString("EN", { month: "short", day: "2-digit" })}`}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.rating}>
                  <ItemReviews
                    itemId={itemId}
                    reviewCount={reviewCount}
                    onReviewChangeCount={(n) => setReviewCount(n)}
                    rating={data?.rating}
                  />
                </div>
              </div>
            </div>
            <TabsComponent
              defaultActiveKey={searchParams.get(PROP_NAME_MAIN_TAB) || "0"}
              items={items}
              className={styles.tabs}
              destroyInactiveTabPane
              onChange={(activeKey) => {
                searchParams.set(PROP_NAME_MAIN_TAB, items.find((v) => v.key === activeKey).key);
                searchParams.delete(PROP_NAME_SKIP_ADS);
                searchParams.delete(PROP_NAME_NEXT_TAB);
                setSearchParams(searchParams, { replace: true });
              }}
            />
          </>
        )}
      </div>
      <CascaderModal isOpen={isEditGroupOpen} onClose={closeModalCategory} groupId={data?.productGroupId} />
      <ModalComponent
        isOpen={isModalOpen}
        onClose={closeModalCorrection}
        title="Decline Ad"
        buttons={{
          confirm: {
            label: "Send",
            onConfirm: () => {
              updateProduct("Corrections", inputComment.current);
              inputComment.current = "";
            },
          },
          decline: {
            label: "Cancel",
            onDecline: closeModalCorrection,
          },
        }}
        inputField={{
          onChange: (text) => {
            const formatedText = text?.replaceAll("\n", "\\n");
            inputComment.current = formatedText;
          },
        }}
        content="Please describe why the Ad cannot pass moderation"
      />
    </>
  );
};
