import { API_ORIGIN } from "pages/Main/Components/AdminPanel/Debate/DetailsModal/utils";
import { UserOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { Avatar, Rate } from "antd";

type Props = {
  el: {
    avatar: string;
    rating: number;
    firstName: string;
    lastName: string;
    isOnlyName?: boolean;
  };
};

export const UserNameRatingPhotoComponent = ({ el }: Props) => {
  return (
    <div className={styles.container}>
      <div>
        {el?.avatar ? (
          <Avatar
            style={{ width: 46, height: 46 }}
            icon={<img src={API_ORIGIN + "/public/avatars/" + el.avatar} alt="userPhoto" />}
          />
        ) : (
          <Avatar style={{ width: 46, height: 46 }} icon={<UserOutlined size={46} />} />
        )}
      </div>
      <div className={styles.nameRate}>
        <div>{el?.rating ? <Rate disabled defaultValue={el.rating} className={styles.rate} /> : "—"}</div>
        {el.isOnlyName ? (
          <div>{`${el.firstName}`.slice(0, 15)}</div>
        ) : (
          <div>{`${el.firstName} ${el.lastName}`.slice(0, 15)}</div>
        )}
      </div>
    </div>
  );
};
