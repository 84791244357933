import { Divider } from "antd";
import styles from "./index.module.css";

type Props = {
  features: { name: string; value: string }[];
};
export const FeaturesComponent = ({ features }: Props) => {
  return (
    <div className={styles.container}>
      <div style={{ fontWeight: 700, fontSize: 18, marginBottom: 16 }}>Features</div>
      <div className={styles.featuresContent}>
        {features?.map((features, i, array) => (
          <div key={features.name}>
            <div className={styles.feature}>
              <div>{features.name}</div>
              <div>{features.value}</div>
            </div>
            {array.length - 1 !== i && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
          </div>
        ))}
      </div>
    </div>
  );
};
