import React, { useState, useRef, useEffect } from "react";
import { Button } from "antd";
import styles from "./index.module.css";

type Props = {
  onChoose: (v: number) => void;
  value: number;
};

export const SwitcherDispute = ({ onChoose, value }: Props) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [indicatorStyle, setIndicatorStyle] = useState<{ width: string; left: string }>({
    width: "",
    left: "",
  });
  const buttonsRef = useRef<HTMLElement[]>([]);
  const options = ["Disable Return", "Enable Return", "Return impossible"];

  useEffect(() => {
    if (buttonsRef.current[selectedOption]) {
      const button = buttonsRef.current[selectedOption];
      buttonsRef.current.forEach((b) => {
        b.style.fontWeight = "400";
      });
      setTimeout(() => {
        const { width, left } = button.getBoundingClientRect();
        const container = button.parentElement;
        const containerLeft = container.getBoundingClientRect().left;

        setIndicatorStyle({
          width: `${width}px`,
          left: `${left - containerLeft - 2.2}px`,
        });

        buttonsRef.current[selectedOption].style.fontWeight = "500";
        onChoose(selectedOption);
      });
    }
  }, [selectedOption]);

  return (
    <div className={styles.switcherContainer}>
      <div className={styles.buttonsWrapper}>
        {options.map((option, index) => (
          <Button
            key={index}
            type="text"
            ref={(el) => (buttonsRef.current[index] = el)}
            className={` ${index === selectedOption ? styles.selected : ""}`}
            onClick={() => setSelectedOption(index)}
          >
            {option}
          </Button>
        ))}
        <div className={styles.indicator} style={indicatorStyle} />
      </div>
    </div>
  );
};
