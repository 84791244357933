import { API_ORIGIN } from "pages/Main/Components/AdminPanel/Debate/DetailsModal/utils";
import styles from "./index.module.css";
import { UserOutlined } from "@ant-design/icons";
import notVerifiedIcon from "assets/svg/notVerifiedIcon.svg";
import verifiedIcon from "assets/svg/verifiedIcon.svg";

import { Avatar, Button, Divider, Rate } from "antd";
import { UserDetails } from "helpers/backend_helper";
import { SocketContext } from "context/socketContext";
import { useContext, useMemo } from "react";

type Props = {
  userInfo: UserDetails;
  toFrozeUser: (isFrozen: boolean) => void;
  countryUserId: string;
};

function dateDifferenceInDays(date: Date): string {
  const onlineDate = new Date(date);
  const nowDate = new Date();
  onlineDate.setHours(0, 0, 0, 0);
  nowDate.setHours(0, 0, 0, 0);
  const timeDifference = onlineDate.getTime() - nowDate.getTime();
  const differenceInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  let res = "";
  const time = date.toLocaleDateString("ru", { hour: "2-digit", minute: "2-digit" }).split(",")[1].trim();

  if (differenceInDays === 0) {
    res = `today at ${time}`;
  } else if (differenceInDays === 1) {
    res = `yesterday at ${time}`;
  } else {
    res = `${differenceInDays} ago at ${time}`;
  }
  return res;
}

export const MainInfo = ({ userInfo, toFrozeUser, countryUserId }: Props) => {
  const differenceInDays = useMemo(
    () => dateDifferenceInDays(new Date(userInfo.lastOnlineDate)),
    [userInfo.lastOnlineDate],
  );
  const { online } = useContext(SocketContext);

  return (
    <div className={styles.container}>
      <div className={styles.mainInfoWithIcon}>
        {userInfo.avatar ? (
          <Avatar
            icon={<img src={API_ORIGIN + "/public/avatars/" + userInfo.avatar} alt="" />}
            size={96}
            className={styles.avatar}
          />
        ) : (
          <Avatar icon={<UserOutlined />} size={96} />
        )}
        <div className={styles.mainInfoWrapperText}>
          <div className={styles.ratingInfo}>
            {userInfo.rating ? (
              <>
                <div style={{ fontSize: 20, fontWeight: 700, color: "#868686" }}>{userInfo.rating.toFixed(1)}</div>
                <Rate defaultValue={userInfo.rating} disabled className={styles.ratingOfUser} />
              </>
            ) : (
              "-"
            )}
          </div>
          <div style={{ fontWeight: 400, color: "#868686", fontSize: 14 }}>
            {online.clientId === countryUserId && online.online ? (
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                online <span className={styles.onlineDot}></span>
              </div>
            ) : (
              `online ${
                online.clientId === countryUserId && !online.online
                  ? dateDifferenceInDays(new Date())
                  : differenceInDays
              }`
            )}
          </div>
          <div style={{ fontSize: 14, color: "#3E3E3E", fontWeight: 700 }}>{userInfo.email}</div>
          <div style={{ fontSize: 14, color: "#3E3E3E", fontWeight: 700 }}>+{userInfo.phone}</div>
        </div>
      </div>
      <Button
        type="primary"
        ghost
        danger
        className={styles.buttonBlock}
        onClick={() => {
          toFrozeUser(!userInfo.frozen);
        }}
      >
        {userInfo.frozen ? "Unblock User" : "Block User"}
      </Button>
      <div style={{ width: "fit-content", margin: "0 auto" }}>
        <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!userInfo.verified ? (
              <>
                <img src={notVerifiedIcon} alt="verificationIcon" />
                <span style={{ fontSize: 16, fontWeight: 700 }}>Not verified user</span>
              </>
            ) : (
              <>
                <img src={verifiedIcon} alt="verificationIcon" />
                <span style={{ fontSize: 16, fontWeight: 700 }}>Verified user</span>
              </>
            )}
          </div>
          <span style={{ color: "#868686", fontSize: "16px" }}>
            on C2C from {new Date(userInfo.createdAt).toLocaleDateString("EN", { month: "short", year: "numeric" })}
          </span>
        </div>
      </div>
      <Divider style={{ borderWidth: 3 }} />
      <p className={styles.description}>{userInfo.description}</p>
    </div>
  );
};
