import { Button } from "antd";
import { TableWithPagination } from "Components/TableWithPagination";
import sortIcon from "assets/svg/SortAscending.svg";
import styles from "./index.module.css";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Pagination } from "pages/Main/Components/AdminPanel/Options";
import {
  ENTITY_PER_PAGE,
  PROP_NAME_CITY,
  PROP_NAME_INPUT_TEXT,
  PROP_NAME_PRODUCT_CATEGORY,
  PROP_NAME_SKIP_ADS,
  PROP_NAME_SORTBY,
} from "helpers/constants";
import { UserNameRatingPhotoComponent } from "Components/UserNameRatingPhotoComponent";
import { CopyButton } from "Components/CopyButton";
import dayjs from "dayjs";
import { PhotoNameTab } from "Components/PhotoNameTab";
import { formatDateRange } from "../../Users/UserComponent/TableDeals/RequestDeals/RequestDeals";
import { DealsInfo } from "Components/DealsInfo";
import { AdminRentsStatus, getAdminRents, SortByAdminRents } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { useSearchParams } from "react-router-dom";
import { onSort } from "helpers/helper";

type Props = {
  status: AdminRentsStatus;
  dateRange?: { to: string; from: string };
};

export const RequestDeals = ({ status, dateRange }: Props) => {
  const [skipAds, setSkipAds] = useState(0);
  const [sortBy, setSortBy] = useState<SortByAdminRents>("");
  const [isOpen, setIsOpen] = useState(false);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [selectedId, setSelectedId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { localCountry } = useContext(AdminContext);

  const skipAdsFilter = searchParams.get(PROP_NAME_SKIP_ADS);
  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT); //debounced;
  const citiesFilter = searchParams.get(PROP_NAME_CITY);
  const categoriesFilter = searchParams.get(PROP_NAME_PRODUCT_CATEGORY);
  const sortByFilter = searchParams.get(PROP_NAME_SORTBY);

  const [pagination, setPagination] = useState<Pagination>({ skip: 0, take: ENTITY_PER_PAGE, total: 0 });

  useEffect(() => {
    if (sortByFilter) {
      setSortBy(sortByFilter as SortByAdminRents);
    } else {
      setSortBy("");
    }
  }, [sortByFilter]);

  const onSortBy = useCallback(
    (sortName) => {
      onSort(sortName, searchParams, sortBy, setSearchParams);
    },
    [searchParams, setSearchParams, sortBy],
  );
  useEffect(() => {
    if (skipAdsFilter) {
      setSkipAds(+skipAdsFilter);
    } else {
      setSkipAds(0);
    }
  }, [skipAdsFilter]);

  const { data, loading } = useRequest(
    () =>
      getAdminRents(
        {
          status,
          pagination: {
            skip: skipAds,
            take: ENTITY_PER_PAGE,
            total: pagination.total,
          },
          sort: { sortBy, sortOrder: sortBy === "dateRequest" ? "asc" : "desc" },
          cityIds: citiesFilter?.length ? citiesFilter.split(",") : [],
          productCategories: categoriesFilter?.length ? categoriesFilter.split(",") : [],
          searchText,
          dateRange,
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      ready: !!localCountry.iso,
      refreshDeps: [sortBy, skipAds, searchParams, status, dateRange],
      onError: (err: any) => {
        openNotificationWithIcon("error", err.response?.data?.message || err.message);
      },
      onSuccess: (data) => {
        setPagination((prev) => ({ ...prev, total: +data.totalCount }));
      },
    },
  );
  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
      },
      {
        title: "Renter",
        dataIndex: "renter",
        key: "renter",
      },
      {
        title: "Lessor",
        dataIndex: "lessor",
        key: "lessor",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Period</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="usagePercentage"
                  style={{ width: 16, height: 16, transform: sortBy === "period" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("period");
              }}
            />
          </div>
        ),
        dataIndex: "period",
        key: "period",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Price</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="usagePercentage"
                  style={{ width: 16, height: 16, transform: sortBy === "amount" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("amount");
              }}
            />
          </div>
        ),
        dataIndex: "price",
        key: "price",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Data Request</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="usagePercentage"
                  style={{ width: 16, height: 16, transform: sortBy === "dateRequest" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("dateRequest");
              }}
            />
          </div>
        ),
        dataIndex: "data_request",
        key: "data_request",
      },
      {
        title: " ",
        dataIndex: "actions",
        key: "actions",
      },
    ],
    [sortBy],
  );

  const source = useMemo(
    () =>
      data?.rents?.map((el) => ({
        key: el.rental_id,
        id: <CopyButton copyText="copied" sliceOfText={6} text={el.rental_id} wait={1000} key={el.rental_id} />,
        period: (
          <div style={{ width: 170 }}>
            <div>{formatDateRange(new Date(el.dateStart), new Date(el.dateEnd)) + ","}</div>
            <div>{el.period_days + " days"} </div>
          </div>
        ),
        data_request: dayjs(new Date(el.dateStart)).format("DD.MM.YYYY"),
        renter: (
          <UserNameRatingPhotoComponent
            el={{
              avatar: el.lessee.avatar,
              firstName: el.lessee.firstName,
              lastName: el.lessee.lastName,
              rating: el.lessee.rating,
              isOnlyName: true,
            }}
          />
        ),
        lessor: (
          <UserNameRatingPhotoComponent
            el={{
              avatar: el.lessor.avatar,
              firstName: el.lessor.firstName,
              lastName: el.lessor.lastName,
              rating: el.lessor.rating,
              isOnlyName: true,
            }}
          />
        ),
        product: <PhotoNameTab photoFullPath={el?.productPhoto?.preview} title={el.productName} />,
        price: "€" + el.rentPrice,
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              type="primary"
              onClick={() => {
                setIsOpen(true);
                setSelectedId(el.rental_id);
              }}
            >
              Details
            </Button>
          </div>
        ),
      })),
    [data?.rents],
  );
  return (
    <div className={styles.container}>
      <TableWithPagination
        paginationTable={{
          pagination,
          skipAds: skipAds,
        }}
        propsTable={{
          columns,
          dataSource: source,
          headerShow: true,
          isLoading: loading,
        }}
      />
      <DealsInfo
        close={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        id={selectedId}
      />
    </div>
  );
};
