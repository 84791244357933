import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SlidersOutlined,
  UnorderedListOutlined,
  UserOutlined,
  GlobalOutlined,
  BarcodeOutlined,
  PieChartOutlined,
  ProductOutlined,
  NotificationOutlined,
  PartitionOutlined,
  MessageOutlined,
  HeatMapOutlined,
  BookOutlined,
  // SafetyOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme, Select } from "antd";
import Login from "./Components/Login";
import { AdminContext } from "context/adminContext";
import Logout from "./Components/Logout";
import { NotificationProvider } from "context/notificationContext";
import { getContract } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import styles from "./index.module.css";
import { Outlet, useNavigate, useParams } from "react-router";
import { SocketContext } from "context/socketContext";

const { Header, Sider, Content } = Layout;

export const dataKeys = [
  "wallet",
  "adminList",
  "products",
  "chats",
  "languages",
  "countries",
  "cities",
  "categories",
  "groups",
  "options",
  "debate",
  "users",
  "deals",
  "disputes",
  "insurance",
];

const getItems = (isSupportRole: boolean) => {
  const items = [
    {
      key: "0",
      icon: <SlidersOutlined />,
      label: "Contract",
    },
    {
      key: "1",
      icon: <UserOutlined />,
      label: "Admins list",
    },
    {
      key: "2",
      icon: <ProductOutlined />,
      label: "Ads",
    },
    {
      key: "3",
      icon: <MessageOutlined />,
      label: "Chats",
    },
    {
      key: "4",
      icon: <NotificationOutlined />,
      label: "Languages",
    },
    {
      key: "5",
      icon: <GlobalOutlined />,
      label: "Countries",
    },
    {
      key: "6",
      icon: <PartitionOutlined />,
      label: "Cities",
    },
    {
      key: "7",
      icon: <UnorderedListOutlined />,
      label: "Categories",
    },
    {
      key: "8",
      icon: <BarcodeOutlined />,
      label: "Groups",
    },
    {
      key: "9",
      icon: <PieChartOutlined />,
      label: "Options",
    },
    {
      key: "10",
      icon: <HeatMapOutlined />,
      label: "Debate",
    },
    { key: "11", icon: <UserOutlined />, label: "Users" },
    { key: "12", icon: <BookOutlined />, label: "Deals" },
    // { key: "13", icon: <HeatMapOutlined />, label: "New Debate" },
    // { key: "14", icon: <SafetyOutlined />, label: "Insurance" },
  ];
  if (!isSupportRole) {
    items.splice(3, 1);
  }

  return items;
};

const MainPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { currentChatJoinedID, leaveChatAdmin } = useContext(SocketContext);
  const navigate = useNavigate();
  const params = useParams();
  const { isAuthorized, setLocalCountry, localCountry, countries, accountData } = useContext(AdminContext);

  const [menuKey, setMenuKey] = useState("0");

  const isSupportRole = !!accountData?.userRoles.find((el) => {
    if (el.role === "SUPERSUPPORT" && el.approved) {
      return true;
    }
    if (el.role === "SUPPORT" && el.approved) {
      return true;
    }
    return false;
  });
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { data: contract } = useRequest(getContract);
  const items = useMemo(() => getItems(isSupportRole), [isSupportRole]);

  useEffect(() => {
    const currentPageKey = params["*"].split("/")[0];
    const indexOfCurrPage = dataKeys.findIndex((v) => v === currentPageKey);

    if (indexOfCurrPage !== -1) {
      setMenuKey(indexOfCurrPage.toString());
    }
  }, [params["*"]]);

  const menuHandler = (e) => {
    if (currentChatJoinedID.current) leaveChatAdmin(currentChatJoinedID.current);
    setMenuKey(e.key);
    navigate(`/${dataKeys[e.key]}`);
  };

  const countriesList = countries?.map((el) => ({ ...el, label: el.name, value: el.iso }));

  useEffect(() => {
    localStorage.setItem("menuKey", menuKey);
  }, [menuKey]);

  return (
    <NotificationProvider>
      <Layout className={styles.layout}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" />
          <Menu
            theme="dark"
            mode="inline"
            onClick={menuHandler}
            selectedKeys={[menuKey]} // Устанавливаем выбранный пункт меню
            items={items}
          />
        </Sider>
        <Layout className={styles.layoutWrapper}>
          <Header className={styles.header}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            {contract ? (
              <div className={styles.walletWrapper}>
                <div className={styles.wrapper}> Network: {contract.network}</div>
                <div className={styles.wrapper}> Balance: {contract.balance}</div>
              </div>
            ) : null}
            <div className={styles.wrapper}>
              Country:{" "}
              {countriesList && localCountry ? (
                <Select
                  loading={!countriesList}
                  style={{ width: "150px" }}
                  defaultValue={localCountry?.iso || countriesList[0]?.value}
                  placeholder="type"
                  options={countriesList}
                  onChange={(iso) => {
                    setLocalCountry(countries.find((country) => country.iso === iso));
                  }}
                />
              ) : null}
            </div>
            <div className={styles.adminData}>{accountData?.email}</div>
            <div className={styles.headerAside}>{!isAuthorized ? <Login /> : <Logout />}</div>
          </Header>
          <Content
            className={styles.content}
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </NotificationProvider>
  );
};

export default MainPage;
