import { Spin, Tooltip } from "antd";
import styles from "./index.module.css";
import { ReactNode } from "react";

type Props = {
  infoOfData: string;
  value: string | ReactNode;
  label: string;
  isLoading?: boolean;
  isWarning?: boolean;
  className?: string;
  moreInfo?: {
    value: string;
    isPositive: boolean;
  };
  moreValue?: {
    value: string;
    moreInfo?: {
      value: string;
      isPositive: boolean;
    };
  };
};

export const InfoComponent = ({
  infoOfData,
  label,
  value,
  isLoading,
  isWarning,
  moreInfo,
  className,
  moreValue,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Spin style={{ top: "50%", left: "50%" }} />
      ) : (
        <div className={styles.wrapperOfContent}>
          <div className={styles.labelWrapper + " " + className}>
            <span>{label}</span>
            <Tooltip title={infoOfData}>
              <span className={styles.infoIcon} />
            </Tooltip>
          </div>
          <div style={{ display: "flex", gap: 20 }}>
            <div className={styles.content} style={isWarning ? { color: "#F40909" } : {}}>
              {value}
              {moreInfo && (
                <span
                  className={styles.moreInfo}
                  style={!moreInfo.isPositive ? { color: "#F40909" } : { color: "#47B022" }}
                >
                  {moreInfo.value}
                </span>
              )}
            </div>
            {moreValue && (
              <div className={styles.content} style={isWarning ? { color: "#F40909" } : {}}>
                {moreValue.value}
                {moreValue.moreInfo && (
                  <span
                    className={styles.moreInfo}
                    style={!moreValue.moreInfo.isPositive ? { color: "#F40909" } : { color: "#47B022" }}
                  >
                    {moreValue.moreInfo.value}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
