import { Modal } from "antd";
import styles from "./index.module.css";
import { getCategories } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import { getTreeItems } from "helpers/helper";
import { CategoryCascader } from "Components/CategoryComponent";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  groupId: string;
};

const findCategoryTreeById = (id: string, tree: any[]) => {
  for (const current of tree) {
    if (current.isProduct && id === current.id) {
      return [current.id];
    }
    if (current.children.length) {
      const res = findCategoryTreeById(id, current.children);
      if (res?.length) {
        res.push(current.id);
        return res;
      }
    }
  }
};

export const CascaderModal = ({ isOpen, onClose, groupId }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const [categoriesTree, setCategoriesTree] = useState<any[]>([]);

  const { loading, data } = useRequest(() => getCategories(localCountry?.iso, true), {
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const categoryTreeData = useMemo(() => {
    if (categoriesTree && groupId) return findCategoryTreeById(groupId, categoriesTree);
  }, [categoriesTree, groupId]);

  console.log(categoryTreeData?.toString()[0], "cascader tree data");

  useEffect(() => {
    if (data) {
      const treeData = data?.map((el) => getTreeItems(el));
      setCategoriesTree(treeData);
    }
  }, [data]);

  return (
    <Modal title="Edit Category" open={isOpen} onCancel={onClose} className={styles.modal} closeIcon="X" footer={false}>
      <CategoryCascader
        categoriesTree={categoriesTree}
        isCategoriesLoading={loading}
        button={{ label: "Confirm" }}
        parentId={groupId}
      />
    </Modal>
  );
};
