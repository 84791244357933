import { useRequest } from "ahooks";
import { TableWithPagination } from "Components/TableWithPagination";
import { Pagination as PaginationData } from "pages/Main/Components/AdminPanel/Options";
import { getUserAds, sortByAdsUser, UserAdsStatuses } from "helpers/backend_helper";
import sortIcon from "assets/svg/SortAscending.svg";
import { ENTITY_PER_PAGE, PROP_NAME_INPUT_TEXT, PROP_NAME_SKIP_ADS, PROP_NAME_SORTBY } from "helpers/constants";
import { NotificationContext } from "context/notificationContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AdminContext } from "context/adminContext";
import styles from "./index.module.css";
import { Button, Tooltip } from "antd";
import { PhotoNameTab } from "Components/PhotoNameTab";
import dayjs from "dayjs";
import { Link, useSearchParams } from "react-router-dom";
import { SocketContext } from "context/socketContext";
import { onSort } from "helpers/helper";

type Props = {
  countryUserId: string;
  status: UserAdsStatuses;
};

export const RequestAds = ({ countryUserId, status }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const { leaveChatAdmin, currentChatJoinedID } = useContext(SocketContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [sortBy, setSortBy] = useState<sortByAdsUser>("");
  const [skipAds, setSkipAds] = useState(+searchParams.get(PROP_NAME_SKIP_ADS) || 0);
  const [pagination, setPagination] = useState<PaginationData>({ skip: 0, take: ENTITY_PER_PAGE, total: 0 });
  const sortByFilter = searchParams.get(PROP_NAME_SORTBY);

  const skipAdsFilter = searchParams.get(PROP_NAME_SKIP_ADS);
  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT); // debounced

  useEffect(() => {
    if (searchText) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(PROP_NAME_SKIP_ADS);
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchText]);

  useEffect(() => {
    if (sortByFilter) {
      setSortBy(sortByFilter as sortByAdsUser);
    } else {
      setSortBy("");
    }
  }, [sortByFilter]);

  const onSortBy = useCallback(
    (sortName: string) => {
      onSort(sortName, searchParams, sortBy, setSearchParams);
    },
    [searchParams, setSearchParams, sortBy],
  );

  useEffect(() => {
    if (skipAdsFilter) {
      setSkipAds(+skipAdsFilter);
    } else {
      setSkipAds(0);
    }
  }, [skipAdsFilter]);

  const { loading, data: userAds } = useRequest(
    () =>
      getUserAds({
        countryUserId,
        localCountry: localCountry.iso.toUpperCase(),
        pagination: { skip: skipAds, take: ENTITY_PER_PAGE, total: pagination.total },
        sort: { sortBy: sortBy },
        status,
      }),
    {
      ready: !!countryUserId && !!localCountry?.iso,
      refreshDeps: [sortBy, localCountry, countryUserId, skipAds],
      onError: (err: any) => {
        openNotificationWithIcon("error", err.response?.data?.message || err?.message);
      },
      onSuccess: (data) => {
        if (data.count) {
          setPagination((prev) => ({ ...prev, total: data.count }));
        }
      },
    },
  );

  const source = useMemo(
    () =>
      userAds?.products.map((el, i) => ({
        ...el,
        key: i,
        id: (
          <Tooltip title={el.id}>
            <div>{`${el.id.slice(0, 5)}...`}</div>
          </Tooltip>
        ),
        product: (
          <div>
            <PhotoNameTab photoFullPath={el?.productPhoto?.preview} title={el?.name} />
          </div>
        ),
        category: (
          <Tooltip title={el?.categoryName?.length > 15 ? el.categoryName : ""}>
            <div>{el?.categoryName ? `${el.categoryName}`.slice(0, 15) : "-"}</div>
          </Tooltip>
        ),
        usage_rate: <div>{el?.usagePercentage ? `${el.usagePercentage}%` : "-"}</div>,
        quality_rate: <div>{el?.qualityRate ? el.qualityRate : "-"}</div>,
        moderation_date: <div>{el?.moderatedAt ? dayjs(new Date(el.moderatedAt)).format("DD.MM.YYYY") : "-"}</div>,
        actions: (
          <div className={styles.buttonWrapper}>
            <Link
              to={{ search: "", pathname: `/products/item/${el.id}` }}
              onClick={() => {
                if (currentChatJoinedID?.current) leaveChatAdmin(currentChatJoinedID?.current);
              }}
            >
              <Button type="primary" className={styles.button}>
                Details
              </Button>
            </Link>
          </div>
        ),
      })) || [],
    [userAds?.products],
  );

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Usage Rate</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="usagePercentage"
                  style={{ width: 16, height: 16, transform: sortBy === "usagePercentage" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("usagePercentage");
              }}
            />
          </div>
        ),
        dataIndex: "usage_rate",
        key: "usage_rate",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Quality Rate</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="qualityRate"
                  style={{ width: 16, height: 16, transform: sortBy === "qualityRate" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("qualityRate");
              }}
            />
          </div>
        ),
        dataIndex: "quality_rate",
        key: "quality_rate",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Moderation Date</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="moderatedAt"
                  style={{ width: 16, height: 16, transform: sortBy === "moderatedAt" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                onSortBy("moderatedAt");
              }}
            />
          </div>
        ),
        dataIndex: "moderation_date",
        key: "moderation_date",
      },
      {
        title: " ",
        dataIndex: "actions",
        key: "actions",
      },
    ],
    [sortBy],
  );

  return (
    <TableWithPagination
      paginationTable={{
        pagination,
        skipAds: skipAds,
      }}
      propsTable={{ columns, dataSource: source, headerShow: true, isLoading: loading }}
    />
  );
};
