import { Avatar, Button, Rate } from "antd";
import styles from "./index.module.css";
import trash from "assets/svg/Trash.svg";
import { UserOutlined } from "@ant-design/icons";
import { API_ORIGIN } from "pages/Main/Components/AdminPanel/Debate/DetailsModal/utils";
import { ReviewRespond, UserDetails } from "helpers/backend_helper";

type Props = {
  userDetails: UserDetails;
  id: number;
  author: {
    avatar: string;
    firstName: string;
  };
  comment: string;
  comments: ReviewRespond[];
  rating: string;
  createdAt: string;
  onDelete: (id: number) => void;
  isRenter: boolean;
};

export const ReviewComponent = ({
  author,
  comment,
  createdAt,
  onDelete,
  id,
  rating,
  comments,
  userDetails,
  isRenter,
}: Props) => {
  return (
    <div>
      <div className={styles.review}>
        <div className={styles.avatarName}>
          {author.avatar ? (
            <Avatar
              style={{ width: 57.6, height: 57.6 }}
              icon={<img src={API_ORIGIN + "/public/avatars/" + author.avatar} alt="userPhoto" />}
              size={57.6}
            />
          ) : (
            <Avatar style={{ width: 57.6, height: 57.6 }} icon={<UserOutlined size={57.6} />} size={57.6} />
          )}
          <div className={styles.text}>
            <div>{author.firstName}</div>
            <div>{comment}</div>
          </div>
        </div>
        <div className={styles.dataButton}>
          <div className={styles.rateDate}>
            <Rate disabled defaultValue={+rating} allowHalf className={styles.rating} />
            <div className={styles.date}>
              {new Date(createdAt).toLocaleDateString("EN", { day: "2-digit", month: "short", year: "numeric" })}
            </div>
          </div>
          <Button
            onClick={() => onDelete(id)}
            icon={<img src={trash} alt="trash" width={24} height={24} />}
            type="primary"
            className={styles.button}
          />
        </div>
      </div>
      {comments?.length ? (
        <div className={styles.commentsTreeWrapper}>
          {comments.map((comment, i) => (
            <div key={comment.id} className={styles.commentTree} style={{ marginLeft: `${(i + 1) * 40}px` }}>
              <div className={styles.commentAvatarWrapper + " " + styles.text}>
                <Avatar src={`${API_ORIGIN}/public/avatars/${userDetails.avatar}`} size={42} />
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px", width: "70%" }}>
                  <span className={styles.itemHeader}>
                    {userDetails.firstName} {userDetails.lastName}
                  </span>
                  <div style={{ width: "100%" }} className={styles.itemMessage}>
                    {comment.comment}
                  </div>
                </div>
              </div>
              <div className={styles.date} style={{ width: 126.69 }}>
                <div className={styles.lessorCommentTree}>{isRenter ? "Renter" : "Lessor"}</div>
                {new Date(comment.createdAt).toLocaleString("EN", { day: "2-digit", month: "short", year: "numeric" })}
              </div>
              <div style={{ width: 45.63 }}></div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
