import { Button, Input, Spin, UploadFile } from "antd";
import { createRef, useCallback, useContext, useEffect, useState } from "react";
import { AdminContext, Chat as ChatType } from "context/adminContext";
import dayjs from "dayjs";

import styles from "./Chat.module.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { UploadFilesComponent } from "Components/UploadFilesComponent";
import { PaperClipIcon } from "Common/Icons/PaperClip";
import { postFileChat } from "helpers/backend_helper";
import { ChatsContext } from "context/chatsContext";

type Props = {
  chatId: string;
  sendSocketMessage: (content: string, chatId: string) => Promise<any>;
  loading: boolean;
  cursor: number;
  chatData: ChatType;
  loadMore: (cursor: number) => void;
  hasMore: boolean;
  isDebateChat: boolean;
  isArbitr: boolean;
};

const FilesRender = ({ message }: { message: any }) => {
  return message.map((file) => {
    return (
      <div key={file.content} className={styles.linkWrapper}>
        <PaperClipIcon />
        <a href={file.link} target="_blank" rel="noreferrer">
          {file.content}
        </a>
      </div>
    );
  });
};

export const Chat = ({ chatId, chatData, sendSocketMessage, loading, loadMore, cursor, hasMore, isArbitr }: Props) => {
  const [textMessage, setTextMessage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const divRef = createRef<HTMLDivElement>();
  const { localCountry } = useContext(AdminContext);
  const { bunchFiles, resetInputFields } = useContext(ChatsContext);
  const sendUploadedFiles = useCallback(async () => {
    if (bunchFiles.status === "DONE") {
      if (bunchFiles.files.length) {
        const result = await postFileChat(chatId, { files: bunchFiles.files }, isArbitr, {
          headers: {
            "country-iso-code": localCountry.iso,
          },
        });
        console.log("Result of sending files: ", result);
        resetInputFields();
      }
    }
  }, [bunchFiles, chatId, isArbitr, localCountry.iso, resetInputFields]);

  const sendTextHandler = () => {
    if (!textMessage) {
      sendUploadedFiles();
      return;
    }
    sendSocketMessage(textMessage, chatId).then(sendUploadedFiles);
    setTextMessage("");
  };

  const loadMoreData = () => {
    if (loading) return;
    loadMore(cursor);
  };

  useEffect(() => {
    if (chatId) {
      console.log(chatId);
      divRef.current?.scrollTo({ left: 0, top: 0 });
    }
  }, [chatId]);

  return (
    <div className={styles.chatWrapper}>
      <>
        <div className={styles.chat} id="scrollableElem" ref={divRef}>
          <InfiniteScroll
            dataLength={chatData.messages.length}
            hasMore={hasMore}
            className={styles.chat}
            loader={<Spin />}
            inverse={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            next={loadMoreData}
            scrollableTarget="scrollableElem"
          >
            {chatData.messages?.map((messageGroup) => {
              const message = messageGroup?.messages;
              const isFiles = message[0].contentType !== "text";

              if (message[0].authorType === "CLIENT" || message[0].author) {
                return (
                  <div key={messageGroup.grouping_key} className={styles.myMessages}>
                    {message.author && (
                      <div
                        className={styles.messageDate}
                      >{`${message.author.firstName} ${message.author.lastName}`}</div>
                    )}
                    <div className={styles.messageDate}>{dayjs(message.createdAt).format("MMMM D, hh:mm A")}</div>
                    <div className={isFiles ? styles.message + " " + styles.filesMessage : styles.message}>
                      {isFiles ? <FilesRender message={message} /> : message[0].content}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={messageGroup.grouping_key} className={styles.otherMessages}>
                    <div className={styles.messageDate}>{dayjs(message[0].createdAt).format("MMMM D, hh:mm A")}</div>
                    <div className={isFiles ? styles.message + " " + styles.filesMessage : styles.message}>
                      {isFiles ? <FilesRender message={message} /> : message[0].content}
                    </div>
                  </div>
                );
              }
            })}
          </InfiniteScroll>
        </div>

        <div className={styles.inputBox}>
          <UploadFilesComponent fileList={fileList} setFileList={setFileList} chatId={chatId} isArbitr={isArbitr}>
            <Button type="link" className={styles.paperClipBtn} icon={<PaperClipIcon />} />
          </UploadFilesComponent>
          <Input
            className={styles.input}
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                sendTextHandler();
              }
            }}
          />
          <button className={styles.sendButton} onClick={sendTextHandler} />
        </div>
      </>
    </div>
  );
};
