import { useParams } from "react-router";
import styles from "./index.module.css";
import { Link, useSearchParams } from "react-router-dom";
import { TitleComponent } from "Components/Title";
import arrowLeftBack from "assets/svg/ArrowLeftBack.svg";
import { Button, Spin } from "antd";
import { useCallback, useContext, useState } from "react";
import { CopyButton } from "Components/CopyButton";
import { UserChat } from "../../Users/UserComponent/UserChat";
import { DealsInfo } from "Components/DealsInfo";
import { ModalComponent } from "Components/ModalComponent";
import { ManageModalDisputes } from "../ManageModalDisputes";
import { useRequest } from "ahooks";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { getDebateDetails } from "helpers/backend_helper";
type DisputeStatuses = "in_process" | "requested" | "declined" | "complited" | "";

const STATUSES_DISPUTE_LABEL = {
  in_process: "In Process",
  requested: "Requested",
  declined: "Declined",
  complited: "Complited",
};

export const DisputeDetails = () => {
  const { dealId } = useParams();
  const [searchParam] = useSearchParams();
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [currentStatus, setCurrentStatus] = useState<DisputeStatuses>("requested");
  const [isOpenDetails, setOpenDetails] = useState(false);
  const isTransfered = true;
  const [declineOpen, setDeclineOpen] = useState(false);
  const [startOpen, setStartOpen] = useState(false);

  const { data: debateDetails, loading: debateLoading } = useRequest(
    () => getDebateDetails(dealId, localCountry.iso.toUpperCase()),
    {
      ready: !!localCountry?.iso,
      onError: (err: any) => {
        openNotificationWithIcon("error", err.response?.data?.message || err.message);
      },
    },
  );

  console.log(debateDetails, debateLoading);

  const closeDeclineOrStartModal = useCallback(() => {
    setDeclineOpen(false);
    setStartOpen(false);
  }, []);

  const onDecline = useCallback(() => {
    console.log("decline");
  }, []);
  const onStart = useCallback(() => {
    console.log("start");
  }, []);
  console.log(setCurrentStatus);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.btnTitle}>
            <Link to={{ search: ``, pathname: `/disputes` }}>
              <Button type="text" className={styles.btnBack} icon={<img src={arrowLeftBack} alt="arrow back" />} />
            </Link>
            <TitleComponent text="Dispute" />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className={styles.grayTitle}>ID</span>
            <CopyButton copyText="copied!" text={dealId} wait={1000} sliceOfText={7} className={styles.copyBtn} />
          </div>
          {currentStatus !== "requested" && (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className={styles.grayTitle}>Support ID</span>
                <CopyButton copyText="copied!" text={dealId} wait={1000} sliceOfText={7} className={styles.copyBtn} />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className={styles.grayTitle}>Dates</span>
                <span className={styles.dateTitle}>May, 21</span>
              </div>
            </>
          )}
          <Button
            type="primary"
            className={styles.btn}
            onClick={() => {
              setOpenDetails(true);
            }}
          >
            Deal Info
          </Button>
          <div className={styles.changeStatus}>
            {currentStatus ? (
              <>
                <span>Status</span>
                <span>{STATUSES_DISPUTE_LABEL[currentStatus]}</span>
                {currentStatus === "requested" ? (
                  <>
                    <Button
                      type="primary"
                      className={styles.confirmBtn}
                      onClick={() => {
                        setStartOpen(true);
                      }}
                    >
                      Start Dispute
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      danger
                      className={styles.declineBtn}
                      onClick={() => {
                        setDeclineOpen(true);
                      }}
                    >
                      Decline Dispute
                    </Button>
                  </>
                ) : currentStatus === "in_process" ? (
                  <>
                    <Button
                      type="primary"
                      className={styles.confirmBtn}
                      onClick={() => {
                        console.log("1");
                      }}
                    >
                      Manage
                    </Button>
                  </>
                ) : currentStatus === "complited" ? (
                  <>
                    <Button
                      type="primary"
                      className={styles.confirmBtn}
                      onClick={() => {
                        console.log("1");
                      }}
                    >
                      Report
                    </Button>
                  </>
                ) : null}
              </>
            ) : (
              <Spin
                style={{ width: "100%", height: "100%", top: "50%", left: "50%", transform: "transform(50% -50%)" }}
              />
            )}
          </div>
        </div>
        <UserChat idChat={searchParam.get("chatId")} className={styles.userChat} />
      </div>
      {debateDetails && (
        <DealsInfo
          close={() => {
            setOpenDetails(false);
          }}
          isOpen={isOpenDetails}
          id={debateDetails?.rent?.id}
        />
      )}
      <ModalComponent
        isOpen={declineOpen}
        onClose={closeDeclineOrStartModal}
        buttons={{
          confirm: {
            label: "Decline Dispute",
            onConfirm: onDecline,
            isDanger: true,
          },
          decline: {
            label: "Cancel",
            onDecline: closeDeclineOrStartModal,
          },
        }}
        content={
          isTransfered
            ? "Please make sure that the product has been transferred on the initial date"
            : "Please make sure that the product has been returned on the initial date"
        }
        title="Decline Dispute"
      />

      <ModalComponent
        isOpen={startOpen}
        onClose={closeDeclineOrStartModal}
        buttons={{
          confirm: {
            label: "Start Dispute",
            onConfirm: onStart,
          },
          decline: {
            label: "Cancel",
            onDecline: closeDeclineOrStartModal,
          },
        }}
        content={
          isTransfered
            ? "Please make sure that the product has not been transferred on the initial date"
            : "Please make sure that the product has not been returned on the initial date"
        }
        title="Start Dispute"
      />
      {debateDetails && <ManageModalDisputes debateInfo={debateDetails} />}
    </>
  );
};
