import { Badge, Button, Spin } from "antd";
import styles from "./index.module.css";
import { ArrowRightIcon } from "Common/Icons/Arrow";
import { StatusInfoUsers } from "../Users";
import lockRed from "assets/svg/redLock.svg";
import RentSwitcher from "./Switcher";
import { useContext, useEffect, useMemo, useState } from "react";
import { InfoComponent } from "Components/InfoComponent";
import { MainInfo } from "./MainInfo";
import { UserChat } from "./UserChat";
import { useRequest } from "ahooks";
import { frozeUser, getUserDetails, UserDetails } from "helpers/backend_helper";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { TabsComponent } from "Components/TabsComponent";
import { TableAds } from "./TableAds";
import { TableDeals } from "./TableDeals";
import { TablePayments } from "./TablePayments";
import { TableReviews } from "./TableReviews";
import { useNavigate } from "react-router";
import { SocketContext } from "context/socketContext";
import { useSearchParams } from "react-router-dom";
import {
  PROP_NAME_INPUT_TEXT,
  PROP_NAME_IS_RENTER,
  PROP_NAME_MAIN_TAB,
  PROP_NAME_NEXT_TAB,
  PROP_NAME_SKIP_ADS,
  PROP_NAME_SORTBY,
} from "helpers/constants";

const getAds = (countryUserId: string, moderatingBadge: number) => ({
  key: "0",
  label: (
    <Badge count={0} size="small" className={styles.badge}>
      <div className={styles.deals}>Ads</div>
    </Badge>
  ),
  children: <TableAds moderatingBadge={moderatingBadge} countryUserId={countryUserId} />,
});

const getItems = (countryUserId: string, isRenter: boolean, rateAll: number, userDetails: UserDetails) => [
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Deals</div>
      </Badge>
    ),
    children: <TableDeals countryUserId={countryUserId} isRenter={isRenter} />,
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Payments</div>
      </Badge>
    ),
    children: <TablePayments countryUserId={countryUserId} userDetails={userDetails} />,
  },
  {
    key: "3",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Reviews</div>
      </Badge>
    ),
    children: (
      <TableReviews countryUserId={countryUserId} isRenter={isRenter} rateAll={rateAll} userDetails={userDetails} />
    ),
  },
];

export type UserPropInfo = {
  avatar: string;
  balance: number;
  debt: number;
  email: string;
  firstName: string;
  id: string;
  insuranceRate: number;
  lastName: string;
  patronymic: string;
  phone: string;
  rating: number;
  status: StatusInfoUsers[];
  mainId: string;
};

export const UserComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [countryUserId, mainId] = [searchParams.get("countryUserId"), searchParams.get("mainId")];
  const [isRenter, setIsRenter] = useState(true);
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { leaveChatAdmin, currentChatJoinedID } = useContext(SocketContext);
  const [userData, setUserData] = useState<UserDetails>();

  const isRenterFilter = searchParams.get(PROP_NAME_IS_RENTER); // debounced

  useEffect(() => {
    if (isRenterFilter === "true") {
      setIsRenter(true);
    } else {
      setIsRenter(false);
    }
  }, [isRenterFilter]);

  const { loading } = useRequest(() => getUserDetails(countryUserId, localCountry.iso.toUpperCase()), {
    ready: !!localCountry && !!countryUserId,
    onError: (err: any) => {
      openNotificationWithIcon("error", err.response?.data?.message || err?.message);
    },
    onSuccess: (data) => {
      setUserData(data);
    },
  });
  const { run: toBeFrozen } = useRequest(
    (isFrozen: boolean) => {
      return frozeUser(mainId, { froze: isFrozen }, localCountry.iso.toUpperCase());
    },
    {
      manual: true,
      onError: (err: any) => {
        openNotificationWithIcon("error", err.response?.data?.message || err?.message);
      },
      onSuccess: (_, params) => {
        setUserData((prev) => ({ ...prev, frozen: params[0] }));
      },
    },
  );
  const items = useMemo(() => {
    if (!userData) return [];
    const mainItems = getItems(countryUserId, isRenter, userData?.rating, userData);

    if (!isRenter) mainItems.unshift(getAds(countryUserId, userData.moderationBage));
    return mainItems;
  }, [isRenter, countryUserId, userData]);
  return (
    <>
      <div className={styles.container}>
        {loading && (
          <Spin style={{ top: "50%", left: "50%", transform: "translate(50%,-50%)", position: "absolute" }} />
        )}

        {userData && (
          <>
            <div className={styles.userChoosenTop}>
              <Button
                type="text"
                style={{ padding: 0 }}
                onClick={() => {
                  if (currentChatJoinedID.current) leaveChatAdmin(currentChatJoinedID.current);
                  navigate("/users", { replace: true });
                }}
              >
                Users
              </Button>
              <ArrowRightIcon size={17} />
              <div style={{ fontWeight: 700 }}>{`${userData.firstName} ${userData.lastName}`}</div>
            </div>
            <div className={styles.mainInfo}>
              <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
                <div style={{ fontWeight: 700, fontSize: 24 }}>
                  {`${userData.firstName} ${userData.lastName}`}
                  <span style={{ fontWeight: 700, color: "#868686", fontSize: 24, marginLeft: 10 }}>
                    {userData?.address}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: 16, fontWeight: 700, marginRight: 10 }}>ID {countryUserId}</div>
                  {userData.frozen && (
                    <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                      <img src={lockRed} alt="restricted" />{" "}
                      <span style={{ color: "#F40909", fontWeight: 700, fontSize: 16 }}>User Restricted</span>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.switcherContainer}>
                <RentSwitcher
                  isRenter={isRenter}
                  onChangeType={(isRenterProp: boolean) => {
                    searchParams.set(PROP_NAME_IS_RENTER, isRenterProp.toString());
                    searchParams.delete(PROP_NAME_SKIP_ADS);
                    searchParams.delete(PROP_NAME_SORTBY);
                    searchParams.delete(PROP_NAME_INPUT_TEXT);
                    setSearchParams(searchParams, { replace: true });
                  }}
                />
                <InfoComponent
                  infoOfData="Rate of insurance"
                  label="Insurance Rate"
                  value={userData?.insuranceRate?.toFixed(2)}
                />
              </div>
            </div>
            <div className={styles.chatAndMainInfo}>
              <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                <MainInfo userInfo={userData} toFrozeUser={toBeFrozen} countryUserId={countryUserId} />
                <UserChat idChat={userData.supportChatId} />
              </div>
            </div>
            <div className={styles.tabsWrapper}>
              <TabsComponent
                defaultActiveKey={searchParams.get(PROP_NAME_MAIN_TAB) || "0"}
                items={items}
                destroyInactiveTabPane
                onChange={(activeKey) => {
                  searchParams.set(PROP_NAME_MAIN_TAB, items.find((v) => v.key === activeKey).key);
                  searchParams.delete(PROP_NAME_NEXT_TAB);
                  searchParams.delete(PROP_NAME_SORTBY);
                  searchParams.delete(PROP_NAME_SKIP_ADS);

                  setSearchParams(searchParams, { replace: true });
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
