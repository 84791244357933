import { DatePicker } from "antd";
import caretRight from "assets/svg/CaretRightGray.svg";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import React, { useEffect, useState } from "react";

import { SeparatorIcon } from "Common/Icons/Separator";

import styles from "./index.module.css";

import "dayjs/locale/zh-cn";

type Props = {
  onDelete?: (deletedDateStrings: [string, string]) => void;
  onChange: (dates: any, dateStrings: [string, string]) => void;
  defaultValue?: { from: string; to: string };
  placeHolders?: {
    start: string;
    end: string;
  };
  className?: string;
  value?: [Date, Date];
};

dayjs.extend(updateLocale);
dayjs.updateLocale("zh-cn", {
  weekStart: 0,
});
const RangePicker = ({ onChange, defaultValue, onDelete, placeHolders, className, value }: Props) => {
  const [dateStrings, setDateStrings] = useState<[string, string]>();

  useEffect(() => {
    if (defaultValue) {
      setDateStrings([defaultValue.from, defaultValue.to]);
    }
  }, [defaultValue, setDateStrings]);
  return (
    <DatePicker.RangePicker
      defaultValue={defaultValue ? [dayjs(defaultValue.from), dayjs(defaultValue.to)] : null}
      format="DD.MM.YYYY"
      className={className ? className + " " + styles.datePickerRange : styles.datePickerRange}
      value={value && [dayjs(value[0]), dayjs(value[1])]}
      locale={{
        lang: {
          locale: "en_US",
          placeholder: "Select date",
          rangePlaceholder: placeHolders ? [placeHolders.start, placeHolders.end] : ["Start date", "End date"],
          today: "Today",
          now: "Now",
          backToToday: "Back to today",
          ok: "OK",
          clear: "Clear",
          month: "Month",
          year: "Year",
          timeSelect: "Select time",
          dateSelect: "Select date",
          monthSelect: "Choose a month",
          yearSelect: "Choose a year",
          decadeSelect: "Choose a decade",
          yearFormat: "YYYY",
          dateFormat: "M/D/YYYY",
          dayFormat: "D",
          dateTimeFormat: "M/D/YYYY HH:mm:ss",
          monthFormat: "MMMM",
          monthBeforeYear: true,
          previousMonth: "Previous month (PageUp)",
          nextMonth: "Next month (PageDown)",
          previousYear: "Last year (Control + left)",
          nextYear: "Next year (Control + right)",
          previousDecade: "Last decade",
          nextDecade: "Next decade",
          previousCentury: "Last century",
          nextCentury: "Next century",
          shortWeekDays: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        },
        timePickerLocale: {
          placeholder: "Select time",
        },
        dateFormat: "YYYY-MM-DD",
        dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
        weekFormat: "YYYY-wo",
      }}
      mode={["date", "date"]}
      nextIcon={<img src={caretRight} alt="carretright" />}
      prevIcon={<img src={caretRight} alt="carretright" style={{ transform: "rotate(180deg)" }} />}
      size="large"
      separator={<SeparatorIcon />}
      allowEmpty={[false, false]}
      popupClassName={styles.popup}
      onChange={(dates, date) => {
        if ((date[0] === "" || date[1] === "") && onDelete) {
          onDelete(dateStrings);
        } else {
          onChange(dates, date);
          setDateStrings(date);
        }
      }}
    />
  );
};

export const RangePickerComponent = React.memo((props: Props) => <RangePicker {...props} />);
