import { ReactNode, useContext, useMemo, useState } from "react";
import { Badge, Divider } from "antd";
import { type Category } from "../Categories";

import styles from "./index.module.css";
import { SearchComponent } from "Components/SearchComponent";
import { InfoComponent } from "Components/InfoComponent";
import { RequestsProducts } from "./RequestsProducts";
import { TitleComponent } from "Components/Title";
import { TabsComponent } from "Components/TabsComponent";
import { useRequest } from "ahooks";
import { getAdsMetrics, getCategories, getCities } from "helpers/backend_helper";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import {
  PROP_NAME_CITY,
  PROP_NAME_INPUT_TEXT,
  PROP_NAME_MAIN_TAB,
  PROP_NAME_PRODUCT_CATEGORY,
  PROP_NAME_SKIP_ADS,
  PROP_NAME_SORTBY,
} from "helpers/constants";
import { useSearchParams } from "react-router-dom";

export type Group = {
  name: string;
  id: number;
  description: string;
  productCategories: Category[];
};

const getItems = () => [
  {
    key: "0",
    label: (
      <Badge count={1} size="small" className={styles.badge}>
        <div className={styles.deals}>Moderating</div>
      </Badge>
    ),
    children: <RequestsProducts status="Moderating" />,
  },
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Active</div>
      </Badge>
    ),
    children: <RequestsProducts status="Active" />,
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Stopped</div>
      </Badge>
    ),
    children: <RequestsProducts status="Stopped" />,
  },
  {
    key: "3",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Draft</div>
      </Badge>
    ),
    children: <RequestsProducts status="Draft" />,
  },
  {
    key: "4",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Archived</div>
      </Badge>
    ),
    children: <RequestsProducts status="Archived" />,
  },
];

const Products = () => {
  const [cities, setCities] = useState<{ label: string | ReactNode; value: string }[]>([]);
  const [categories, setCategories] = useState<{ label: string | ReactNode; value: string }[]>([]);
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT);
  const citiesFilter = searchParams.get(PROP_NAME_CITY);
  const categoriesFilter = searchParams.get(PROP_NAME_PRODUCT_CATEGORY);

  const items = useMemo(() => getItems(), []);
  const { loading: getCitiesLoading } = useRequest(() => getCities(localCountry.iso, true), {
    refreshDeps: [localCountry],
    ready: !!localCountry?.iso,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || error?.message);
    },
    onSuccess: (data) => {
      setCities(() => [
        { label: "All", value: "" },
        ...data.cities.map((city) => {
          const upperCaseFirstLt = city.formattedData[0].toUpperCase() + city.formattedData.slice(1);
          return { label: upperCaseFirstLt, value: city.id };
        }),
      ]);
    },
  });

  const { data: metricAds, loading: isLoadingMetricAds } = useRequest(
    () =>
      getAdsMetrics(
        {
          categories: categoriesFilter?.length ? categoriesFilter.split(",") : [],
          cities: citiesFilter?.length ? citiesFilter.split(",") : [],
          searchText: searchText,
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      refreshDeps: [searchParams],
      ready: !!localCountry?.iso,
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message || error?.message);
      },
    },
  );

  const { loading: isLoadingCategoriesAds } = useRequest(() => getCategories(localCountry.iso.toUpperCase()), {
    refreshDeps: [localCountry],
    ready: !!localCountry?.iso,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || error?.message);
    },
    onSuccess: (data) => {
      setCategories(() => [
        { label: "All", value: "" },
        ...data.map((cat) => {
          const upperCaseFirstLt = cat.name[0].toUpperCase() + cat.name.slice(1);
          return { label: upperCaseFirstLt, value: cat.id };
        }),
      ]);
    },
  });

  return (
    <>
      <div className={styles.container}>
        <div style={{ marginBottom: 20 }}>
          <TitleComponent text="Ads" />
        </div>
        <div className={styles.wrapper}>
          <div>
            <SearchComponent
              selectData={[
                {
                  queryName: PROP_NAME_CITY,
                  isLoadingSelect: getCitiesLoading,
                  options: cities,
                  label: "City",
                },
                {
                  isLoadingSelect: isLoadingCategoriesAds,
                  options: categories,
                  label: "Product Category",
                  queryName: PROP_NAME_PRODUCT_CATEGORY,
                },
              ]}
              inputData={{
                palceHolder: "Search",
                queryTextName: PROP_NAME_INPUT_TEXT,
              }}
            />
          </div>
          <Divider style={{ borderWidth: "2px" }} />
          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
            {[
              {
                infoOfData: "Count of ads",
                label: "Ads",
                value: metricAds?.ads_metric ? metricAds?.ads_metric : "-",
                loading: isLoadingMetricAds,
              },
              {
                infoOfData: "User's rating",
                label: "Usage Rate",
                value: metricAds?.usage_percentage ? metricAds?.usage_percentage + "%" : "-",
                loading: isLoadingMetricAds,
              },
              {
                infoOfData: "Quality",
                label: "Quality Rate",
                value: metricAds?.quality_rate ? metricAds?.quality_rate : "-",
                loading: isLoadingMetricAds,
              },
            ].map(({ infoOfData, label, value }, i) => (
              <InfoComponent infoOfData={infoOfData} label={label} value={value} key={i} isLoading={false} />
            ))}
          </div>
          <TabsComponent
            defaultActiveKey={searchParams.get(PROP_NAME_MAIN_TAB) || "0"}
            items={items}
            className={styles.tabs}
            destroyInactiveTabPane
            onChange={(activeKey) => {
              searchParams.set(PROP_NAME_MAIN_TAB, items.find((v) => v.key === activeKey).key);
              searchParams.delete(PROP_NAME_SORTBY);
              searchParams.delete(PROP_NAME_SKIP_ADS);
              setSearchParams(searchParams, { replace: true });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Products;
