import { useRequest } from "ahooks";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { getUserAdsMetrics } from "helpers/backend_helper";
import { useContext, useMemo } from "react";
import styles from "./index.module.css";

import { InfoComponent } from "Components/InfoComponent";
import { Badge } from "antd";
import { TabsComponent } from "Components/TabsComponent";
import { RequestAds } from "./RequestAds";
import { useSearchParams } from "react-router-dom";
import { PROP_NAME_NEXT_TAB, PROP_NAME_SKIP_ADS, PROP_NAME_SORTBY } from "helpers/constants";

type Props = {
  countryUserId: string;
  moderatingBadge: number;
};
const getItems = (countryUserId: string, moderatingBadge: number) => [
  {
    key: "0",
    label: (
      <Badge count={moderatingBadge} size="small" className={styles.badge}>
        <div className={styles.deals}>Moderating</div>
      </Badge>
    ),
    children: <RequestAds countryUserId={countryUserId} status="Moderating" />,
  },
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Active</div>
      </Badge>
    ),
    children: <RequestAds countryUserId={countryUserId} status="Active" />,
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Stopped</div>
      </Badge>
    ),
    children: <RequestAds countryUserId={countryUserId} status="Stopped" />,
  },
  {
    key: "3",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Draft</div>
      </Badge>
    ),
    children: <RequestAds countryUserId={countryUserId} status="Draft" />,
  },
  {
    key: "4",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Archived</div>
      </Badge>
    ),
    children: <RequestAds countryUserId={countryUserId} status="Archived" />,
  },
];
export const TableAds = ({ countryUserId, moderatingBadge }: Props) => {
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const items = useMemo(() => getItems(countryUserId, moderatingBadge), [countryUserId, moderatingBadge]);
  const { data: userMetrics, loading: isLoadingMetrics } = useRequest(
    () => {
      return getUserAdsMetrics(countryUserId, localCountry.iso.toUpperCase());
    },
    {
      ready: !!countryUserId && !!localCountry?.iso,
      onError: (err: any) => {
        openNotificationWithIcon("error", err.response?.data?.message || err?.message);
      },
    },
  );

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        {[
          { infoDate: "Amount of ads", label: "Ads", value: userMetrics?.adsMetric },
          { infoDate: "Amount of usage rate", label: "Usage Rate", value: `${userMetrics?.usagePercentage}%` },
          { infoDate: "Quality rate", label: "Quality Rate", value: userMetrics?.qualityRate },
        ].map(({ infoDate, label, value }, i) => (
          <InfoComponent key={i} infoOfData={infoDate} label={label} value={value} isLoading={isLoadingMetrics} />
        ))}
      </div>
      <TabsComponent
        defaultActiveKey={searchParams.get(PROP_NAME_NEXT_TAB) || "0"}
        items={items}
        className={styles.tabs}
        destroyInactiveTabPane
        onChange={(activeKey) => {
          searchParams.set(PROP_NAME_NEXT_TAB, items.find((v) => v.key === activeKey).key);
          searchParams.delete(PROP_NAME_SKIP_ADS);
          searchParams.delete(PROP_NAME_SORTBY);
          setSearchParams(searchParams, { replace: true });
        }}
      />
    </div>
  );
};
