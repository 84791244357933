export const ENTITY_PER_PAGE = 8;
export const PROP_NAME_CITY = "city";
export const PROP_NAME_PRODUCT_CATEGORY = "categories";
export const PROP_NAME_INPUT_TEXT = "searchText";
export const PROP_NAME_STATUS = "status";
export const PROP_NAME_INSURANCE = "insurance";
export const PROP_NAME_METHOD = "method";
export const PROP_NAME_PURPOSE = "purpose";
export const PROP_NAME_SORTBY = "sortBy";
export const PROP_NAME_SKIP_ADS = "skipAds";
export const PROP_NAME_FROM_DATE = "fromDate";
export const PROP_NAME_FROM_DATE_SEARCH_MAIN = "fromDateMain";
export const PROP_NAME_TO_DATE_SEARCH_MAIN = "toDateMain";
export const PROP_NAME_TO_DATE = "toDate";
export const PROP_NAME_IS_RENTER = "isRenter";
export const PROP_NAME_MAIN_TAB = "mainTab";
export const PROP_NAME_NEXT_TAB = "nextTab";
