import { Avatar, Button, Rate } from "antd";
import { UserOutlined } from "@ant-design/icons";

import styles from "./index.module.css";
import trash from "assets/svg/Trash.svg";
import { API_ORIGIN } from "pages/Main/Components/AdminPanel/Debate/DetailsModal/utils";

export type ItemReview = {
  id: string;
  comment: string;
  rating: number;
  user: {
    firstName: string;
    lastName: string;
    avatar: string;
  };
};
type Props = {
  item: ItemReview;
  isModal?: boolean;
  onDelete: (id: string) => void;
};

const Review = ({ item, isModal, onDelete }: Props) => {
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric" };
  const date = new Date();
  return (
    <>
      <div className={styles.listItem}>
        {item.user?.avatar ? (
          <Avatar icon={<img src={API_ORIGIN + "/public/avatars/" + item.user?.avatar} alt="userPhoto" />} size={56} />
        ) : (
          <Avatar icon={<UserOutlined />} size={57.6} />
        )}
        <div className={styles.text}>
          <div className={styles.itemHeader}>{`${item.user.firstName} ${item.user.lastName}`}</div>
          <div className={styles.itemMessage}>{item.comment}</div>
        </div>
        <div className={styles.rating}>
          <Rate value={item.rating} disabled className={styles.ratingRate} />
          <div className={styles.ratingDate}>{date.toLocaleDateString("ENG", options)}</div>
        </div>
        {!isModal && (
          <Button
            onClick={() => {
              onDelete(item.id);
            }}
            icon={<img src={trash} alt="trash" width={24} height={24} />}
            type="primary"
            className={styles.button}
          />
        )}
      </div>
    </>
  );
};

export default Review;
