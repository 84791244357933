import { QuestionOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { Tooltip } from "antd";

type Props = {
  photoFullPath: string;
  title: string;
};
export const PhotoNameTab = ({ photoFullPath, title }: Props) => {
  return (
    <div className={styles.container}>
      <div>
        {photoFullPath ? (
          <img src={photoFullPath} alt="pic" className={styles.photo} />
        ) : (
          <QuestionOutlined size={46} />
        )}
      </div>
      <div className={styles.nameRate}>
        <Tooltip title={title?.length > 15 ? title : ""}>
          <div>{title ? `${title}`.slice(0, 15) : "-"}</div>
        </Tooltip>
      </div>
    </div>
  );
};
