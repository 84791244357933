import { Button } from "antd";

import styles from "./index.module.css";
import { Pagination as PaginationData } from "../../Options";
import { useDebounce, useRequest } from "ahooks";
import sortIcon from "assets/svg/SortAscending.svg";
import { getProductsV1, SortByForProducts } from "helpers/backend_helper";
import { useContext, useEffect, useMemo, useState } from "react";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import { ENTITY_PER_PAGE, PROP_NAME_SKIP_ADS } from "helpers/constants";
import { TableWithPagination } from "Components/TableWithPagination";
import { PhotoNameTab } from "Components/PhotoNameTab";
import { UserNameRatingPhotoComponent } from "Components/UserNameRatingPhotoComponent";
import dayjs from "dayjs";
import { CopyButton } from "Components/CopyButton";
import { Link, useSearchParams } from "react-router-dom";
import { PROP_NAME_CITY, PROP_NAME_INPUT_TEXT, PROP_NAME_PRODUCT_CATEGORY } from "helpers/constants";

export type SearchDataProducts = {
  city: string[];
  productCat: string[];
};

type Props = {
  status: "Draft" | "Active" | "Moderating" | "Archived" | "Stopped";
};
export const RequestsProducts = ({ status }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const [skipAds, setSkipAds] = useState(0);
  const [pagination, setPagination] = useState<PaginationData>({ skip: 0, take: ENTITY_PER_PAGE, total: 0 });
  const [sortBy, setSortBy] = useState<SortByForProducts>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUserInput, setIsUserInput] = useState(false);
  const isRefresh = useDebounce(searchParams, { wait: 100 });

  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT); //debounced;
  const citiesFilter = searchParams.get(PROP_NAME_CITY);
  const categoriesFilter = searchParams.get(PROP_NAME_PRODUCT_CATEGORY);
  const skipAdsFilter = searchParams.get(PROP_NAME_SKIP_ADS);

  useEffect(() => {
    if (searchText && isUserInput) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(PROP_NAME_SKIP_ADS);
      setSearchParams(newSearchParams, { replace: true });
    } else {
      setIsUserInput(true);
    }
  }, [searchText]);

  useEffect(() => {
    if (skipAdsFilter) {
      setSkipAds(+skipAdsFilter);
    } else {
      setSkipAds(0);
    }
  }, [skipAdsFilter]);

  const { data: products, loading: productsLoading } = useRequest(
    () =>
      getProductsV1(
        {
          pagination: { take: ENTITY_PER_PAGE, skip: skipAds, total: pagination.total },
          searchText: searchText,
          status,
          categories: categoriesFilter?.length ? categoriesFilter.split(",") : [],
          cities: citiesFilter?.length ? citiesFilter.split(",") : [],
          sort: { sortBy },
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      ready: !!localCountry,
      refreshDeps: [localCountry, sortBy, isRefresh],
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
      },
      onSuccess: (data) => {
        setPagination((prev) => ({ ...prev, total: data.count }));
      },
    },
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Lessor",
      dataIndex: "lessor",
      key: "lessor",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: (
        <div className={styles.columnSort}>
          <div>Usage Rate</div>
          <Button
            type="text"
            icon={
              <img
                src={sortIcon}
                alt="usagePercentage"
                style={{ width: 16, height: 16, transform: sortBy === "usagePercentage" ? "rotate(180deg)" : null }}
              />
            }
            onClick={() => {
              setSortBy((prev) => (prev === "usagePercentage" ? "" : "usagePercentage"));
            }}
          />
        </div>
      ),
      dataIndex: "usage_rate",
      key: "usage_rate",
    },
    {
      title: (
        <div className={styles.columnSort}>
          <div>Quality Rate</div>
          <Button
            type="text"
            icon={
              <img
                src={sortIcon}
                alt="qualityRate"
                style={{ width: 16, height: 16, transform: sortBy === "qualityRate" ? "rotate(180deg)" : null }}
              />
            }
            onClick={() => {
              setSortBy((prev) => (prev === "qualityRate" ? "" : "qualityRate"));
            }}
          />
        </div>
      ),
      dataIndex: "quality_rate",
      key: "quality_rate",
    },
    {
      title: (
        <div className={styles.columnSort}>
          <div>Moderation Date</div>
          <Button
            type="text"
            icon={
              <img
                src={sortIcon}
                alt="moderatedAt"
                style={{ width: 16, height: 16, transform: sortBy === "moderatedAt" ? "rotate(180deg)" : null }}
              />
            }
            onClick={() => {
              setSortBy((prev) => (prev === "moderatedAt" ? "" : "moderatedAt"));
            }}
          />
        </div>
      ),
      dataIndex: "moderation_date",
      key: "moderation_date",
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const source = useMemo(
    () =>
      products?.products.map((el, i) => ({
        id: <CopyButton copyText="copied!" text={el.id} wait={1000} sliceOfText={7} className={styles.copyBtn} />,
        key: i,
        product: <PhotoNameTab photoFullPath={el?.productPhoto?.preview} title={el.name} />,
        lessor: (
          <UserNameRatingPhotoComponent
            el={{
              avatar: el?.user.avatar,
              firstName: el?.user.firstName,
              lastName: el?.user.lastName,
              rating: el?.user.userRating,
              isOnlyName: true,
            }}
          />
        ),
        category: el.categoryName,
        usage_rate: el.usagePercentage + "%",
        quality_rate: el.qualityRate,
        moderation_date: el.moderatedAt ? dayjs(new Date(el.moderatedAt)).format("DD.MM.YYYY") : "N/A",
        actions: (
          <div className={styles.buttonWrapper}>
            <Link to={{ search: "", pathname: `item/${el.id}` }}>
              <Button type="primary" className={styles.button}>
                Details
              </Button>
            </Link>
          </div>
        ),
      })) || [],
    [products?.products],
  );
  return (
    <div className={styles.container}>
      <TableWithPagination
        paginationTable={{
          pagination,
          skipAds,
        }}
        propsTable={{
          columns,
          dataSource: source,
          isLoading: productsLoading,
          headerShow: true,
        }}
      />
    </div>
  );
};
