import { TitleComponent } from "Components/Title";
import styles from "./index.module.css";
import { SearchComponent } from "Components/SearchComponent";
import {
  PROP_NAME_CITY,
  PROP_NAME_INPUT_TEXT,
  PROP_NAME_INSURANCE,
  PROP_NAME_MAIN_TAB,
  PROP_NAME_SKIP_ADS,
  PROP_NAME_SORTBY,
} from "helpers/constants";
import { getCities } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import { ReactNode, useCallback, useContext, useState } from "react";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import { Badge, Button, Divider } from "antd";
import { useSearchParams } from "react-router-dom";
import { TabsComponent } from "Components/TabsComponent";
import { RatioLimitModal } from "./Components/RatioLimitModal";
import { RequestInsurance } from "./RequestInsurance";

const items = [
  {
    key: "0",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>All</div>
      </Badge>
    ),
    children: <RequestInsurance status="" />,
  },
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Incoming</div>
      </Badge>
    ),
    children: <RequestInsurance status="incoming" />,
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Outgoing</div>
      </Badge>
    ),
    children: <RequestInsurance status="outgoing" />,
  },
];

export const Insurance = () => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const [cities, setCities] = useState<{ label: string | ReactNode; value: string }[]>([]);
  //   const [fromToDate, setFromToDate] = useState({ from: "", to: "" });
  const [isRatioOpen, setIsRatioOpen] = useState(false);

  const onCloseRatio = useCallback(() => {
    setIsRatioOpen(false);
  }, []);

  const onOpenRatio = useCallback(() => {
    setIsRatioOpen(true);
  }, []);
  const [searchParams, setSearchParams] = useSearchParams();

  const { loading: getCitiesLoading } = useRequest(() => getCities(localCountry.iso, true), {
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: (data) => {
      setCities(() => {
        return [
          { label: "All", value: "" },
          ...data.cities.map((city) => {
            const upperCaseFirstLt = city.formattedData[0].toUpperCase() + city.formattedData.slice(1);
            return { label: upperCaseFirstLt, value: city.id };
          }),
        ];
      });
    },
  });
  return (
    <>
      <div className={styles.container}>
        <div style={{ marginBottom: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <TitleComponent text="Insurance" />
          <Button type="primary" ghost className={styles.rateLimitsButton} onClick={onOpenRatio}>
            Ratio Limits
          </Button>
        </div>
        <SearchComponent
          inputData={{
            palceHolder: "Search",
            queryTextName: PROP_NAME_INPUT_TEXT,
          }}
          selectData={[
            {
              options: cities,
              label: "City",
              isLoadingSelect: getCitiesLoading,
              queryName: PROP_NAME_CITY,
            },
            {
              isLoadingSelect: false,
              options: [
                { label: "All", value: "" },
                {
                  label: "Deposit",
                  value: "DEPOSIT",
                },
                {
                  label: "Percentage",
                  value: "PERCENTAGE",
                },
              ],
              label: "Insurance",
              queryName: PROP_NAME_INSURANCE,
            },
          ]}
          isDateRangeField
        />
        <Divider style={{ borderWidth: 2 }} />
        <TabsComponent
          defaultActiveKey={searchParams.get(PROP_NAME_MAIN_TAB) || "0"}
          items={items}
          className={styles.tabs}
          destroyInactiveTabPane
          onChange={(activeKey) => {
            searchParams.set(PROP_NAME_MAIN_TAB, items.find((v) => v.key === activeKey).key);
            searchParams.delete(PROP_NAME_SORTBY);
            searchParams.delete(PROP_NAME_SKIP_ADS);

            setSearchParams(searchParams, { replace: true });
          }}
        />
      </div>
      <RatioLimitModal isOpen={isRatioOpen} onClose={onCloseRatio} />
    </>
  );
};
