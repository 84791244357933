import { Button } from "antd";
import { TableWithPagination } from "Components/TableWithPagination";
import sortIcon from "assets/svg/SortAscending.svg";
import styles from "./index.module.css";
import { useMemo, useState } from "react";
import { Pagination } from "pages/Main/Components/AdminPanel/Options";
import { ENTITY_PER_PAGE } from "helpers/constants";
import { CopyButton } from "Components/CopyButton";
import { PhotoNameTab } from "Components/PhotoNameTab";
import { AdminDisputesStatuses, SortByDisputes } from "helpers/backend_helper";
import { formatDateRange } from "../../Users/UserComponent/TableDeals/RequestDeals/RequestDeals";
import { Link } from "react-router-dom";

type Props = {
  status: AdminDisputesStatuses;
  cityIds: string[];
  insurance: string[];
  searchText: string;
  dateRange?: { to: string; from: string };
};

export const RequestDisputes = ({ status, cityIds, insurance, searchText, dateRange }: Props) => {
  const [skipAds, setSkipAds] = useState(0);
  const [sortBy, setSortBy] = useState<SortByDisputes>("");
  const [pagination, setPagination] = useState<Pagination>({ skip: 0, take: ENTITY_PER_PAGE, total: 0 });
  console.log(status, insurance, searchText, dateRange, cityIds, setPagination, setSkipAds);

  const columns = useMemo(() => {
    let columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Date</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="usagePercentage"
                  style={{ width: 16, height: 16, transform: sortBy === "date" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                setSortBy((prev) => (prev === "date" ? "" : "date"));
              }}
            />
          </div>
        ),
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Item",
        dataIndex: "item",
        key: "item",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Price</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="usagePercentage"
                  style={{ width: 16, height: 16, transform: sortBy === "price" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                setSortBy((prev) => (prev === "price" ? "" : "price"));
              }}
            />
          </div>
        ),
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Deal ID",
        dataIndex: "dealId",
        key: "dealId",
      },
      {
        title: "Insurance",
        dataIndex: "insurance",
        key: "insurance",
      },
      {
        title: (
          <div className={styles.columnSort}>
            <div>Period</div>
            <Button
              type="text"
              icon={
                <img
                  src={sortIcon}
                  alt="usagePercentage"
                  style={{ width: 16, height: 16, transform: sortBy === "period" ? "rotate(180deg)" : null }}
                />
              }
              onClick={() => {
                setSortBy((prev) => (prev === "period" ? "" : "period"));
              }}
            />
          </div>
        ),
        dataIndex: "period",
        key: "period",
      },
      {
        title: " ",
        dataIndex: "actions",
        key: "actions",
      },
    ];

    if (status === "completed") {
      columns = [
        ...columns.slice(0, 7),
        {
          title: (
            <div className={styles.columnSort}>
              <div>Paid Out</div>
              <Button
                type="text"
                icon={
                  <img
                    src={sortIcon}
                    alt="usagePercentage"
                    style={{ width: 16, height: 16, transform: sortBy === "paid_out" ? "rotate(180deg)" : null }}
                  />
                }
                onClick={() => {
                  setSortBy((prev) => (prev === "paid_out" ? "" : "paid_out"));
                }}
              />
            </div>
          ),
          dataIndex: "paid_out",
          key: "paid_out",
        },
        ...columns.slice(7),
      ];
    }

    return columns;
  }, [sortBy, status]);

  const source = useMemo(
    () =>
      [
        {
          id: "d9d01450-1889-4559-a1f2-94f9da463305",
          date: new Date().toString(),
          productPhoto: {
            preview:
              "https://c2c-api.goldapp.ru/product/photo/145ecb2b-2e2a-45b3-9618-1f228beda50f?photo=ba30b969-97dd-4925-ad3c-df8659d3657c-preview_1739213475019_RU.jpeg",
          },
          productName: "Computer",
          price: 20,
          insurance: "Deposit",
          paid_out: 20,
        },
      ].map((el) => ({
        key: el.id,
        id: (
          <CopyButton
            copyText="copied"
            sliceOfText={6}
            text={el.id}
            wait={1000}
            key={el.id}
            className={styles.copybtn}
          />
        ),
        date: new Date(el.date).toLocaleString("eng", {
          day: "2-digit",
          month: "short",
          hour: "2-digit",
          minute: "2-digit",
        }),
        item: <PhotoNameTab photoFullPath={el?.productPhoto?.preview} title={el.productName} />,
        price: "€" + el.price,
        dealId: <CopyButton copyText="copied!" text={el.id} wait={1000} sliceOfText={7} className={styles.copybtn} />,
        insurance: el.insurance,
        period: (
          <div>
            <div>{formatDateRange(new Date(), new Date()) + ","}</div>
            <div>{0 + " days"} </div>
          </div>
        ),
        paid_out: "€" + el?.paid_out,
        actions: (
          <div className={styles.buttonsWrapper}>
            {status === "completed" && (
              <div className={styles.buttonWrapper}>
                <Button
                  className={styles.button}
                  style={{ backgroundColor: "transparent", border: "2px solid #FFCC4A" }}
                  type="primary"
                  onClick={() => {
                    console.log("details");
                  }}
                >
                  Invoice
                </Button>
              </div>
            )}
            <div className={styles.buttonWrapper}>
              <Link to={{ search: `?chatId=e3f2613d-7b6e-4046-8ac9-6545fcb9f56a`, pathname: `/dispute/${el.id}` }}>
                <Button type="primary" className={styles.button}>
                  Details
                </Button>
              </Link>
            </div>
          </div>
        ),
      })),
    [status],
  );
  return (
    <div className={styles.container}>
      <TableWithPagination
        paginationTable={{ pagination, skipAds: skipAds }}
        propsTable={{
          columns,
          dataSource: source,
          headerShow: true,
          isLoading: false,
          classList: styles.table,
        }}
      />
    </div>
  );
};
