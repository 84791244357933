import { Badge, Divider } from "antd";
import { RequestPayments } from "./RequestPayments";
import styles from "./index.module.css";
import { TabsComponent } from "Components/TabsComponent";
import c2cToken from "assets/svg/c2c-token.svg";
import { SearchComponent } from "Components/SearchComponent";
import currencyEur from "assets/svg/CurrencyEur.svg";
import { useContext, useMemo } from "react";
import { InfoComponent } from "Components/InfoComponent";
import {
  PROP_NAME_INPUT_TEXT,
  PROP_NAME_NEXT_TAB,
  PROP_NAME_METHOD,
  PROP_NAME_PURPOSE,
  PROP_NAME_SKIP_ADS,
  PROP_NAME_SORTBY,
} from "helpers/constants";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import { useDebounce, useRequest } from "ahooks";
import { getProductPaymentMetrics } from "helpers/backend_helper";
import { useSearchParams } from "react-router-dom";

const getItems = (productId: string) => [
  {
    key: "0",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>All</div>
      </Badge>
    ),
    children: <RequestPayments productId={productId} />,
  },
  {
    key: "1",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Incoming</div>
      </Badge>
    ),
    children: <RequestPayments productId={productId} />,
  },
  {
    key: "2",
    label: (
      <Badge count={0} size="small" className={styles.badge}>
        <div className={styles.deals}>Outgoing</div>
      </Badge>
    ),
    children: <RequestPayments productId={productId} />,
  },
];

export const TABS_MAP = {
  0: "",
  1: "incoming",
  2: "outgoing",
};
export type Balances = {
  balance: number;
  debt: number;
  deposit: number;
};
type Props = {
  productId: string;
};
export const PURPOSE_OPTIONS = {
  FREEZE_AMOUNT: "Freeze",
  CHARGE_SERVICE_INSURANCE: "Insurance",
  CHARGE_RENT_LESSOR_BY_LESSEE: "Rent",
  UNFEEZE_AMOUNT: "Unfreeze",
  CHARGE_RENT_LESSOR_BY_SERVICE: "Commission",
  CHARGE_RENT_LESSEE_BY_SERVICE: "ServiceFee",
  CHARGE_DEBT_INTO_SERVICE: "Debt",
};

export const TOKEN_DECIMAL = 9;

export const TablePayments = ({ productId }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const searchText = searchParams.get(PROP_NAME_INPUT_TEXT); // debounced
  const paymentMethodFilter = searchParams.get(PROP_NAME_METHOD);
  const purposFilter = searchParams.get(PROP_NAME_PURPOSE);
  const nextTabs = searchParams.get(PROP_NAME_NEXT_TAB);
  const debauncedSearchParams = useDebounce(searchParams, { wait: 100 });

  const { data: userMetrics, loading: isUserMetricsLoading } = useRequest(
    () =>
      getProductPaymentMetrics(
        productId,
        {
          search: searchText,
          paymentMethod: paymentMethodFilter
            ? paymentMethodFilter.split(",").length > 1
              ? ""
              : (paymentMethodFilter as any)
            : "",
          purpose: purposFilter as any,
          transactionType: TABS_MAP[nextTabs] || "",
        },
        localCountry.iso.toUpperCase(),
      ),
    {
      ready: !!localCountry?.iso,
      refreshDeps: [debauncedSearchParams, localCountry?.iso],
      onError: (err: any) => {
        openNotificationWithIcon("error", err?.response?.data?.message || err.message);
      },
    },
  );

  const isTransaction = useMemo(() => {
    if (!userMetrics) return false;
    if (!userMetrics.bankAmount && !userMetrics.serviceFee && !userMetrics.vat && !!userMetrics.walletAmount)
      return false;
    return true;
  }, [userMetrics]);
  return (
    <>
      {isTransaction ? (
        <div className={styles.container}>
          <SearchComponent
            inputData={{
              queryTextName: PROP_NAME_INPUT_TEXT,
            }}
            selectData={[
              {
                options: [
                  { label: "All", value: "" },
                  { label: "Card", value: "Card" },
                  { label: "Wallet", value: "Wallet" },
                ],
                label: "Method",
                isLoadingSelect: false,
                queryName: PROP_NAME_METHOD,
              },
              {
                options: [
                  { label: "All", value: "" },
                  { label: PURPOSE_OPTIONS["FREEZE_AMOUNT"], value: "FREEZE_AMOUNT" },
                  { label: PURPOSE_OPTIONS["CHARGE_SERVICE_INSURANCE"], value: "CHARGE_SERVICE_INSURANCE" },
                  { label: PURPOSE_OPTIONS["CHARGE_RENT_LESSOR_BY_LESSEE"], value: "CHARGE_RENT_LESSOR_BY_LESSEE" },
                  { label: PURPOSE_OPTIONS["UNFEEZE_AMOUNT"], value: "UNFEEZE_AMOUNT" },
                  { label: PURPOSE_OPTIONS["CHARGE_RENT_LESSOR_BY_SERVICE"], value: "CHARGE_RENT_LESSOR_BY_SERVICE" },
                  { label: PURPOSE_OPTIONS["CHARGE_RENT_LESSEE_BY_SERVICE"], value: "CHARGE_RENT_LESSEE_BY_SERVICE" },
                  { label: PURPOSE_OPTIONS["CHARGE_DEBT_INTO_SERVICE"], value: "CHARGE_DEBT_INTO_SERVICE" },
                ],
                label: "Purpose",
                isLoadingSelect: false,
                queryName: PROP_NAME_PURPOSE,
              },
            ]}
          />
          <Divider style={{ borderWidth: 2 }} />
          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
            {[
              {
                infoOfData: "Amount",
                isLoading: isUserMetricsLoading,
                label: "Amount",
                value: (
                  <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                    <img src={c2cToken} alt="c2cToken" />
                    <span>{userMetrics?.walletAmount}</span>
                  </div>
                ),
                moreValue: {
                  value: "€" + userMetrics?.bankAmount,
                  moreInfo: null,
                },
              },
              {
                infoOfData: "Service Fees",
                label: "Service Fees",
                value: "€" + userMetrics?.serviceFee,
                isLoading: isUserMetricsLoading,
              },
              { infoOfData: "VAT", label: "VAT", value: userMetrics?.vat + "%", isLoading: isUserMetricsLoading },
            ].map(({ infoOfData, label, value, isLoading, moreValue }, i) => (
              <InfoComponent
                infoOfData={infoOfData}
                label={label}
                value={value}
                key={i}
                isLoading={isLoading}
                moreValue={moreValue}
              />
            ))}
          </div>
          <TabsComponent
            defaultActiveKey={searchParams.get(PROP_NAME_NEXT_TAB) || "0"}
            items={getItems(productId)}
            className={styles.tabs}
            destroyInactiveTabPane
            onChange={(activeKey) => {
              searchParams.set(PROP_NAME_NEXT_TAB, getItems(productId).find((v) => v.key === activeKey).key);
              searchParams.delete(PROP_NAME_SORTBY);
              searchParams.delete(PROP_NAME_SKIP_ADS);
              searchParams.delete(PROP_NAME_SORTBY);

              setSearchParams(searchParams, { replace: true });
            }}
          />
        </div>
      ) : (
        <div className={styles.noData}>
          <img src={currencyEur} alt="euro" />
          <div>No data</div>
        </div>
      )}
    </>
  );
};
